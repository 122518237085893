import {Navigate, useLocation} from 'react-router-dom';
import {connect} from 'react-redux';

import LoadingSpinner from "../components/LoadingSpinner";


const PrivateRoute = ({children, isAuthenticated}) => {
    const location = useLocation();
    if (isAuthenticated === null) {
        return <LoadingSpinner/>
    }
    return !isAuthenticated ? <Navigate to='/signin' state={{from: location}} replace={true}/> : children;
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(PrivateRoute);
