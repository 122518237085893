import React from 'react';

import {Form} from "react-bootstrap";
import {Button} from '@gravity-ui/uikit';
import {File} from '@gravity-ui/icons';

import {
    ProjectCommentField, ProjectCustomerField,
    ProjectDescriptionFullField,
    ProjectDescriptionShortField, ProjectIsForSchoolchildrenField,
    ProjectKeywordsField, ProjectManagerField, ProjectManagerRequirementsField,
    ProjectNameField, ProjectRequirementsField, ProjectTestField,
    ProjectVenueField
} from "./ProjectFields"

function ProjectUserForm({
                             projectData,
                             setProjectData,
                             errors,
                             setErrors,
                             onSubmit,
                             submitting,
                             onCanselClick, type,
                             hasManager, setHasManager,
                             oldFiles
                         }) {
    let submitButtonName, submittingButtonName, canselButtonName;
    let isCreate = false, isUpdate = false;
    if (type === "create") {
        isCreate = true;
        submitButtonName = "Подать проект";
        submittingButtonName = "Отправка...";
        canselButtonName = "Отменить подачу";
    } else if (type === "update") {
        isUpdate = true;
        submitButtonName = "Редактировать проект";
        submittingButtonName = "Сохранение...";
        canselButtonName = "Отменить редактирование";
    }

    const onChange = (e) => {
        const {name, value, type} = e.target;
        setProjectData((prevData) => ({
            ...prevData,
            [name]: type === 'radio' ? value === 'Да' : value,
        }));
    };

    const onHasManagerChange = (e) => {
        setHasManager(e.target.value === 'Да');
    };

    return (
        <Form className="form-explain" onSubmit={onSubmit}>

            <ProjectNameField projectData={projectData} onChange={onChange} errors={errors}/>

            <ProjectVenueField projectData={projectData} setProjectData={setProjectData} errors={errors}/>

            <ProjectKeywordsField projectData={projectData} setProjectData={setProjectData} errors={errors}/>

            <ProjectDescriptionShortField projectData={projectData} setProjectData={setProjectData}
                                          onChange={onChange}
                                          errors={errors}/>

            {isUpdate && oldFiles.full_description_file &&
                <a href={oldFiles.full_description_file} target="_blank"><File/>Полное описание проекта</a>}
            <ProjectDescriptionFullField setProjectData={setProjectData}
                                         errors={errors}
                                         required={isCreate}/>

            <ProjectIsForSchoolchildrenField projectData={projectData} onChange={onChange}/>

            <ProjectRequirementsField projectData={projectData} setProjectData={setProjectData} errors={errors}/>

            {isUpdate && oldFiles.test_file &&
                <a href={oldFiles.test_file} target="_blank"><File/>Тестовое задание</a>}
            <ProjectTestField setProjectData={setProjectData}
                              errors={errors}/>

            <Form.Group>
                <Form.Label as="legend" className="required">У вас есть куратор?</Form.Label>
                <Form.Check required
                            inline type="radio" id="radio-hasManager-1"
                            label="Да"
                            name="hasManager"
                            value="Да"
                            checked={hasManager === true}
                            onChange={onHasManagerChange}
                />
                <Form.Check required
                            inline type="radio" id="radio-hasManager-2"
                            label="Нет"
                            name="hasManager"
                            value="Нет"
                            checked={hasManager === false}
                            onChange={onHasManagerChange}
                />
            </Form.Group>

            {hasManager === true &&
                <ProjectManagerField projectData={projectData} setProjectData={setProjectData}
                                     errors={errors} setErrors={setErrors}/>}

            {hasManager === false && <ProjectManagerRequirementsField projectData={projectData} onChange={onChange}/>}

            <ProjectCommentField onChange={onChange} projectData={projectData}/>

            <div className="line-btn-group flex-column-reverse flex-sm-row">
                <Button view="normal" size="xl" onClick={onCanselClick} width="max">{canselButtonName}</Button>
                <Button view="action" type="submit" disabled={submitting} size="xl" width="max">
                    {submitting ? submittingButtonName : submitButtonName}
                </Button>
            </div>
        </Form>
    );
}

export default ProjectUserForm;