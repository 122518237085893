import React from 'react'

import Timeline from "../../components/timeline/Timeline";
import {Link} from "react-router-dom";

const stages = [
    {
        date: '01.02.2024',
        title: 'Старт набора проектов',
        text: <>Открывается набор проектов. Чтобы подать проект, заполните <Link
            to="../account/project/create/">форму</Link>.</>,
    },
    {
        date: '01.04.2024',
        title: 'Последний день подачи проектов',
        text: 'К этому дню все проекты должны быть поданы.',
    },
    {
        date: '28.04.2024 – 01.05.2024',
        title: 'Интенсив для кураторов',
        text: <>В ходе интенсива планируется разделить все проекты Мастерской на потоки, объединенные общей идеей, а
            также
            вместе с кураторами продумать работу каждого потока, обсудить совместные мероприятия и совместную работу
            кураторов с участниками.<br/><strong>Участие в интенсиве является
                обязательным для всех кураторов.</strong></>,
    },
    {
        date: '01.05.2024',
        title: 'Старт набора участников',
        text: <>Открывается регистрация в <Link to="../account">Личном кабинете участника</Link>. Все
            проекты представлены на сайте.</>
    },
    {
        date: '01.06.2024',
        title: 'Последний день подачи заявки на участие в Мастерской',
        text: 'К этому дню в Личном кабинете участника должны быть заполнено портфолио и выбраны не менее 3 проектов.',
    },
    {
        date: '01.06.2024',
        title: 'Последний день подачи заявки на финансовую поддержку',
    },
    {
        date: '01.06.2024',
        title: 'Старт отбора участников представителями проектов',
        text: 'Представители проектов присваивают каждому участнику один из трех статусов: рекомендован, в листе ожидания и не рекомендован. По каждому из проектов участник увидит свой статус.',
    },
    // {
    //     date: '23.06.2024',
    //     title: 'Объявление результатов конкурса грантов',
    //     text: 'В Личном кабинете появляется информация о выделении средств на проезд и проживание участников, подавших заявку на поддержку.',
    // },
    {
        date: '15.06.2024',
        title: 'Оглашение результатов отбора участников',
        text: 'В Личном кабинете участника публикуется информация о проекте, в котором он будет работать в рамках Мастерской. В Личном кабинете представителей проекта появляется список участников проекта.',
    },
    {
        title: 'Подготовка к началу работы',
    },
    {
        date: '08.07.2024',
        title: 'Старт первого модуля БММ',
        text: 'С этого дня команды начинают активно работать над своими проектами. Ориентировочное время работы — с 10:00 до 19:00 ежедневно.',
    },
    {
        date: '14.07.2024',
        title: 'Окончание первого модуля БММ и начало межмодуля',
        text: 'В этот день завершаются доклады по итогам работы в первом модуле, и формируется план работы на межмодуле.',
    },
    {
        title: 'Межмодуль',
    },
    {
        date: '18.07.2024',
        title: 'Старт второго модуля БММ',
        text: 'В формате питч-сессии команды рассказывают о своих наработках за межмодуль.',
    },
    {
        date: '23.07.2024',
        title: 'Окончание БММ',
        text: 'В этот день завершаются доклады по итогам работы на БММ и подводятся итоги.',
    },
];

const StagesPage = () => {
    function isCompletedDate(dateString) {
        const [day, month, year] = dateString.split(' – ')[0].split('.');
        return (new Date(year, month - 1, day) <= new Date());
    }

    function isCompletedPrevDate(dateString) {
        const [day, month, year] = dateString.split(' – ')[0].split('.');
        return (new Date(year, month - 1, day) < new Date());
    }

    return (
        <>
            <div className="page-title">Этапы Мастерской</div>
            <div className="bmm-period">8-14 июля + межмодуль + 18-23 июля 2024 года</div>

            <Timeline position="left">
                {stages.map(({date, title, text}, index) => (
                    <Timeline.Item key={index}
                                   completed={date && isCompletedDate(date) || !date && stages[index - 1].date && isCompletedPrevDate(stages[index - 1].date)}>
                        <Timeline.Date>{date}</Timeline.Date>
                        <Timeline.Description>
                            <Timeline.Title>{title}</Timeline.Title>
                            <Timeline.Text>{text}</Timeline.Text>
                        </Timeline.Description>
                    </Timeline.Item>
                ))}

            </Timeline>
        </>
    )
}

export default StagesPage