import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import axios from "axios";

import TravelForm from "./TravelForm";
import {
    getJSONAcceptAuthConfig,
    USER_APPLICATION_LOCAL_WORKSHOPS_ENDPOINT, USER_TRAVEL_GRANTS_ENDPOINT
} from "../../../api/api";
import LoadingSpinner from "../../../components/LoadingSpinner";

function TravelFormCreate() {
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);

    const [errors, setErrors] = useState(null);
    const [accommodationNeeded, setAccommodationNeeded] = useState({});

    const [travelData, setTravelData] = useState({
        passport: {
            citizenship: '',
            gender: '',
            number: '',
            issuer: '',
            issue_date: '',
            issuer_code: ''
        },
        accompanying_name: '',
        motivation: '',
        comment: '',
        livings: {},
        accompanying_phone: ''
    });

    const [localWorkshops, setLocalWorkshops] = useState([]);

    // Load saved form data from localStorage when the component mounts
    useEffect(() => {
        const savedTravelData = localStorage.getItem('travelData');
        if (savedTravelData) {
            setTravelData(JSON.parse(savedTravelData));
        }
        const savedAccommodationNeeded = localStorage.getItem('accommodationNeeded');
        if (savedAccommodationNeeded) {
            setAccommodationNeeded(JSON.parse(savedAccommodationNeeded));
        }
    }, []);

    // Load local workshops when the component mounts
    useEffect(() => {
        axios
            .get(USER_APPLICATION_LOCAL_WORKSHOPS_ENDPOINT, getJSONAcceptAuthConfig())
            .then(response => {
                setLocalWorkshops(response.data)
            })
            .catch(error => {
                console.error('Error getting application data:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    // Save form data to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('travelData', JSON.stringify(travelData));
        localStorage.setItem('accommodationNeeded', JSON.stringify(accommodationNeeded));
    }, [travelData, accommodationNeeded]);

    const onSubmit = (e) => {
        e.preventDefault();
        setSubmitting(true);

        // Преобразование livings в массив объектов
        const livingsArray = Object.entries(travelData.livings).map(([id, value]) => ({
            ...value,
            local_workshop: Number(id)
        }));

        // Замена livings в travelData
        const newTravelData = {...travelData, livings: livingsArray};

        // Удаление всех символов, кроме цифр, из номера паспорта и кода подразделения
        newTravelData.passport.number = newTravelData.passport.number.replace(/[^0-9]/g, '');
        newTravelData.passport.issuer_code = newTravelData.passport.issuer_code.replace(/[^0-9]/g, '');

        const travelDataJson = JSON.stringify(newTravelData);

        // console.log(travelDataJson);
        axios
            .post(USER_TRAVEL_GRANTS_ENDPOINT, travelDataJson, getJSONAcceptAuthConfig())
            .then(response => {
                localStorage.removeItem('travelData');
                localStorage.removeItem('accommodationNeeded');
                navigate('../', {relative: "path"});
            })
            .catch(error => {
                // Handle error
                if (error.response.data.non_field_errors && error.response.data.non_field_errors.includes("Поля user, workshop должны производить массив с уникальными значениями.")) {
                    navigate('/account/travel');
                } else {
                    // Handle other errors
                    setErrors(error.response.data);
                }
            })
            .finally(() => {
                setSubmitting(false);
            });
    }

    if (isLoading) {
        return (
            <>
                <h3 className="mb-2">Подача заявки на тревел грант</h3>
                <hr className="clearfix w-100 pb-0"/>
                <LoadingSpinner/>;
            </>
        );
    }

    return (
        <>
            <h3 className="mb-2">Подача заявки на тревел грант</h3>
            <hr className="clearfix w-100 pb-0"/>
            <TravelForm
                travelData={travelData}
                setTravelData={setTravelData}
                accommodationNeeded={accommodationNeeded}
                setAccommodationNeeded={setAccommodationNeeded}
                errors={errors}
                setErrors={setErrors}
                onSubmit={onSubmit}
                submitting={submitting}
                localWorkshops={localWorkshops}
                setLocalWorkshops={setLocalWorkshops}
                type="create"
            />
        </>
    );
}

export default TravelFormCreate;