import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import axios from "axios";

import ApplicationForm from "./ApplicationForm";
import {getAcceptAuthConfig, USER_APPLICATION_ENDPOINT} from "../../../api/api";

function ApplicationFormCreate() {
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);

    const [errors, setErrors] = useState(null);

    const [applicationData, setApplicationData] = useState(() => (localStorage.getItem('application') ?
        JSON.parse(localStorage.getItem('application')) :
        {
            education: "",
            experience: "",
            achievements: "",
            achievements_confirmation_file: "",
            skills: "",
            scientific_interests: "",
            expectations: "",
            other_comments: ""
        }));

    const [applicationProjectsData, setApplicationProjectsData] = useState(() => {
        const applicationProjects = JSON.parse(localStorage.getItem('applicationProjects')) || [];
        const addedProjects = JSON.parse(localStorage.getItem('addedProjects')) || [];
        const newProjects = addedProjects.filter(project => !applicationProjects.some(appProject => appProject.id === project.id));
        return [...applicationProjects, ...newProjects];
    });

    useEffect(() => {
        localStorage.setItem('application', JSON.stringify(applicationData));
    }, [applicationData]);

    useEffect(() => {
        localStorage.setItem('addedProjects', JSON.stringify(applicationProjectsData));
    }, [applicationProjectsData])

    const onSubmit = (e) => {
        e.preventDefault();

        setSubmitting(true);

        const formData = new FormData();

        formData.append("cv", JSON.stringify(applicationData));
        formData.append("achievements_confirmation_file", applicationData.achievements_confirmation_file);

        formData.append("projects_user", JSON.stringify(applicationProjectsData));
        applicationProjectsData.forEach(project => {
            if (project.file) {
                formData.append(project.id, project.file);
            }
        });

        axios
            .post(USER_APPLICATION_ENDPOINT, formData, getAcceptAuthConfig())
            .then(response => {
                localStorage.removeItem('application');
                navigate('../', {relative: "path"});
            })
            .catch(error => {
                setErrors(JSON.parse(error.response.request.response))
                console.error('Ошибка отправки заявки:', error);
            })
            .finally(() => {
                setSubmitting(false);
            });
    }

    return (
        <>
            <h3 className="mb-2">Подача заявки на участие</h3>
            <hr className="clearfix w-100 pb-0"/>

            <ApplicationForm type={"create"}
                             applicationData={applicationData} setApplicationData={setApplicationData}
                             applicationProjectsData={applicationProjectsData}
                             setApplicationProjectsData={setApplicationProjectsData}
                             errors={errors}
                             onSubmit={onSubmit} submitting={submitting}/>
        </>
    );
}

export default ApplicationFormCreate;