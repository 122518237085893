import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";

import {Form} from "react-bootstrap";
import {File} from "@gravity-ui/icons";
import {Button} from "@gravity-ui/uikit";

import {
    getAcceptAuthConfig,
    PROJECT_DISTRIBUTION_MANAGER_DETAIL_ENDPOINT
} from "../../../api/api";
import LoadingSpinner from "../../../components/LoadingSpinner";
import {LinkLikeButton_File} from "../../../components/Button/Button";

function ProjectManagerSelectionForm() {
    const {id} = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const navigate = useNavigate();

    const [selectionData, setSelectionData] = useState();
    const [selectionDataToSend, setSelectionDataToSend] = useState({
        id: "",
        grade: 0,
        comment: "",
    });

    useEffect(() => {
        axios
            .get(PROJECT_DISTRIBUTION_MANAGER_DETAIL_ENDPOINT(id), getAcceptAuthConfig())
            .then(response => {
                const {id, grade, comment} = response.data.projects_user;
                setSelectionData(response.data);
                setSelectionDataToSend({id, grade, comment});
            })
            .catch(error => {
                console.error('Error getting selection Data:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        setSubmitting(true);

        if (!selectionDataToSend.comment) {
            selectionDataToSend.comment = "";
        }

        axios
            .put(PROJECT_DISTRIBUTION_MANAGER_DETAIL_ENDPOINT(id), selectionDataToSend, getAcceptAuthConfig())
            .then(response => {
                navigate('../', {relative: "path"});
            })
            .catch(error => {
                console.error('Error submitting form:', error);
            })
            .finally(() => {
                setSubmitting(false);
            });
    }

    const onChange = (e) => {
        const {name, value, type} = e.target;
        if (type === 'number') {
            const numericValue = value ? parseInt(value, 10) : "";
            setSelectionDataToSend(prevData => ({
                ...prevData,
                [name]: numericValue,
            }));
        } else {
            setSelectionDataToSend(prevData => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const currentDate = new Date();
    const minDate = new Date('2024-06-07T23:59:59.999+07:00');
    const maxDate = new Date('2024-06-30T23:59:59.999+07:00');

    return (
        <>
            <h3 className="mb-2">Заявка</h3>
            <hr className="clearfix w-100 pb-0"/>
            {isLoading &&
                <LoadingSpinner/> ||
                <>
                    <div className={"bmm-text_bold selection-cv"}>
                        <p>{selectionData.projects_user.user.name}</p>

                        <strong>Образование</strong><br/>
                        <p className={"selection-text"}>{selectionData.cv.education || "–"}</p>

                        <strong>Опыт работы</strong>
                        <p className={"selection-text"}>{selectionData.cv.experience || "–"}</p>

                        <strong>Достижения</strong>
                        <p className={"selection-text"}>{selectionData.cv.achievements || "–"} <br/>

                            {selectionData.cv.achievements_confirmation_file &&
                                <LinkLikeButton_File href={selectionData.cv.achievements_confirmation_file}
                                                     icon={<File/>}
                                                     text={'Документы, подтверждающие достижения'}
                                                     fileName={selectionData.projects_user.user.name + ' Достижения'}
                                />
                                // <a href={selectionData.cv.achievements_confirmation_file} target="_blank">
                                //     <File/> Документы, подтверждающие достижения
                                // </a>
                            }
                        </p>

                        <strong>Навыки</strong>
                        <p className={"selection-text"}>{selectionData.cv.skills || "–"}</p>

                        <strong>Интересы в научной сфере</strong>
                        <p className={"selection-text"}>{selectionData.cv.scientific_interests || "–"}</p>

                        <strong>Ожидания от мастерской</strong>
                        <p className={"selection-text"}>{selectionData.cv.expectations || "–"}</p>

                        <strong>Прочие комментарии</strong>
                        <p className={"selection-text"}>{selectionData.cv.other_comments || "–"}</p>

                        <p>
                            {selectionData.projects_user.file &&
                                <LinkLikeButton_File href={selectionData.projects_user.file}
                                                     icon={<File/>}
                                                     text={'Решение тестового задания'}
                                                     fileName={selectionData.projects_user.user.name + ' Тестовое'}
                                />
                                // <a href={selectionData.projects_user.file} target="_blank">
                                //     <File/> Решение тестового задания
                                // </a>
                            }
                        </p>

                    </div>

                    {currentDate > minDate && currentDate <= maxDate &&
                        <Form onSubmit={onSubmit}>
                            <Form.Group controlId="grade">
                                <Form.Label className={"required"}>Оценка (от 0 до 100)</Form.Label>
                                <Form.Control required name="grade"
                                              type="number"
                                              min="0" max="100"
                                              onChange={onChange}
                                              value={selectionDataToSend.grade}
                                />
                            </Form.Group>

                            <Form.Group controlId="comment">
                                <Form.Label>Комментарий (не более 300 символов)</Form.Label>
                                <Form.Control name="comment" as="textarea" rows={3} maxLength={300}
                                              onChange={onChange}
                                              value={selectionDataToSend.comment || ""}
                                />
                            </Form.Group>

                            <Button view="action" type="submit" disabled={submitting} size="xl" width="max">
                                {submitting ? "Сохранение..." : "Сохранить"}
                            </Button>
                        </Form>
                    }
                </>
            }
        </>
    );
}

export default ProjectManagerSelectionForm;