export const onAccordionSelect = (e, name) => {
    let storedObject = localStorage.getItem('accordionStates');

    if (!storedObject) {
        storedObject = {};
    } else {
        storedObject = JSON.parse(storedObject);
    }

    storedObject[name] = e;

    localStorage.setItem('accordionStates', JSON.stringify(storedObject));
};

export const getDefaultActiveKey = (name) => {
    return localStorage.getItem('accordionStates')
        ? JSON.parse(localStorage.getItem('accordionStates')).hasOwnProperty(name)
            ? JSON.parse(localStorage.getItem('accordionStates'))[name]
            : ""
        : "";
};