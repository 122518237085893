import React, {useState} from 'react';
import {Link} from 'react-router-dom';

import {Button, Icon} from "@gravity-ui/uikit";
import {FileText, Pencil, Plus, TrashBin} from "@gravity-ui/icons";

import {openFileInNewTab} from "../../utils/utils";
import './Button.css'

function MyButton({children, onClick, type, to, className, disabled}) {
    const buttonProps = {
        className: `my-button ${className || ''}`,
        onClick: onClick,
        type: type || 'button',
        disabled: disabled || false,
    };

    return to ? <Link to={to} {...buttonProps}>{children}</Link> : <button {...buttonProps}>{children}</button>;
}

export default MyButton;

export function EditButtonIcon({onClick = null}) {
    return (
        <Button view={"normal"} size={"m"} onClick={onClick} title={"Редактировать"}>
            <Icon data={Pencil} size={18}/>
        </Button>
    );
}

export function EditButtonIcon_Link({to}) {
    return (
        <Link className={"text-decoration-none"} to={to}>
            <EditButtonIcon/>
        </Link>
    );
}

export function EditButtonIcon_href({href, icon}) {
    return (
        <a className={"text-decoration-none"} href={href} target="_blank">
            <Icon data={icon} size={18}/>
        </a>
    );
}

export function DeleteButtonIcon({onClick, title = 'Удалить'}) {
    return (
        <Button view={"flat-danger"} size={"m"} onClick={onClick} title={title}>
            <Icon data={TrashBin} size={18}/>
        </Button>
    );
}

export function AddButtonMaxWidth({className = "", text, onClick = null}) {
    return (
        <Button className={`add-button_max-width ${className}`}
                view={"action"} size={"xl"} width="max"
                onClick={onClick}>
            {text}
            <Icon data={Plus} size={18}/>
        </Button>
    );
}

export function AddButtonMaxWidth_Link({text, to}) {
    return (
        <Link className={"text-decoration-none"} to={to}>
            <AddButtonMaxWidth text={text}/>
        </Link>
    );
}

export function LinkLikeButton_File({href, icon, text, fileName = ''}) {
    return (
        <button type="button" className={"link-like-button"} onClick={(e) => openFileInNewTab(e, href, fileName)}>
            {icon}{text}
        </button>
    );
}

export function IconLikeButton({icon, onClick, size, ...props}) {
    return (
        <button type="button" className={"icon-like-button"}
            // disabled={!workerFeedbackTemplate}
                onClick={onClick}
                {...props} // Передача всех остальных параметров
        >
            <Icon data={icon} size={size}/>
        </button>
    );
}

export function HoverableIconLikeButton({ icon, hoverIcon, size, onClick, disabled }) {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <IconLikeButton
            icon={isHovered ? hoverIcon : icon}
            size={size}
            onClick={onClick}
            disabled={disabled}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        />
    );
}