import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {connect} from "react-redux";
import {Button} from "@gravity-ui/uikit";
import {Link} from "react-router-dom";
import ProjectUserCard from "../../components/projectCard/ProjectUserCard";
import {PROJECT_DETAIL_ENDPOINT, USER_PROJECTS_ENDPOINT, getAcceptAuthConfig} from "../../api/api"

const ProjectUserList = ({user, access}) => {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        axios
            .get(USER_PROJECTS_ENDPOINT, getAcceptAuthConfig())
            .then(response => {
                setProjects(response.data);
            })
            .catch(error => {
                console.error('Error getting my projects:', error);
            });

    }, []);

    const onDelete = async (projectId) => {
        try {
            await axios.delete(PROJECT_DETAIL_ENDPOINT(projectId), getAcceptAuthConfig());
            window.location.reload();
        } catch (error) {
            window.location.reload();
            console.error('Ошибка при удалении проекта:', error);
        }
    };

    const not_full_user = () => (<>
        <div className="text-danger">Подача проекта станет доступной после заполнения необходимых полей <Link
            to="/account/settings">в профиле</Link>.
        </div>
        <ul>
            {(!user?.last_name || !user?.first_name) && <li className="text-danger">Заполните ФИО.</li>}
            {!user?.title && <li className="text-danger">Заполните должность и место работы.</li>}
            {user?.avatar == null && <li className="text-danger">Загрузите свою фотограцию.</li>}
        </ul>
        <div className="text-center">
            <Button view={"action"} href={"/account/project/create"} size={"xl"} disabled>Подать проект</Button>
        </div>
    </>);

    const is_bad_user = () => {
        return (user?.avatar == null || !user?.title)
    }

    const currentDate = new Date();
    const maxDate = new Date('2024-04-03T23:59:59.999+07:00');
    // TODO: заменить на дату дедлайна подачи проектов + добавить дату начала подачи проектов

    return (
        <>
            <h3 className="mb-2">Мои проекты</h3>
            <hr className="clearfix w-100 pb-0"/>
            {(currentDate <= maxDate || user && (user.is_ws_director || user.is_lws_director || user.is_ls_manager)) && (is_bad_user() ? not_full_user() :
                    <div className="text-center">
                        <Link to={"create"}><Button view={"action"} size={"xl"}>
                            Подать проект
                        </Button></Link>
                    </div>
            )}

            {projects.reverse().map((project) => (
                <ProjectUserCard key={project.id} project={project} onDelete={onDelete} forCustomer/>))}
        </>
    );
};

const mapStateToProps = state => ({
    user: state.auth.user,
    access: state.auth.access,
});
export default connect(mapStateToProps)(ProjectUserList);