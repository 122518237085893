import React, {useRef, useState} from 'react';
import {Form} from "react-bootstrap";
import {Button} from "@gravity-ui/uikit";
import UserCard from "../../components/userCard/UserCard";
import {Pencil} from "@gravity-ui/icons";

function UserForm({onSubmit, submitting, userData, setUserData, errors}) {
    const [selectedImage, setSelectedImage] = useState("");

    const filePicker = useRef(null);

    const onPick = () => {
        filePicker.current.click();
    };

    const onChangeAvatar = (e) => {
        setUserData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.files[0]
        }));

        setSelectedImage(URL.createObjectURL(e.target.files[0]))
    };

    const onChange = (e) => {
        setUserData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value
        }));
    };

    return (
        <Form onSubmit={onSubmit}>
            <div className="row d-flex flex-column-reverse flex-lg-row">
                <div className="col-lg-9 mt-lg-0 mt-3">
                    <Form.Group controlId="formEmail" className="mb-3">
                        <Form.Label className="required">Электронная почта</Form.Label>
                        <Form.Control required type="email" name="email"
                                      value={userData.email} onChange={onChange}
                                      isInvalid={!!(errors && errors.email)}/>
                        <Form.Control.Feedback type="invalid">{errors && errors.email}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formLastname" className="mb-3">
                        <Form.Label className="required">Фамилия</Form.Label>
                        <Form.Control required type="text" name="last_name" value={userData.last_name}
                                      onChange={onChange}/>
                    </Form.Group>

                    <Form.Group controlId="formFirstname" className="mb-3">
                        <Form.Label className="required">Имя</Form.Label>
                        <Form.Control required type="text" name="first_name" value={userData.first_name}
                                      onChange={onChange}/>
                    </Form.Group>

                    <Form.Group controlId="formMiddlename" className="mb-3">
                        <Form.Label>Отчество</Form.Label>
                        <Form.Control type="text" name="middle_name" value={userData.middle_name}
                                      onChange={onChange}/>
                    </Form.Group>

                    <Form.Group controlId="formTitle" className="mb-3">
                        <Form.Label>Должность, место работы/учёбы</Form.Label>
                        <Form.Control as="textarea" type="text" name="title" value={userData.title}
                                      onChange={onChange}/>
                    </Form.Group>

                    <Button view="action" type="submit" disabled={submitting} className="mt-3 w-100" size="l">
                        {submitting ? 'Сохранение...' : 'Сохранить'}
                    </Button>
                </div>
                <div className="col-lg-3">
                    <Form.Label>Изображение профиля</Form.Label>
                    <Form.Control className="hidden" name="avatar" type="file" ref={filePicker} accept="image/*"
                                  onChange={onChangeAvatar}></Form.Control>
                    <div className="avatar-upload position-relative" role="button" onClick={onPick}>
                        <UserCard size="l" imgUrl={selectedImage || userData.avatar} name={`${userData.last_name} ${userData.first_name}`} initialsOnly={true}/>
                        <span className="avatar-upload__button">
                                <Pencil/> Изменить
                        </span>
                    </div>
                </div>
            </div>
        </Form>
    );
}

export default UserForm;