import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import axios from "axios";

import {Button, Icon, Label, Text} from "@gravity-ui/uikit";
import {FileCheck, FileExclamation} from "@gravity-ui/icons";

import {
    getAcceptAuthConfig,
    getJSONAcceptAuthConfig,
    USER_APPLICATION_DETAIL_ENDPOINT,
    USER_APPLICATION_ENDPOINT
} from "../../../api/api";
import {DeleteButtonIcon, EditButtonIcon_Link} from "../../../components/Button/Button";
import {DeleteModal} from "../../../components/Modal/Modals";
import LoadingSpinner from "../../../components/LoadingSpinner";

function DirectorApplicationForm() {
    const {id} = useParams();
    const [isLoading, setIsLoading] = useState(true);

    const [open, setOpen] = React.useState(false);
    const [applicationData, setApplicationData] = useState(null);
    const [applicationProjectsData, setApplicationProjectsData] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        axios
            .get(USER_APPLICATION_ENDPOINT + '?worker_id=' + id, getJSONAcceptAuthConfig())
            .then(response => {
                setApplicationData(response.data.cv);
                const sortedProjects = response.data.projects_user.sort((a, b) => a.priority - b.priority);
                setApplicationProjectsData(sortedProjects);
            })
            .catch(error => {
                console.error('Error getting application data:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const onDelete = async (id) => {
        try {
            await axios.delete(USER_APPLICATION_DETAIL_ENDPOINT(id), getAcceptAuthConfig());
            window.location.reload();
        } catch (error) {
            window.location.reload();
            console.error('Ошибка при удалении заявки:', error);
        }
    };

    if (isLoading) {
        return (<>
            <h3 className="mb-2">Заявки пользователя</h3>
            <hr className="clearfix w-100 pb-0"/>
            <LoadingSpinner/>
        </>)
    }

    return (
        <>
            <h3 className="mb-2">Заявки пользователя</h3>
            <hr className="clearfix w-100 pb-0"/>

            {!applicationData?.participant &&
                <div className="text-center">
                    <Link to={"create_application"}><Button view={"action"} size={"xl"}>
                        Подать заявку на участие
                    </Button></Link>
                </div>
                || applicationData?.participant &&
                <div className={"bmm-card project-card"}>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className={"d-flex align-items-center"}>
                            <Label theme="success" className={"me-2"}>Заявка подана</Label>
                            <h5 className={"project-card__title mt-0 mb-0"}>
                                {applicationData.workshop}
                            </h5>
                        </div>
                        <div className="group-btn">
                            {/*<Link to={`application/${applicationData?.id}`}><Button view={"action"} size={"xl"}>*/}
                            {/*    Редактировать заявку*/}
                            {/*</Button></Link>*/}
                            <DeleteButtonIcon onClick={() => setOpen(true)}/>
                            <EditButtonIcon_Link to={`${applicationData?.id}`}/>
                        </div>
                    </div>
                    {applicationProjectsData.length > 0 && applicationProjectsData.map((project, index) => (
                        <div key={index}
                             className={"bmm-card bmm-card_white d-flex align-items-center justify-content-between"}>
                    <span className={"bmm-text_bold"}>
                        <Label theme="normal" className={"me-2"}>Приоритет {index + 1}</Label>
                        {project.name}
                    </span>
                            {project.test_file && !project.file &&
                                <Text title={"Не загружен файл с решением тестового задания"} color="danger"><Icon
                                    data={FileExclamation} size={18}/></Text>}
                            {project.test_file && project.file &&
                                <Text title={"Файл с решением тестового задания загружен"} color="positive">
                                    <Icon data={FileCheck} size={18}/>
                                </Text>}
                        </div>))}
                    <DeleteModal type="заявку на участие в" name={`${applicationData.workshop}`}
                                 onDelete={() => onDelete(applicationData?.id)}
                                 open={open} setOpen={setOpen}/>
                </div>}
        </>
    );
}

export default DirectorApplicationForm;