import React, {useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import {Accordion, Form} from "react-bootstrap";
import axios from "axios";

import {Button, Icon, TextInput} from "@gravity-ui/uikit"
import {ArrowUpRightFromSquare, Check, FileArrowDown, FileArrowUp, FileZipper, Xmark} from "@gravity-ui/icons";

import './ProjectCard.css'
import {
    ProjectForSchool,
    ProjectPersona,
    ProjectVenue,
    ProjectDate,
    ProjectKeywords,
    ProjectStatusSelect,
    ProjectStream,
    ProjectPersonaWithModal
} from "./utils";
import {EditButtonIcon_Link, IconLikeButton} from "../Button/Button";
import {Load} from "../Icons/Icons";
import {
    getAcceptAuthConfig,
    getJSONAcceptAuthConfig,
    PROJECT_CHARACTERISTIC_DETAIL_ENDPOINT,
    PROJECT_CHARACTERISTIC_ENDPOINT,
    REFLECTIONS_MANAGER_DETAIL_ENDPOINT, REFLECTIONS_MANAGER_ENDPOINT,
    REFLECTIONS_WORKER_DETAIL_ENDPOINT,
    REFLECTIONS_WORKER_ENDPOINT
} from "../../api/api";
import {openFileInNewTab} from "../../utils/utils";

function ProjectReviewCard({
                               project,
                               hasUserFile,
                               onClick,
                               reflectionQuantity,
                               userFiles, setUserFiles,
                               workerReflectionId,
                               managerReflectionId,
                               showReflectionUpload
                           }) {
    const [maxCapacity, setMaxCapacity] = useState(project.characteristics?.max || '');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [characteristics, setCharacteristics] = useState(project.characteristics || null);
    const debounceTimeout = useRef(null);

    const sendMaxCapacityToServer = async (projectId, maxCapacity, characteristicsExist) => {
        try {
            const url = PROJECT_CHARACTERISTIC_ENDPOINT;
            const config = getJSONAcceptAuthConfig();

            let response;
            if (characteristicsExist) {
                const body = JSON.stringify({id: characteristics.id, max: maxCapacity});
                response = await axios.patch(PROJECT_CHARACTERISTIC_DETAIL_ENDPOINT(characteristics.id), body, config);
            } else {
                const body = JSON.stringify({max: maxCapacity, project: projectId});
                response = await axios.post(url, body, config);
                setCharacteristics(response.data);
            }

            return response.data;
        } catch (error) {
            throw new Error('Error sending maxCapacity to server');
        }
    };

    const handleMaxCapacityChange = (e) => {
        const newValue = e.target.value;
        setMaxCapacity(newValue);
        setLoading(true);
        setSuccess(false);
        setError(false);

        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(async () => {
            try {
                const characteristicsExist = !!characteristics;
                await sendMaxCapacityToServer(project.id, newValue, characteristicsExist);
                setLoading(false);
                setSuccess(true);
                setTimeout(() => setSuccess(false), 1000); // Убираем галочку через 1 секунду
            } catch (error) {
                setLoading(false);
                setError(true);
                setTimeout(() => setError(false), 1000); // Убираем крестик через 1 секунду
            }
        }, 1000); // Задержка 1000 мс
    };

    useEffect(() => {
        return () => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
        };
    }, []);

    const hasReflection = (userId) => userFiles.some(file => file.user === userId && file.project === project.id);

    const handleFileUpload = async (e, user, template, project, detailEndpoint, endpoint) => {
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('user', user);
            formData.append('file', file);
            formData.append('template', template);
            formData.append('project', project);

            const userFile = userFiles.find(userFile => userFile.template === template && userFile.user === user && userFile.project === project);

            axios({
                method: userFile ? 'put' : 'post',
                url: userFile ? detailEndpoint(userFile.id) : endpoint,
                data: formData,
                ...getAcceptAuthConfig()
            })
                .then(response => {
                    if (userFile) {
                        // Replace the existing userFile in the array
                        setUserFiles(prevUserFiles => prevUserFiles.map(uf => uf.id === userFile.id ? response.data : uf));
                    } else {
                        // Add the new userFile to the array
                        setUserFiles(prevUserFiles => [...prevUserFiles, response.data]);
                    }
                })
                .catch(error => {
                    console.error('Ошибка при загрузке шаблона:', error);
                })
                .finally(() => {
                });
        } else {
            alert('Пожалуйста, выберите файл.');
        }
    };

    function truncateProjectName(name, id) {
        const maxLength = 20; // Define the maximum length for the truncated name
        if (name.length <= maxLength) {
            return `${id} ${name}`;
        }
        const start = name.slice(0, Math.ceil(maxLength / 2));
        const end = name.slice(-Math.floor(maxLength / 2));
        return `${id} ${start}...${end}`;
    }

    return (
        <div className="bmm-card project-card project-card-draft">
            <div className="project-card__pretitle mb-1">
                <div>
                    {project.customers?.length > 0 && project.customers.map((customer, index) => (
                        <ProjectPersona key={index} user={customer}/>
                    ))}
                </div>
                <div className={"d-flex align-items-center"}>
                    <TextInput
                        type="number"
                        className={"without-appearance me-1"}
                        style={{width: '80px'}}
                        value={maxCapacity}
                        onChange={handleMaxCapacityChange}
                        rightContent={
                            loading ? (
                                <Icon name="loading" data={Load} className="icon-loading me-1"/>
                            ) : success ? (
                                <Icon name="check" data={Check} className={"me-1"}/>
                            ) : error ? (
                                <Icon name="cross" data={Xmark} className={"me-1"}/>
                            ) : null
                        }
                    />
                    <ProjectStatusSelect project={project}/>
                </div>
            </div>

            <div className="project-card__pretitle">
                <div className={"me-1"}>
                    <ProjectVenue venue={project.venue}/>
                    <ProjectStream stream={project.local_stream}/>
                </div>
                <div>
                    <ProjectForSchool is_for_schoolchildren={project.is_for_schoolchildren}/>
                </div>
            </div>

            <Link to={`${project.id}`} className="project-card__title">{project.name}</Link>

            <div className="project__keywords project-card__keywords">
                <ProjectKeywords keywords={project.keywords}/>
            </div>

            <div className="project-card__text" dangerouslySetInnerHTML={{__html: project.description}}/>

            <div className={"mb-2"}>
            {project.results_application_url &&
                <Button view="normal" size="m" className={"mb-2"}
                        onClick={() => window.open(project.results_application_url, '_blank')}>
                            <Icon data={ArrowUpRightFromSquare} size={18}/>
                    {"Перейти по ссылке от куратора"}
                </Button>
            }
            {project.results_application_file &&
                <Button view="normal" size="m" className={"mb-2 ms-2"}
                        onClick={(e) => openFileInNewTab(e, project.results_application_file, truncateProjectName(project.name, project.id))}>
                    <Icon data={FileArrowDown} size={18}/>
                    {"Скачать заметку от куратора"}
                </Button>
            }
            </div>

            {project.managers?.length > 0 &&
                <div className={"d-flex gap-3"}>
                    {project.managers.map((manager, index) => {
                        const filePicker = useRef(null);

                        const onPick = () => {
                            filePicker.current.click();
                        };

                        return (
                            <span key={index} className={"d-flex align-items-center"}>
                                <ProjectPersonaWithModal user={manager} hasReflection={hasReflection(manager.id)}
                                                         projectId={project.id}/>
                                {showReflectionUpload && managerReflectionId &&
                                    <div>
                                        <Form.Control className="hidden" type="file" ref={filePicker} accept="image/*"
                                                      onChange={(e) => handleFileUpload(e, manager.id, managerReflectionId, project.id, REFLECTIONS_MANAGER_DETAIL_ENDPOINT, REFLECTIONS_MANAGER_ENDPOINT)}/>
                                        <IconLikeButton tabIndex={0} icon={FileArrowUp} size={18} onClick={onPick}/>
                                    </div>}
                            </span>
                        )
                    })}
                </div>}

            {project.workers?.length > 0 &&
                <Accordion className={"mt-1 mb-1 workers-accordion"} flush>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Участники проекта <div
                            className={"bmm-text_lightgray ms-1"}>{project.workers.length}</div></Accordion.Header>
                        <Accordion.Body className={"four-columns-container"}>
                            {project.workers.map((worker, index) => {
                                const classes = ['bmm-card', 'bmm-card_white', 'distribution-participant-card'];
                                if (worker.participation_confirmation === 'confirmed') classes.push('background-confirmed');
                                else if (worker.participation_confirmation === 'declined') classes.push('background-declined');

                                const filePicker = useRef(null);

                                const onPick = () => {
                                    filePicker.current.click();
                                };

                                return (
                                    <div key={index} className={classes.join(' ')}>
                                        <ProjectPersonaWithModal key={index} user={worker}
                                                                 hasReflection={hasReflection(worker.id)}
                                                                 projectId={project.id}/>
                                        {showReflectionUpload && workerReflectionId &&
                                            <div>
                                                <Form.Control className="hidden" type="file" ref={filePicker}
                                                              accept="image/*"
                                                              onChange={(e) => handleFileUpload(e, worker.id, workerReflectionId, project.id, REFLECTIONS_WORKER_DETAIL_ENDPOINT, REFLECTIONS_WORKER_ENDPOINT)}></Form.Control>
                                                <IconLikeButton icon={FileArrowUp} size={18} onClick={onPick}/>
                                            </div>
                                        }
                                    </div>
                                );
                            })}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            }

            <div className="project-card__subtitle">
                <div className="project-card__subtitle-date">
                    <ProjectDate date={new Date(project.created_on)}/>
                </div>
                <div className="group-btn">
                    {hasUserFile &&
                        <Button view={"normal"} size={"m"} title={"Скачать архив рефлексий"}
                                onClick={(e) => onClick(e, [project.id])}>
                            {reflectionQuantity(project.id)}
                            <Icon data={FileZipper} size={18} className={"reflection-color"}/>
                        </Button>
                    }
                    <Link to={`/project/${project.id}`}>
                        <Button view={"normal"} size={"m"} title={"Предпросмотр"}>
                            <Icon data={ArrowUpRightFromSquare} size={18}/>
                        </Button>
                    </Link>
                    <EditButtonIcon_Link to={`${project.id}`}/>
                </div>
            </div>
        </div>);
}

export default ProjectReviewCard;