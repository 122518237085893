import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import axios from "axios";

import {Button, Icon, Label, Popup, Select, TextInput} from "@gravity-ui/uikit";
import {
    ArrowDownToSquare,
    ArrowUpFromSquare,
    PersonXmark,
    Shapes3,
    SquareCheck,
    SquareListUl,
    SquareXmark
} from "@gravity-ui/icons";

import {getThemeByPriority} from "../../../utils/utils";
import {
    getJSONConfig,
    STREAMS_ENDPOINT,
    VENUES_ENDPOINT,
    GAYLE_SHAPLEY_ENDPOINT, getJSONAcceptAuthConfig, FIX_DISTRIBUTION_ENDPOINT
} from "../../../api/api";
import {UNALLOCATED_PROJECTS} from "../../../utils/const";

const ParticipantPopup = ({participant, projectId, buttonRef, setOpen, open, onUnassign, onDelete}) => {
    return (
        <Popup anchorRef={buttonRef} open={open}
               style={{maxHeight: '300px', maxWidth: '250px', overflowY: 'auto'}}
               onOutsideClick={() => setOpen(false)}>
            <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                {/* Верхняя часть с именем участника */}
                <div style={{padding: '10px', borderBottom: '1px solid #ddd'}}>
                    <strong className={"single-line-text"}>{participant.name}</strong>
                </div>
                {/* Прокручиваемая область с проектами */}
                <div className={"distribution-participant-proj-list"}
                     style={{flexGrow: 1, maxHeight: '190px', overflowY: 'auto', padding: '10px'}}>
                    {participant.projects.map((proj) => (
                        <div key={proj.id}
                             className={`bmm-card distribution-participant-proj-card ${proj.id === participant.project_id ? 'selected' : ''}`}>
                            <Label theme="info" className={"grade-label me-1"}>
                                {proj.grade}
                            </Label>
                            <Label
                                theme={proj?.priority !== undefined ? getThemeByPriority(proj.priority) : "danger"}
                                className={"priority-label me-1"}>
                                {proj.priority + 1}
                            </Label>
                            <div className={"single-line-text"}>{proj.name}</div>
                        </div>
                    ))}
                </div>
                {/* Область с кнопками */}
                <div style={{
                    padding: '8px',
                    borderTop: '1px solid #ddd',
                    display: 'flex',
                    justifyContent: 'right',
                    gap: '4px',
                }}>
                    {projectId !== -1 &&
                        <Button size={"s"} view={"flat"} title={'Переместить в "Нераспределённые"'}
                                onClick={() => onUnassign(participant.id)}>
                            <Icon data={ArrowUpFromSquare} size={16}/>
                        </Button>}
                    {projectId !== -2 &&
                        <Button size={"s"} view={"flat-danger"} title={'Переместить в "Исключённые"'}
                                onClick={() => onDelete(participant.id)}>
                            <Icon data={PersonXmark} size={16}/>
                        </Button>}
                </div>
            </div>
        </Popup>
    );
}


const ParticipantCard = ({participant, currentProjectId, selected, onSelect, onUnassign, onDelete}) => {
    const buttonRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);
    const project = Array.isArray(participant.projects) ? participant.projects?.find(proj => proj.id === currentProjectId) : null;

    return (
        <div
            className={`bmm-card bmm-card_white distribution-participant-card ${selected ? 'selected' : ''} ${participant.participation_confirmation === 'confirmed' && "background-confirmed" || participant.participation_confirmation === 'declined' && "background-declined"}`}>
            <div className={'d-flex align-items-center'}>
                <Label theme="info" className={"grade-label me-1"}>{project ? project.grade : '-'}</Label>
                <Label theme={project?.priority !== undefined ? getThemeByPriority(project.priority) : "danger"}
                       className={"priority-label me-1"}>
                    {project ? project.priority + 1 : '-'}
                </Label>

                <span onClick={() => onSelect(participant.id)} style={{cursor: 'pointer'}}
                      className={"single-line-text"}>
                    {participant.name}
                </span>
            </div>

            <Button className="square-list-button"
                    ref={buttonRef} onClick={() => setOpen((prevOpen) => !prevOpen)}
                    size={"s"} view={"flat"}>
                <Icon data={SquareListUl} size={16}/>
            </Button>

            <ParticipantPopup participant={participant} projectId={currentProjectId}
                              buttonRef={buttonRef} open={open} setOpen={setOpen}
                              onUnassign={onUnassign} onDelete={onDelete}/>
        </div>
    );
};

const Project = ({
                     project,
                     selectedParticipants,
                     onSelect,
                     onMoveParticipants,
                     onUnassignParticipant,
                     onDeleteParticipant,
                     onDeleteProject = () => {
                     },
                     className
                 }) => {
    return (
        <div className={`bmm-card project-card ${className || ''}`}>
            {project.id > 0 ?
                <>
                    <div className={"d-flex flex-row-reverse align-items-center justify-content-between mb-1"}>
                        <div>
                            <Button className="hover-button"
                                    view="outlined" onClick={() => onMoveParticipants(project.id)} size={"s"}
                                    disabled={selectedParticipants.length <= 0}
                                    title={"Переместить выбранных участников"}>
                                <Icon size={16} data={ArrowDownToSquare}/>
                            </Button>
                            <Button className="hover-button ms-1" view="flat-danger"
                                    onClick={() => onDeleteProject(project.id)} size={"s"}
                                    title={"Исключить проект"}>
                                <Icon size={16} data={SquareXmark}/>
                            </Button>
                        </div>
                        {project.venue &&
                            <div className={"mb-1 d-flex align-items-center"}>
                                <Label theme={"unknown"}>
                                    {project.venue.name === 'Омский филиал ИМ СО РАН' ? project.venue.city : project.venue.name}
                                </Label>
                                {project.is_for_schoolchildren &&
                                    <div className={"ms-1 d-flex"} title={"Проект для школьников"}>
                                        <Label theme={"warning"} icon={<Icon size={14} data={Shapes3}/>}/>
                                    </div>}
                            </div>}
                    </div>
                    <Link to={`/project/${project.id}`} className="project-card__title single-line-text mt-0"
                          target="_blank" rel="noopener noreferrer">
                        <h5 className={"mb-0"}>{project.name}</h5>
                    </Link>
                </> :
                <>
                    <div className={"d-flex align-items-center justify-content-between mb-1"}>
                        <div className="project-card__title single-line-text mt-0">
                            <h5 className={"mb-0"}>{project.name}</h5>
                        </div>
                        {project.id === -2 ?
                            <Button className="hover-button" view="outlined-danger" size={"s"}
                                    onClick={() => onMoveParticipants(project.id)}
                                    disabled={selectedParticipants.length <= 0}
                                    title={"Исключить выбранных участников"}>
                                <Icon size={16} data={PersonXmark}/>
                            </Button> :
                            <Button className="hover-button"
                                    view="outlined" onClick={() => onMoveParticipants(project.id)} size={"s"}
                                    disabled={selectedParticipants.length <= 0}
                                    title={"Переместить выбранных участников"}>
                                <Icon size={16} data={ArrowDownToSquare}/>
                            </Button>}
                    </div>
                </>
            }

            <div className={`${project.id < 0 ? "four-columns-container gap-1" : "distribution-participant-list"}`}>
                {project.participants.map((participant) => (
                    <ParticipantCard key={participant.id}
                                     participant={participant}
                                     currentProjectId={project.id}
                                     selected={selectedParticipants.includes(participant.id)}
                                     onSelect={onSelect}
                                     onUnassign={onUnassignParticipant}
                                     onDelete={onDeleteParticipant}
                    />
                ))}
            </div>
        </div>
    );
};

const DeletedProjects = ({projects, onRestoreProject, onRemoveProjectsBelowThreshold}) => {
    const [threshold, setThreshold] = useState(4);

    const handleThresholdChange = (e) => setThreshold(e.target.value ? parseInt(e.target.value, 10) : "");

    return (
        <div className={"bmm-card mt-3"}>
            <div className="project-card__title single-line-text mt-0 mb-1">
                <h5 className={"mb-0"}>Исключённые проекты</h5>
            </div>
            <div className={"four-columns-container gap-1"}>
                {projects.map((project) => (
                    <div key={project.id}
                         className={"bmm-card bmm-card_white distribution-deleted-project-card project-card"}>
                        <Link to={`/project/${project.id}`} target="_blank" rel="noopener noreferrer"
                              className={"text-decoration-none"}>
                            <span className={"single-line-text"}>{project.name}</span>
                        </Link>
                        <Button className="square-list-button hover-button" size={"s"} view={"flat-success"}
                                onClick={() => onRestoreProject(project.id)}>
                            <Icon data={SquareCheck} size={16}/>
                        </Button>
                    </div>
                ))}
            </div>
            <div className={"custom-input-group mt-3"}>
                <Button view="outlined-danger" size="s" pin="round-brick"
                        onClick={() => onRemoveProjectsBelowThreshold(threshold)}>
                    Исключить проекты, где кол-во участников
                </Button>
                <TextInput size="s" label="&#8804;" pin="brick-round" type={"number"}
                           value={threshold} onChange={handleThresholdChange}/>
            </div>
        </div>
    );
};

function WorkshopDirectorDistributionPage() {
    const [distributionData, setDistributionData] = useState(() => (localStorage.getItem('distributionData') ?
        JSON.parse(localStorage.getItem('distributionData')) : null));
    const [deletedProjects, setDeletedProjects] = useState(() => (localStorage.getItem('deletedProjects') ?
        JSON.parse(localStorage.getItem('deletedProjects')) : []));
    const [selectedParticipants, setSelectedParticipants] = useState([]);

    const [capacity, setCapacity] = useState(8);
    const [gettingGayleShapley, setGettingGayleShapley] = useState(false);
    const [fixAnswerText, setFixAnswerText] = useState("");

    const [venues, setVenues] = useState([]);
    const [streams, setStreams] = useState([]);

    const [venueFilter, setVenueFilter] = useState([]);
    const [streamFilter, setStreamFilter] = useState([]);
    const [schoolchildrenFilter, setSchoolchildrenFilter] = useState('');

    useEffect(() => {
        localStorage.setItem('distributionData', JSON.stringify(distributionData));
    }, [distributionData]);

    useEffect(() => {
        localStorage.setItem('deletedProjects', JSON.stringify(deletedProjects));
    }, [deletedProjects]);

    useEffect(() => {
        axios
            .get(VENUES_ENDPOINT, getJSONConfig())
            .then(response => {
                setVenues(response.data);
            });

        axios
            .get(STREAMS_ENDPOINT, getJSONConfig())
            .then(response => {
                // Сортируем массив так, чтобы элемент "Нераспределенные проекты" был первым
                response.data.sort((a, b) => {
                    if (a.name === UNALLOCATED_PROJECTS) {
                        return -1;
                    } else if (b.name === UNALLOCATED_PROJECTS) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
                setStreams(response.data);
            });
    }, []);

    const fix_distribution = () => {
        setGettingGayleShapley(true);

        let dist_data = [];
        distributionData.projects.forEach(project => {
            if (project.id < 0)
                return;
            project.participants.forEach(participant => {
                dist_data.push({project: project.id, worker: participant.id});
            });
        });

        const dist_dataJSON = JSON.stringify(dist_data);

        axios
            .post(FIX_DISTRIBUTION_ENDPOINT, dist_dataJSON, getJSONAcceptAuthConfig())
            .then(response => {
                if (response.status === 201) {
                    let project_workers = response.data;
                    let projects = [];
                    project_workers.forEach(project_worker => {
                        let project = projects.find(p => p === project_worker.project);
                        if (!project) {
                            projects.push(project_worker.project);
                        }
                    })
                    setFixAnswerText("Зафиксировано успешно. Участников: " + project_workers.length + ", проектов: " + projects.length);
                }
            })
            .catch(e => {
                setFixAnswerText("Ошибка при фиксации распределения")
                console.error(e);
            })
            .finally(
                () => {
                    setGettingGayleShapley(false);
                }
            );
    }

    const get_distribution = () => {
        setGettingGayleShapley(true);

        axios
            .get(FIX_DISTRIBUTION_ENDPOINT, getJSONAcceptAuthConfig())
            .then(response => {
                setDistributionData({projects: response.data.projects});
                setDeletedProjects(response.data.stop_projects);
                // if (response.status === 201) {
                //     let project_workers = response.data;
                //     let projects = [];
                //     project_workers.forEach(project_worker => {
                //         let project = projects.find(p => p === project_worker.project);
                //         if (!project) {
                //             projects.push(project_worker.project);
                //         }
                //     })
                //     setFixAnswerText("Зафиксировано успешно. Участников: " + project_workers.length + ", проектов: " + projects.length);
                // }
            })
            .catch(e => {
                // setFixAnswerText("Ошибка при фиксации распределения")
                console.error("Ошибка при получении распределения:", e);
            })
            .finally(() => {
                    setGettingGayleShapley(false);
                }
            );
    }

    const getGayleShapley = (withExcluded = false) => {
        setGettingGayleShapley(true);

        let data = {capacity: capacity};
        if (withExcluded) {
            data.stop_users = distributionData?.projects.find(project => project.id === -2).participants.map(({id}) => (id));
            data.stop_projects = deletedProjects?.map(({id}) => (id));
        } else {
            setDeletedProjects([]);
        }

        const dataJSON = JSON.stringify(data);

        axios
            .post(GAYLE_SHAPLEY_ENDPOINT, dataJSON, getJSONAcceptAuthConfig())
            .then(response => {
                setDistributionData(response.data);
            })
            .catch(error => {
                console.error("Ошибка получения распределения:", error);
            })
            .finally(() => {
                setGettingGayleShapley(false);
            });
    }

    const onChange = (e) => {
        setCapacity(e.target.value ? parseInt(e.target.value, 10) : "");
    }

    const handleSelectParticipant = (id) => {
        setSelectedParticipants(prev =>
            prev.includes(id) ? prev.filter(pId => pId !== id) : [...prev, id]
        );
    };

    const handleMoveParticipants = (targetProjectId) => {
        setDistributionData(prevData => {
            const newProjects = prevData.projects.map(project => {
                if (project.id === targetProjectId) {
                    const existingParticipantIds = new Set(project.participants.map(participant => participant.id));
                    const newParticipants = [
                        ...project.participants,
                        ...selectedParticipants
                            .filter(id => !existingParticipantIds.has(id))
                            .map(id => {
                                const sourceProject = prevData.projects.find(p => p.participants.some(part => part.id === id));
                                return sourceProject.participants.find(part => part.id === id);
                            })
                    ];
                    return {...project, participants: newParticipants};
                } else {
                    const newParticipants = project.participants.filter(part => !selectedParticipants.includes(part.id));
                    return {...project, participants: newParticipants};
                }
            });
            return {...prevData, projects: newProjects};
        });
        setSelectedParticipants([]);
    };

    const moveParticipantToProject = (participantId, targetProjectId) => {
        setDistributionData(prevData => {
            const newProjects = prevData.projects.map(project => {
                const newParticipants = project.participants.filter(part => part.id !== participantId);
                return {...project, participants: newParticipants};
            });
            const targetProject = newProjects.find(project => project.id === targetProjectId);
            const sourceProject = prevData.projects.find(project => project.participants.some(part => part.id === participantId));
            const participant = sourceProject.participants.find(part => part.id === participantId);
            if (targetProject && participant) {
                targetProject.participants.push(participant);
            }
            return {...prevData, projects: newProjects};
        });
    };

    const handleUnassignParticipants = participantId => moveParticipantToProject(participantId, -1);
    const handleDeleteParticipants = participantId => moveParticipantToProject(participantId, -2);

    const handleDeleteProject = (projectId) => {
        const projectToDelete = distributionData.projects.find(project => project.id === projectId);
        setDistributionData(prevData => {
            let newProjects = prevData.projects.filter(project => project.id !== projectId);
            const unassignedProject = newProjects.find(project => project.id === -1);
            if (unassignedProject && projectToDelete) {
                unassignedProject.participants = [...unassignedProject.participants, ...projectToDelete.participants];
            }
            return {...prevData, projects: newProjects};
        });
        setDeletedProjects(prevDeletedProjects => [...prevDeletedProjects, {...projectToDelete, participants: []}]);
    };

    const handleRestoreProject = projectId => {
        const projectToRestore = deletedProjects.find(project => project.id === projectId);
        setDistributionData(prevData => ({...prevData, projects: [...prevData.projects, projectToRestore]}));
        setDeletedProjects(prevDeletedProjects => prevDeletedProjects.filter(project => project.id !== projectId));
    };

    const handleRemoveProjectsBelowThreshold = threshold => {
        const projectsToDelete = distributionData.projects.filter(project => project.participants.length <= threshold && project.id > 0);
        const updatedProjects = distributionData.projects.filter(project => project.participants.length > threshold || project.id < 0);
        const unassignedProject = updatedProjects.find(project => project.id === -1);

        projectsToDelete.forEach(project => {
            if (unassignedProject) {
                unassignedProject.participants = [...unassignedProject.participants, ...project.participants];
            }
        });

        setDistributionData(prevData => ({...prevData, projects: updatedProjects}));
        setDeletedProjects(prevDeletedProjects => [...prevDeletedProjects, ...projectsToDelete.map(project => ({
            ...project,
            participants: []
        }))]);
    };

    const filterProjects = () => {
        return distributionData?.projects?.filter(project => {
            if (project.id < 0) {
                return false;
            }
            // Фильтрация по площадке
            if (venueFilter.length > 0) {
                if (venueFilter.includes("-1")) {
                    if (project.venue && !venueFilter.includes(project.venue.id.toString())) {
                        return false; // Если площадка проекта не выбрана и в фильтре выбрана опция "Не выбрана"
                    }
                } else {
                    if (!project.venue || !venueFilter.includes(project.venue.id.toString())) {
                        return false; // Если площадка проекта не выбрана или не содержится в списке фильтра по площадке
                    }
                }
            }

            // Фильтрация по потоку
            if (streamFilter.length > 0) {
                if (!project.stream || !streamFilter.includes(project.stream.toString())) {
                    return false;
                }
            }

            // Фильтрация по наличию или отсутствию школьных проектов
            if (schoolchildrenFilter.length > 0) {
                if (schoolchildrenFilter[0] === 'true' && !project.is_for_schoolchildren) {
                    return false;
                }
                if (schoolchildrenFilter[0] === 'false' && project.is_for_schoolchildren) {
                    return false;
                }
            }

            return true;
        });
    };

    const filteredProjects = filterProjects();

    return (
        <>
            <h3 className="mb-2">Распределение по проектам</h3>
            <hr className="clearfix w-100 pb-0"/>

            <div className="line-btn-group line-btn-group_distribution mb-3">
                <div className={"custom-input-group"}>
                    <Button view="normal" size="xl" width="max" pin="round-brick"
                            onClick={() => getGayleShapley(false)} disabled={gettingGayleShapley}>
                        Распределить на основе заявок, участников
                    </Button>
                    <TextInput size="xl" label="&#8804;" pin="brick-round" type={"number"}
                               style={{backgroundColor: '#f9f9f9'}}
                               value={capacity} onChange={onChange}/>
                </div>
                <Button view="normal" size="xl" width="max" disabled={gettingGayleShapley}
                        onClick={() => getGayleShapley(true)}>
                    Распределить на основе заявок с учётом исключений</Button>
                <Button view="action" size="xl" width="max" disabled={gettingGayleShapley}
                        onClick={() => fix_distribution()}>
                    Зафиксировать распределение в БД</Button>
                <Button view="action" size="xl" width="max" disabled={gettingGayleShapley}
                        onClick={() => get_distribution()}>
                    Получить составы проектов из БД</Button>
            </div>

            <div className="quantity-info">
                {fixAnswerText}
            </div>

            <div className="project-filters wide-filters-label mt-4">
                <div className="filters-column">
                    <div className="filter">
                        <label htmlFor="venueFilter">Площадка:</label>
                        <Select id="venueFilter"
                                multiple={true}
                                hasClear
                                placeholder={'Все'}
                                onUpdate={(values) => setVenueFilter(values)}>
                            <Select.Option value={"-1"}>Не выбрана</Select.Option>
                            {venues.map((venue) => (<Select.Option key={venue.id}
                                                                   value={venue.id.toString()}>{venue.name}</Select.Option>))}
                        </Select>
                    </div>

                    <div className="filter">
                        <label htmlFor="streamFilter">Поток:</label>
                        <Select id="streamFilter"
                                multiple={true}
                                hasClear
                                placeholder={'Все'}
                                onUpdate={(values) => setStreamFilter(values)}>
                            {streams.map((stream) => (<Select.Option key={stream.id}
                                                                     value={stream.id.toString()}>{stream.name}</Select.Option>))}
                        </Select>
                    </div>
                </div>

                <div className="filters-column wide-filters-label narrow-filters-select">
                    <div className="filter">
                        <label htmlFor="schoolchildrenFilter">Для школьников:</label>
                        <Select id="schoolchildrenFilter"
                                hasClear
                                placeholder={'Все'}
                                onUpdate={(values) => setSchoolchildrenFilter(values)}>
                            <Select.Option value="true">Да</Select.Option>
                            <Select.Option value="false">Нет</Select.Option>
                        </Select>
                    </div>
                </div>
            </div>

            <div className="projects__quantity-info quantity-info">
                Кол-во проектов: {filteredProjects?.length || 0}
            </div>

            {distributionData?.projects &&
                <>
                    <DeletedProjects projects={deletedProjects}
                                     onRestoreProject={handleRestoreProject}
                                     onRemoveProjectsBelowThreshold={handleRemoveProjectsBelowThreshold}/>

                    <Project project={distributionData.projects.find(project => project.id === -2)}
                             selectedParticipants={selectedParticipants}
                             onSelect={handleSelectParticipant}
                             onMoveParticipants={handleMoveParticipants}
                             onUnassignParticipant={handleUnassignParticipants}
                             onDeleteParticipant={handleDeleteParticipants}/>
                    {distributionData.projects.find(project => project.id === -1) &&
                        <Project project={distributionData.projects.find(project => project.id === -1)}
                                 selectedParticipants={selectedParticipants}
                                 onSelect={handleSelectParticipant}
                                 onMoveParticipants={handleMoveParticipants}
                                 onUnassignParticipant={handleUnassignParticipants}
                                 onDeleteParticipant={handleDeleteParticipants}/>}
                    <div className="four-columns-container mt-3">
                        {filteredProjects.map((project) => (
                            <Project key={project.id} className={"columns-item"}
                                     project={project} selectedParticipants={selectedParticipants}
                                     onSelect={handleSelectParticipant}
                                     onMoveParticipants={handleMoveParticipants}
                                     onUnassignParticipant={handleUnassignParticipants}
                                     onDeleteParticipant={handleDeleteParticipants}
                                     onDeleteProject={handleDeleteProject}/>
                        ))}
                    </div>
                </>}
        </>
    );
}

export default WorkshopDirectorDistributionPage;