import React, {useEffect, useState} from 'react';
import axios from "axios";

import {
    getAcceptAuthConfig,
    MANAGER_PROJECTS_ENDPOINT, REFLECTIONS_MANAGER_ENDPOINT
} from "../../../api/api";
import ProjectManagerCard from "../../../components/projectCard/ProjectManagerCard";

function ProjectManagerList() {
    const [projects, setProjects] = useState([]);

    const [templates, setTemplates] = useState(null);
    const [userFiles, setUserFiles] = useState(null);

    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        axios
            .get(MANAGER_PROJECTS_ENDPOINT, getAcceptAuthConfig())
            .then(response => {
                setProjects(response.data);
            })
            .catch(error => {
                console.error('Error getting my projects:', error);
            });

        axios
            .get(REFLECTIONS_MANAGER_ENDPOINT, getAcceptAuthConfig())
            .then(response => {
                const templates = response.data.templates;
                const filteredTemplates = templates.filter(template => template.type_file.startsWith('manager_'));
                setTemplates(filteredTemplates);
                setUserFiles(response.data.user_files);
            })
            .catch(error => {
                console.error('Ошибка при получении шаблонов и файлов:', error);
            });
    }, []);

    return (
        <>
            <h3 className="mb-2">Мои проекты</h3>
            <hr className="clearfix w-100 pb-0"/>

            {projects.map((project) => (
                <ProjectManagerCard key={project.id} project={project}
                                    templates={templates} userFiles={userFiles} setUserFiles={setUserFiles}
                                    submitting={submitting} setSubmitting={setSubmitting}/>))}
        </>
    );
}

export default ProjectManagerList;