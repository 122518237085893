import React, {useState} from 'react'
import {useLocation, Navigate} from "react-router-dom";
import {Container, Card, Button} from "@gravity-ui/uikit";
import {Form, InputGroup} from "react-bootstrap";
import PasswordButton from "../../components/passwordButton/PasswordButton";
import {Eye, EyeSlash} from "@gravity-ui/icons";
import {connect} from 'react-redux';

import {login} from '../../actions/auth';


const LoginPage = ({login, isAuthenticated}) => {
    const [submitting, setSubmitting] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const {email, password} = formData;

    const [errors, setErrors] = useState({
        email: '',
        password: '',
        detail: ''
    });

    const location = useLocation();
    const fromPage = location.state?.from?.pathname || '/';

    if (isAuthenticated) {
        return <Navigate to={fromPage}/>
    }

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const onSubmit = e => {
        e.preventDefault();
        setSubmitting(true);
        login(email, password)
            .catch(e => {
                setErrors(JSON.parse(e.request?.responseText));
            })
            .finally(() => {
                setSubmitting(false);
            });
    };


    return (
        <Container className="mainContainer">
            <Card view="raised" className="info__card yc-s__p_5">
                <h1 className="info__title">Авторизация</h1>
                <Form onSubmit={e => onSubmit(e)}>
                    <Form.Group controlId="formEmail">
                        <Form.Label>Электронная почта</Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            value={email}
                            onChange={e => onChange(e)}
                            isInvalid={!!(errors && errors.email || errors && errors.detail)}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors && errors.email}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formPasssword">
                        <Form.Label>Пароль</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                value={password}
                                onChange={e => onChange(e)}
                                isInvalid={!!(errors && errors.password || errors && errors.detail)}
                                required
                            />
                            <PasswordButton onClick={toggleShowPassword}>
                                {showPassword ? <EyeSlash/> : <Eye/>}
                            </PasswordButton>
                        </InputGroup>
                        <Form.Control.Feedback
                            type="invalid">{(errors && errors.password || errors && errors.detail)}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Button view="action" type="submit" className="mt-3 w-100" size="l" disabled={submitting}>
                        {submitting ? 'Вход...' : 'Войти'}
                    </Button>
                </Form>

                <div className="info-footer">
                    <div><a href="/password_reset">Забыли пароль?</a> / <a href="/signup">Регистрация</a></div>
                </div>

            </Card>
        </Container>
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {login})(LoginPage);