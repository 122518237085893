import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import axios from "axios";

import {Button, Icon, Label, Text} from "@gravity-ui/uikit";
import {Check, FileCheck, FileExclamation, Xmark} from "@gravity-ui/icons";

import {
    CONFIRMATION_DETAIL_ENDPOINT,
    getAcceptAuthConfig,
    getJSONAcceptAuthConfig,
    USER_APPLICATION_DETAIL_ENDPOINT,
    USER_APPLICATION_ENDPOINT
} from "../../../api/api";
import {DeleteButtonIcon, EditButtonIcon_Link} from "../../../components/Button/Button";
import {DeleteModal} from "../../../components/Modal/Modals";
import LoadingSpinner from "../../../components/LoadingSpinner";

function ParticipantPage() {
    const [isLoading, setIsLoading] = useState(true);

    const [open, setOpen] = React.useState(false);
    const [applicationData, setApplicationData] = useState(null);
    const [applicationProjectsData, setApplicationProjectsData] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        axios
            .get(USER_APPLICATION_ENDPOINT, getJSONAcceptAuthConfig())
            .then(response => {
                setApplicationData(response.data.cv);
                const sortedProjects = response.data.projects_user.sort((a, b) => a.priority - b.priority);
                setApplicationProjectsData(sortedProjects);
            })
            .catch(error => {
                console.error('Error getting application data:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const onDelete = async (id) => {
        try {
            await axios.delete(USER_APPLICATION_DETAIL_ENDPOINT(id), getAcceptAuthConfig());
            window.location.reload();
        } catch (error) {
            window.location.reload();
            console.error('Ошибка при удалении заявки:', error);
        }
    };

    const onConfirm = async (conf) => {
        try {
            const body = JSON.stringify({participation_confirmation: conf});
            await axios.put(CONFIRMATION_DETAIL_ENDPOINT(applicationData.id), body, getJSONAcceptAuthConfig());
            window.location.reload();
        } catch (error) {
            window.location.reload();
            console.error('Ошибка при подтверждении:', error);
        }
    };

    const currentDate = new Date();
    const maxDate = new Date('2024-06-07T23:59:59.999+07:00');

    if (isLoading) {
        return (<>
            <h3 className="mb-2">Заявка на участиемне </h3>
            <hr className="clearfix w-100 pb-0"/>
            <LoadingSpinner/>
        </>)
    }

    const getThemeByStatus = (status) => {
        switch (status) {
            case 'approved':
                return 'success';
            case 'prior_approved':
                return 'warning';
            default:
                return 'danger';
        }
    };

    const getTitleByStatus = (status) => {
        switch (status) {
            case 'approved':
                return 'Вы зачислены в этот проект';
            case 'prior_approved':
                return 'Вы зачислены в проект с приоритетом выше';
            case 'stopped_project':
                return 'Проект не запустился';
            default:
                return 'Вы не прошли в этот проект';
        }
    };

    return (<>
        <h3 className="mb-2">Заявка на участие</h3>
        <hr className="clearfix w-100 pb-0"/>
        {!applicationData?.participant && currentDate <= maxDate
            && <div className="text-center">
                <Link to={"create_application"}><Button view={"action"} size={"xl"}>
                    Подать заявку на участие
                </Button></Link>
            </div>
            || applicationData?.participant &&
            <div className={"bmm-card project-card"}>
                <div className="d-flex align-items-center justify-content-between">
                    <div className={"d-flex align-items-center"}>
                        <Label theme="success" className={"me-2"}>Заявка подана</Label>
                        <h5 className={"project-card__title mt-0 mb-0"}>
                            {applicationData.workshop}
                        </h5>
                    </div>
                    {currentDate <= maxDate &&
                        <div className="group-btn">
                            <DeleteButtonIcon onClick={() => setOpen(true)}/>
                            <EditButtonIcon_Link to={`${applicationData?.id}`}/>
                        </div>}
                    {/*{applicationData?.participation_confirmation &&*/}
                    {/*    applicationData?.participation_confirmation !== "not_needed" &&*/}
                    {/*    <div className="group-btn">*/}
                    {/*        <Button view={"outlined-success"} size={"s"} onClick={() => onConfirm('confirmed')}*/}
                    {/*                disabled={applicationData?.participation_confirmation === 'confirmed'}>*/}
                    {/*            {applicationData?.participation_confirmation === 'confirmed' ?*/}
                    {/*                "Вы согласились" : "Буду участвовать"}*/}
                    {/*        </Button>*/}
                    {/*        <Button view={"outlined-danger"} size={"s"} onClick={() => onConfirm('declined')}*/}
                    {/*                disabled={applicationData?.participation_confirmation === 'declined'}>*/}
                    {/*            {applicationData?.participation_confirmation === 'declined' ?*/}
                    {/*                "Вы отказались" : "Не буду участвовать"}*/}
                    {/*        </Button>*/}
                    {/*    </div>}*/}
                </div>
                {applicationProjectsData.length > 0 &&
                    applicationProjectsData.map((project, index) => (
                        <div key={index}
                             className={"bmm-card bmm-card_white d-flex align-items-center justify-content-between"}>

                            <div className={"bmm-text_bold d-flex align-items-center"}>
                                <div className={"me-2"} title={project.status ? getTitleByStatus(project.status) : ""}>
                                    <Label icon={project.status ? <Icon size={16} data={project.status === 'approved' ? Check : Xmark}/> : false}
                                           theme={project.status ? getThemeByStatus(project.status) : "normal"}>
                                        Приоритет {index + 1}
                                    </Label>
                                </div>
                                {project.name}
                            </div>
                            {project.test_file && !project.file &&
                                <Text title={"Не загружен файл с решением тестового задания"} color="danger">
                                    <Icon data={FileExclamation} size={18}/></Text>}
                            {project.test_file && project.file &&
                                <Text title={"Файл с решением тестового задания загружен"} color="positive">
                                    <Icon data={FileCheck} size={18}/></Text>}
                        </div>))}
                <DeleteModal type="заявку на участие в" name={`${applicationData.workshop}`}
                             onDelete={() => onDelete(applicationData?.id)}
                             open={open} setOpen={setOpen}/>
            </div>}
    </>);
}

export default ParticipantPage;