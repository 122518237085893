import React, {useRef} from 'react';
import axios from "axios";

import {Form} from "react-bootstrap";
import AsyncSelect from "react-select/async";
import {components} from 'react-select';
import {closestCenter, DndContext, PointerSensor, TouchSensor, useSensor, useSensors} from "@dnd-kit/core";
import {arrayMove, SortableContext, useSortable, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import {Button, Icon, Label} from "@gravity-ui/uikit";
import {ArrowUpRightFromSquare, File, Grip, Magnifier, Xmark} from "@gravity-ui/icons";

import {getJSONAcceptAuthConfig, PROJECT_SEARCH_ENDPOINT} from "../../../api/api";
import {LinkLikeButton_File} from "../../../components/Button/Button";

const SortableProject = ({
                             isUpdate = false,
                             project,
                             id,
                             index,
                             name,
                             test_file,
                             onDeleteProject,
                             onChangeProject,
                             oldSolutionFile
                         }) => {
    const {
        attributes, listeners, setNodeRef, transform, transition,
    } = useSortable({id});
    const style = {
        transition, transform: CSS.Translate.toString(transform),
    };

    const filePicker = useRef(null);

    const onPick = () => {
        filePicker.current.click();
    };

    const onChange = (e) => {
        onChangeProject({...project, file: e.target.files[0]});
    }

    // const onDeliteFile = (e) => {
    //     onChangeProject({...project, file: "DELETED"});
    // }

    return (
        <div ref={setNodeRef} style={style}
             className="application-card d-flex align-items-center">
            <div className={"cursor-drag application-card__action"} {...attributes} {...listeners}>
                <Icon data={Grip} size={20}/>
            </div>
            <div className={'w-100'}>
                <div className="d-flex align-items-center justify-content-between">
                    <div className={"project-card__title single-line-text"}>
                        <Label theme="normal" className={"me-2"}>Приоритет {index + 1}</Label>
                        {name}
                    </div>
                    <div>
                        <a href={`/project/${id}`} target="_blank" rel="noopener noreferrer">
                            <Button className={"application-card__action"}
                                    view={"normal-contrast"} size={"m"} title={"Страница проекта"}>
                                <Icon data={ArrowUpRightFromSquare} size={18}/>
                            </Button>
                        </a>
                        <Button className={"application-card__action"} view={"normal-contrast"} size={"m"}
                                onClick={(e) => onDeleteProject(e, id)} title={"Удалить"}>
                            <Icon data={Xmark} size={18}/>
                        </Button>
                    </div>
                </div>
                {test_file && (<div className="test-file-section">
                    <div className={"test-solution"}>
                        <input hidden type="file" ref={filePicker} onChange={onChange}
                               accept="image/*,.pdf,.doc,.docx,.txt,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/zip,application/x-rar-compressed"/>
                        <Button className={"me-3"}
                                view="action" size="m"
                                onClick={onPick}>
                            Сдать тестовое задание
                        </Button>
                        {project.file && <div className="d-flex align-items-center">
                            {isUpdate && (project.file.name
                                    && <span className="me-2 filename">{project.file.name}</span>
                                    || <LinkLikeButton_File href={oldSolutionFile}
                                                            icon={<File/>} text={'Решение тестового'}
                                                            fileName={project.file.name}/>
                                )
                                || <span className="me-2 filename">{project.file.name}</span>}
                            {/*<Button className={"application-card__action delete-file-btn"}*/}
                            {/*        view={"normal-contrast"}*/}
                            {/*        size="m" onClick={onDeliteFile}>*/}
                            {/*    <Icon data={TrashBin} size={16}/>*/}
                            {/*</Button>*/}
                        </div>}
                    </div>
                    <a className={"test-file"} href={test_file} target="_blank"><File/>Тестовое задание</a>
                </div>)}
            </div>
        </div>
    );
};

const Control = ({children, ...props}) => {
    return (
        <components.Control {...props}>
            <Icon className={"react-select-icon"} data={Magnifier} size={16}/>
            {children}
        </components.Control>
    );
};

function ApplicationForm({
                             type,
                             applicationData,
                             setApplicationData,
                             applicationProjectsData,
                             setApplicationProjectsData,
                             oldAchievementsFile,
                             oldSolutionFiles = null,
                             errors,
                             onSubmit,
                             submitting,
                         }) {
    let submitButtonName, submittingButtonName;
    let isCreate = false, isUpdate = false;
    if (type === "create") {
        isCreate = true;
        submitButtonName = "Подать заявку";
        submittingButtonName = "Отправка...";
    } else if (type === "update") {
        isUpdate = true;
        submitButtonName = "Редактировать заявку";
        submittingButtonName = "Сохранение...";
    }

    const onChange = (e) => {
        const {name, value} = e.target;
        setApplicationData((prevData) => ({
            ...prevData, [name]: value,
        }));
    };

    const onFileChange = (e) => {
        const {name, files} = e.target;
        setApplicationData((prevData) => ({
            ...prevData, [name]: files[0],
        }));
    };

    const onAddProject = (e) => {
        if (e) {
            const newProject = e;
            if (!applicationProjectsData.some(project => project.id === newProject.id)) {
                setApplicationProjectsData(prevData => [...prevData, newProject]);
            }
        }
    };

    const onDeleteProject = (e, id) => {
        // Создаем новый массив, исключая элемент с заданным id
        const updatedProjectsData = applicationProjectsData.filter(project => project.id !== id);
        // Обновляем состояние applicationProjectsData
        setApplicationProjectsData(updatedProjectsData);
    }

    const onChangeProject = (project) => {
        const updatedProjectsData = applicationProjectsData?.map(item => item.id === project.id ? project : item);
        setApplicationProjectsData(updatedProjectsData);
    };

    const loadOptions = async (value, callback) => {
        if (value.length >= 4) {
            await axios.get(PROJECT_SEARCH_ENDPOINT(value), getJSONAcceptAuthConfig())
                .then((response) => {
                    callback(response.data.map(({name, id, test_file}) => ({
                        id: id, name: name, test_file: test_file,
                    })))
                });
        }
    };

    const onDragEnd = (e) => {
        const {active, over} = e;
        if (active.id === over.id) {
            return;
        }
        setApplicationProjectsData((prevData) => {
            const oldIndex = prevData.findIndex((project) => project.id === active.id);
            const newIndex = prevData.findIndex((project) => project.id === over.id);
            return arrayMove(prevData, oldIndex, newIndex);
        });
    };

    const sensors = useSensors(useSensor(PointerSensor), useSensor(TouchSensor));

    return (
        <Form onSubmit={onSubmit}>

            <Form.Group controlId="formApplicationEducation">
                <Form.Label className={"required"}>Образование</Form.Label>
                <Form.Control
                    required
                    as="textarea"
                    name="education"
                    value={applicationData?.education}
                    onChange={onChange}
                    rows={3}
                />
            </Form.Group>

            <Form.Group controlId="formApplicationExperience">
                <Form.Label>Опыт работы</Form.Label>
                <Form.Control as="textarea"
                              name="experience"
                              value={applicationData?.experience}
                              onChange={onChange}
                              rows={2}/>
                <Form.Text id="experienceHelpBlock" muted>
                    Это поле не обязательно для заполнения, однако, если у вас есть что написать, мы
                    рекомендуем вам заполнить его.
                </Form.Text>
            </Form.Group>

            <Form.Group controlId="formApplicationAchievements">
                <Form.Label>Достижения</Form.Label>
                <Form.Control as="textarea"
                              name="achievements"
                              value={applicationData?.achievements}
                              onChange={onChange}
                              rows={2}/>
                <Form.Text id="achievementsHelpBlock" muted>
                    Это поле не обязательно для заполнения, однако, если у вас есть что написать, мы
                    рекомендуем вам заполнить его.
                </Form.Text>
            </Form.Group>

            {isUpdate && oldAchievementsFile &&
                <LinkLikeButton_File href={oldAchievementsFile} icon={<File/>}
                                     text={'Документы, подтверждающие достижения'}
                                     fileName={applicationData?.file?.name}
                />}
            <Form.Group controlId="formProjectTest">
                <Form.Label>Документы, подтверждающие достижения</Form.Label>
                <Form.Control type="file"
                              name="achievements_confirmation_file"
                              onChange={onFileChange}
                              accept="image/*,.pdf,.doc,.docx,.txt,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/zip,application/x-rar-compressed"
                              aria-describedby="achievements_confirmationHelpBlock"/>
                <Form.Text id="achievements_confirmationHelpBlock" muted>
                    Прикрепите единый файл с документами, подтверждающими ваши достижения.
                </Form.Text>
            </Form.Group>

            <Form.Group controlId="formApplicationSkills">
                <Form.Label>Навыки</Form.Label>
                <Form.Control as="textarea"
                              name="skills"
                              value={applicationData?.skills}
                              onChange={onChange}
                              rows={2}/>
                <Form.Text id="skillsHelpBlock" muted>
                    Это поле не обязательно для заполнения, однако, если у вас есть что написать, мы
                    рекомендуем вам заполнить его.
                </Form.Text>
            </Form.Group>

            <Form.Group controlId="formApplicationScientificInterests">
                <Form.Label>Интересы в научной сфере</Form.Label>
                <Form.Control as="textarea"
                              name="scientific_interests"
                              value={applicationData?.scientific_interests}
                              onChange={onChange}
                              rows={2}/>
                <Form.Text id="scientific_interestsHelpBlock" muted>
                    Это поле не обязательно для заполнения, однако, если у вас есть что написать, мы
                    рекомендуем вам заполнить его.
                </Form.Text>
            </Form.Group>

            <Form.Group controlId="formApplicationExpectations">
                <Form.Label>Ожидания от мастерской</Form.Label>
                <Form.Control as="textarea"
                              name="expectations"
                              value={applicationData?.expectations}
                              onChange={onChange}
                              rows={2}/>
                <Form.Text id="expectationsHelpBlock" muted>
                    Это поле не обязательно для заполнения, однако, если у вас есть что написать, мы
                    рекомендуем вам заполнить его.
                </Form.Text>
            </Form.Group>

            <Form.Group controlId="formApplicationOtherComments">
                <Form.Label>Прочие комментарии</Form.Label>
                <Form.Control as="textarea"
                              name="other_comments"
                              value={applicationData?.other_comments}
                              onChange={onChange}
                              rows={2}/>
            </Form.Group>

            <Form.Group controlId="formApplicationProjects">
                <Form.Label className={"required"}>Проекты</Form.Label>
                <AsyncSelect required={false}
                             name="projects"
                             value={""}
                             onChange={onAddProject}
                             loadOptions={loadOptions}
                             components={{Control, DropdownIndicator: null}}
                             getOptionLabel={option => option.name}
                             getOptionValue={option => option.id}
                             classNamePrefix="bmm-select"
                             isClearable={true}
                             placeholder={"Поиск по названию проекта..."}
                             openMenuOnClick={false}
                             loadingMessage={() => 'Проект не найден'}
                             noOptionsMessage={() => 'Проект не найден'}
                             aria-invalid={!!(errors && errors.projects)}
                             aria-describedby={`projectsHelpBlock`}
                />
                <Form.Control.Feedback type="invalid">{errors && errors.projects}</Form.Control.Feedback>
                <Form.Text id="projectsHelpBlock" muted>
                    Выберите НЕ МЕНЕЕ трёх проектов, в которых хотите принять участие.
                    Расположите выбранные проекты в порядке приоритета, начиная с наиболее желаемого.
                    Если в каком-либо из проектов предусмотрено тестовое задание, не забудьте прикрепить решение к
                    соответствующему проекту перед отправкой формы.
                </Form.Text>
            </Form.Group>

            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onDragEnd}>
                {applicationProjectsData &&
                    <SortableContext items={applicationProjectsData} strategy={verticalListSortingStrategy}>
                        {applicationProjectsData?.length > 0 && applicationProjectsData?.map((project, index) => (
                            <SortableProject key={project.id}
                                             isUpdate={isUpdate}
                                             project={project}
                                             id={project.id} index={index} name={project.project?.name || project.name}
                                             test_file={project.project?.test_file || project.test_file}
                                             onDeleteProject={onDeleteProject} onChangeProject={onChangeProject}
                                             oldSolutionFile={oldSolutionFiles ? oldSolutionFiles[index]?.file : null}
                            />))}
                    </SortableContext>}
            </DndContext>

            <Button view="action" type="submit" disabled={submitting} className="mt-3 w-100" size="l">
                {submitting ? submittingButtonName : submitButtonName}
            </Button>

        </Form>);
}

export default ApplicationForm;