import React, {useEffect, useState} from 'react';
import axios from "axios";

import {Form} from 'react-bootstrap';
import Select from 'react-select';
import CreatableSelect from "react-select/creatable";
import AsyncSelect from "react-select/async";
import UserCard from "../../components/userCard/UserCard";
import {
    getJSONAcceptAuthConfig,
    getJSONConfig,
    KEYWORDS_ENDPOINT, STREAMS_ENDPOINT,
    USER_SEARCH_ENDPOINT,
    VENUES_ENDPOINT
} from "../../api/api";
import TipTap from "../../components/Editor/TipTap";
import Profile from "../../components/Profile";
import {DeleteButtonIcon} from "../../components/Button/Button";

export const ProjectNameField = ({projectData, onChange, errors}) => {
    return (<Form.Group controlId="formProjectName">
        <Form.Label className="required">Название проекта</Form.Label>
        <Form.Control required
                      type="text"
                      name="name"
                      value={projectData.name}
                      onChange={onChange}
                      autoComplete="off"
                      aria-describedby="nameHelpBlock"
                      isInvalid={!!(errors && errors.name)}/>
        <Form.Control.Feedback type="invalid">{errors && errors.name}</Form.Control.Feedback>
        <Form.Text id="nameHelpBlock" muted>
            Придумайте короткое и уникальное название проекта. Его можно будет поменять позже.
            Желательно, чтобы название проекта, во-первых, отражало суть проекта, во-вторых, было
            привлекательным для потенциальных участников.
        </Form.Text>
    </Form.Group>);
};

export const ProjectVenueField = ({disabled = false, projectData, setProjectData, errors}) => {
    const [venues, setVenues] = useState([]);
    useEffect(() => {
        axios
            .get(VENUES_ENDPOINT, getJSONConfig())
            .then(response => {
                const newVenues = [{label: 'Не знаю/не определился', value: '-1'}, ...response.data.map((venue) => ({
                    label: `${venue.name}, ${venue.city}, ${venue.address}`, value: venue.id.toString()
                })),];
                setVenues(newVenues);
            });
    }, []);

    const onVenueChange = (e, projectDataField) => {
        const value = !e ? "" : e.value;
        setProjectData((prevData) => ({
            ...prevData, [projectDataField]: value,
        }));
    };

    return (
        <Form.Group controlId="formProjectVenue">
            <Form.Label className="required">Площадка</Form.Label>
            <Select required
                    isDisabled={disabled}
                    name="venue"
                    value={projectData.venue?.id ? venues.find((venue) => venue.value === projectData.venue.id.toString()) : venues.find((venue) => venue.value === (projectData.venue ? projectData.venue : '-1'))}
                    onChange={(e) => onVenueChange(e, 'venue')}
                    options={venues}
                    noOptionsMessage={() => 'Площадка не найдена'}
                    components={{IndicatorSeparator: null}}
                    classNamePrefix="bmm-select"
                    placeholder={null}
            />
            <Form.Control.Feedback type="invalid">{errors && errors.venue}</Form.Control.Feedback>
        </Form.Group>);
};

export const ProjectStreamField = ({disabled = false, hasNotVenue = false, projectData, setProjectData, errors}) => {
    const [streams, setStreams] = useState([]);
    useEffect(() => {
        axios
            .get(STREAMS_ENDPOINT, getJSONConfig())
            .then(response => {
                const newStreams = [...response.data.map((stream) => ({
                    label: stream.name, value: stream.id
                })),];
                setStreams(newStreams);
            });
    }, []);

    const onStreamChange = (e, projectDataField) => {
        const value = !e ? "" : e.value;
        setProjectData((prevData) => ({
            ...prevData, [projectDataField]: value,
        }));
    };

    return (<Form.Group controlId="formProjectVenue">
        <Form.Label className="required">Поток</Form.Label>
        <Select required
                isDisabled={disabled || hasNotVenue}
                name="stream"
                value={(projectData.stream && !hasNotVenue && streams.find((stream) => stream.value === projectData.stream))}
                onChange={(e) => onStreamChange(e, 'stream')}
                options={streams}
                noOptionsMessage={() => 'Поток не найден'}
                components={{IndicatorSeparator: null}}
                classNamePrefix="bmm-select"
                placeholder={hasNotVenue && 'Выберите площадку'}
        />
        <Form.Control.Feedback type="invalid">{errors && errors.local_stream}</Form.Control.Feedback>
    </Form.Group>);
};

export const ProjectKeywordsField = ({projectData, setProjectData, errors}) => {
    const [keywords, setKeywords] = useState([]);
    useEffect(() => {
        axios
            .get(KEYWORDS_ENDPOINT, getJSONConfig())
            .then(response => {
                setKeywords(response.data && response.data.map(({name}) => ({
                    label: name, value: name
                })));
            });
    }, []);

    const onTagsChange = (e, projectDataField) => {
        const labelsArray = (!e || e.length === 0) ? [] : e.map(item => item.label.trim());
        setProjectData((prevData) => ({
            ...prevData, [projectDataField]: labelsArray,
        }));
    };

    return (<Form.Group controlId="formProjectTokens" className={errors && errors.keywords && "is-invalid"}>
        <Form.Label className="required">Ключевые слова</Form.Label>
        <CreatableSelect required isMulti options={keywords}
                         name="keywords"
                         value={projectData.keywords && projectData.keywords.map((keyword) => ({
                             label: keyword, value: keyword
                         }))}
                         onChange={(e) => onTagsChange(e, 'keywords')}
                         components={{DropdownIndicator: null, ClearIndicator: null}}
                         classNamePrefix="bmm-select"
                         noOptionsMessage={() => 'Тег уже выбран'}
                         placeholder={"Выберите из списка и/или введите через Enter\u21B5"}
                         formatCreateLabel={(inputText) => `Добавить "${inputText}"`}
                         aria-describedby="keywordsHelpBlock"
                         aria-invalid={!!(errors && errors.keywords)}/>
        <Form.Control.Feedback type="invalid">{errors && errors.keywords}</Form.Control.Feedback>
        <Form.Text id="keywordsHelpBlock" muted>
            Укажите общие ключевые слова, не используя узко специализированных слов. Они нужны, чтобы
            потенциальный участник сориентировался на сайте и выбрал по-настоящему интересный для него
            проект.<br/>
            <strong>Например:</strong> python, моделирование, оптимизация и т.д.
        </Form.Text>
    </Form.Group>);
};

export const ProjectDescriptionShortField = ({projectData, setProjectData, onChange, errors}) => {
    return (<Form.Group controlId="formProjectDescriptionShort">
        <Form.Label className="required">Краткое описание проекта (не более 700 символов)</Form.Label>
        {/*<Form.Control required*/}
        {/*              as="textarea"*/}
        {/*              name="description"*/}
        {/*              value={projectData.description || ''}*/}
        {/*              onChange={onChange}*/}
        {/*              rows={4} maxLength={700}*/}
        {/*              aria-describedby="descriptionHelpBlock"*/}
        {/*              isInvalid={!!(errors && errors.description)}/>*/}
        {/*<Form.Control.Feedback type="invalid">{errors && errors.description}</Form.Control.Feedback>*/}
        <TipTap projectData={projectData} setProjectData={setProjectData}/>
        <div className="d-flex justify-content-center"><Form.Control className="hidden"
                                                                     as="textarea"
                                                                     name="description_text_editor"/></div>
        <Form.Text id="descriptionHelpBlock" muted>
            Очертите проблематику, которой посвящен проект, и намекните на то, чем будет заниматься команда
            проекта.<br/>
            Написать о проекте коротко и емко — трудно. При необходимости мы предложим, как переписать этот
            текст для размещения на сайте.
        </Form.Text>
    </Form.Group>);
};

const onFileChange = (e, setProjectData) => {
    const {name, files} = e.target;
    setProjectData((prevData) => ({
        ...prevData, [name]: files[0],
    }));
};

export const ProjectDescriptionFullField = ({setProjectData, errors, required = false}) => {
    return (<Form.Group controlId="formProjectDescriptionFull">
        <Form.Label className={required ? 'required' : ''}>Полное описание проекта</Form.Label>
        <Form.Control required={required}
                      type="file"
                      name="full_description_file"
                      onChange={(e) => onFileChange(e, setProjectData)}
                      aria-describedby="full_description_fileHelpBlock"
                      isInvalid={!!(errors && errors.full_description_file)}/>
        <Form.Control.Feedback
            type="invalid">{errors && errors.full_description_file}</Form.Control.Feedback>
        <Form.Text id="full_description_fileHelpBlock" muted>
            В чем состоит ситуация, вокруг которой разворачивается проект? Здесь можно написать об истории
            области, которой посвящен проект.<br/>
            В чем состоит проблема в этой ситуации? Расскажите о конкретных проблемах, которые возникают в
            области.<br/>
            В чем состоит цель проекта? Над какой из проблем планируется работать?
        </Form.Text>
    </Form.Group>);
};

export const ProjectResultsFileField = ({setProjectData, errors, required = false}) => {
    return (<Form.Group controlId="formProjectResultsFile">
        <Form.Label className={required ? 'required' : ''}>Итоговая заметка проекта</Form.Label>
        <Form.Control required={required}
                      type="file"
                      name="results_file"
                      onChange={(e) => onFileChange(e, setProjectData)}
                      isInvalid={!!(errors && errors.results_file)}/>
        <Form.Control.Feedback
            type="invalid">{errors && errors.results_file}</Form.Control.Feedback>
    </Form.Group>);
};

export const ProjectResultsURLField = ({projectData, onChange, errors})  => {
    const insertURL = (event) => {
        event.preventDefault();
        onChange({ target: { name: 'results_url', value: projectData.results_application_url } });
    };
    // Тут должно быть текстовое поле с вводом ссылки
    return (<Form.Group controlId="formProjectResultsURL">
        <Form.Label>
            Ссылка на итоговую заметку проекта
                {projectData.results_application_url && (
                    <a href="#" onClick={insertURL} style={{ marginLeft: '5px' }}>
                        <br/>вставить ссылку куратора
                    </a>
                )}
        </Form.Label>
        <Form.Control type="url"
                      name="results_url"
                      value={projectData.results_url || ''}
                      onChange={onChange}
                      aria-describedby="results_urlHelpBlock"
                      isInvalid={!!(errors && errors.results_url)}/>
        <Form.Control.Feedback
            type="invalid">{errors && errors.results_url}</Form.Control.Feedback>
        <Form.Text id="results_urlHelpBlock" muted>
            Если у вас есть ссылка на итоговую заметку проекта, укажите ее здесь.
        </Form.Text>
    </Form.Group>);
}

export const ProjectTestField = ({setProjectData, errors}) => {
    return (<Form.Group controlId="formProjectTest">
        <Form.Label>Тестовое задание</Form.Label>
        <Form.Control type="file"
                      name="test_file"
                      onChange={(e) => onFileChange(e, setProjectData)}
                      aria-describedby="test_fileHelpBlock"/>
        <Form.Text id="test_fileHelpBlock" muted>
            Если хотите, для отбора участников можно просить всех участников, желающих попасть к вам в
            проект,
            выполнить тестовое задание. Если вы хотите давать участникам тестовое задание, прикрепите,
            пожалуйста, текстовый файл с тестовым заданием.
        </Form.Text>
    </Form.Group>);
};

export const ProjectIsForSchoolchildrenField = ({projectData, onChange}) => {
    return (<Form.Group>
        <Form.Label as="legend" className="required">Проект для школьников?</Form.Label>
        <Form.Check required
                    inline type="radio" id="radio-is_for_schoolchildren-1"
                    label="Да"
                    name="is_for_schoolchildren"
                    value="Да"
                    checked={projectData.is_for_schoolchildren === true}
                    onChange={onChange}/>
        <Form.Check required
                    inline type="radio" id="radio-is_for_schoolchildren-2"
                    label="Нет"
                    name="is_for_schoolchildren"
                    value="Нет"
                    checked={projectData.is_for_schoolchildren === false}
                    onChange={onChange}/>
    </Form.Group>);
};

export const ProjectRequirementsField = ({projectData, setProjectData, errors}) => {
    const onRequirementsChange = (e, projectDataField) => {
        const labelsArray = (!e || e.length === 0) ? [] : e.map(item => {
            const trimmedLabel = item.label.trim();
            return trimmedLabel ? trimmedLabel : null;
        }).filter(label => label !== null);

        setProjectData((prevData) => ({
            ...prevData, [projectDataField]: labelsArray.join('\n'),
        }));
    };

    return (<Form.Group controlId="formProjectRequirements">
        <Form.Label className="required">Требования к участникам</Form.Label>
        <CreatableSelect required isMulti
                         name="requirements"
                         value={projectData.requirements && projectData.requirements.split('\n').map((requirement) => ({
                             label: requirement, value: requirement
                         }))}
                         onChange={(e) => onRequirementsChange(e, 'requirements')}
                         components={{DropdownIndicator: null, ClearIndicator: null, Menu: () => null}}
                         classNamePrefix="bmm-select"
                         placeholder={"Введите через Enter\u21B5"}
                         aria-describedby="requirementsHelpBlock"/>
        <Form.Control.Feedback type="invalid">{errors && errors.requirements}</Form.Control.Feedback>
        <Form.Text id="requirementsHelpBlock" muted>
            Четко сформулируйте, какие навыки и умения должны быть у участников.<br/>
            <strong>Например:</strong> уметь программировать на языке Python на базовом уровне
            (циклы, массивы, задание функций), уметь находить жорданову форму матрицы, уметь находить
            интегралы при помощи вычетов.
        </Form.Text>
    </Form.Group>);
};

const loadOptions = async (value, callback) => {
    if (value.length >= 3) {
        await axios.get(USER_SEARCH_ENDPOINT(value), getJSONAcceptAuthConfig())
            .then((response) => {
                callback(response.data.map(({email, last_name, first_name, middle_name, avatar, title}) => ({
                    label: email,
                    value: email,
                    email: email,
                    last_name: last_name,
                    first_name: first_name,
                    middle_name: middle_name,
                    avatar: avatar,
                    title: title,
                })))
            });
    }
};

const customOption = ({label, data, innerProps}) => (
    <div {...innerProps} className="py-1 px-2 custom-option">
        <UserCard name={`${data.last_name} ${data.first_name}`}
                  email={`${label}`}
                  imgUrl={data.avatar}
                  size={'xs'}/>
    </div>
);

export const AsyncSelectField = ({
                                     controlId, label, required,
                                     name,
                                     value,
                                     onChange,
                                     errors,
                                     helpBlock = ""
                                 }) => {
    return (
        <Form.Group controlId={controlId}>
            <Form.Label className={required ? 'required' : ''}>{label}</Form.Label>
            <AsyncSelect
                required={required}
                name={name}
                value={value}
                onChange={e => onChange(e, name)}
                loadOptions={loadOptions}
                components={{DropdownIndicator: null, Option: customOption}}
                classNamePrefix="bmm-select"
                isClearable={true}
                placeholder={null}
                openMenuOnClick={false}
                loadingMessage={() => 'Пользователь не найден'}
                noOptionsMessage={() => 'Пользователь не найден'}
                aria-describedby={`${name}HelpBlock`}
                // aria-invalid={!!(errors && errors[name])}
            />
            {/*<Form.Control.Feedback type="invalid">{errors && errors[name]}</Form.Control.Feedback>*/}
            {helpBlock && <Form.Text id={`${name}HelpBlock`} muted>{helpBlock}</Form.Text>}
        </Form.Group>
    );
};

export const ProjectManagerField = ({projectData, setProjectData, errors, setErrors, required = true}) => {
    const onManagerChange = (e, projectDataField) => {
        if (e) {
            const manager = !e ? {} : e;

            const isManagerExists = projectData.managers?.some(item => item.email === manager.email);

            if (!isManagerExists) {
                const newManagers = Array.isArray(projectData.managers) ? [...projectData.managers, manager] : [manager];

                setProjectData((prevData) => ({
                    ...prevData,
                    'managers': newManagers,
                }));
            }
        }
    };

    const onDeleteManager = (e, index) => {
        const newManagers = [...projectData.managers];
        newManagers.splice(index, 1);

        setProjectData((prevData) => ({
            ...prevData,
            'managers': newManagers,
        }));

        if (errors && errors.managers) {
            const managersErrors = [...errors.managers];
            managersErrors.splice(index, 1);

            setErrors((prevData) => ({
                ...prevData,
                'managers': managersErrors,
            }));
        }
    }

    return (
        <>
            <AsyncSelectField required={required && (projectData.managers?.length === 0 || !projectData.managers)}
                              controlId="formProjectManager"
                              label="Электронная почта куратора"
                              name="managers"
                              value={""}
                              onChange={onManagerChange}
                              errors={errors}
                              helpBlock="Начните вводить ФИО или почту и выберите куратора из предложенного списка зарегистрированных пользователей. Куратор предварительно должен быть зарегистрирован на сайте, а также ему необходимо загрузить свою фотографию и заполнить поля ФИО и должности."
            />
            {projectData.managers?.length > 0 && projectData.managers.map((manager, index) => (
                <div key={index} className={"person-card-in-form"}>
                    <div className={"d-flex justify-content-between align-items-center"}>
                        <Profile size="sp" profile={manager}/>
                        <DeleteButtonIcon onClick={(e) => onDeleteManager(e, index)}/>
                    </div>
                    <Form.Control.Feedback
                        type="invalid">{errors && errors.managers && errors.managers[index]}</Form.Control.Feedback>
                </div>
            ))}
        </>
    )
};

export const ProjectCustomerField = ({projectData, setProjectData, errors, setErrors, required = true}) => {
    const onCustomerChange = (e, projectDataField) => {
        if (e) {
            const customer = !e ? {} : e;

            const isCustomerExists = projectData.customers?.some(item => item.email === customer.email);

            if (!isCustomerExists) {
                const newCustomers = Array.isArray(projectData.customers) ? [...projectData.customers, customer] : [customer];

                setProjectData((prevData) => ({
                    ...prevData,
                    'customers': newCustomers,
                }));
            }
        }
    };

    const onDeleteCustomer = (e, index) => {
        const newCustomers = [...projectData.customers];
        newCustomers.splice(index, 1);

        setProjectData((prevData) => ({
            ...prevData,
            'customers': newCustomers,
        }));

        if (errors && errors.customers) {
            const customersErrors = [...errors.customers];
            customersErrors.splice(index, 1);

            setErrors((prevData) => ({
                ...prevData,
                'customers': customersErrors,
            }));
        }
    }

    return (
        <>
            <AsyncSelectField required={required && (projectData.customers?.length === 0 || !projectData.customers)}
                              controlId="formProjectCustomer"
                              label="Электронная почта заказчика"
                              name="customers"
                              value={""}
                              onChange={onCustomerChange}
                              errors={errors}
                              helpBlock="Начните вводить ФИО или почту и выберите заказчика из предложенного списка зарегистрированных пользователей. Заказчик предварительно должен быть зарегистрирован на сайте, а также ему необходимо загрузить свою фотографию и заполнить поля ФИО и должности."
            />
            {projectData.customers?.length > 0 && projectData.customers.map((customer, index) => (
                <div key={index} className={"person-card-in-form"}>
                    <div className={"d-flex justify-content-between align-items-center"}>
                        <Profile size="sp" profile={customer}/>
                        <DeleteButtonIcon onClick={(e) => onDeleteCustomer(e, index)}/>
                    </div>
                    <Form.Control.Feedback
                        type="invalid">{errors && errors.customers && errors.customers[index]}</Form.Control.Feedback>
                </div>
            ))}
        </>
    )
};

export const ProjectManagerRequirementsField = ({projectData, onChange}) => {
    return (<Form.Group controlId="formProjectManagerRequirements">
        <Form.Label>Требования к куратору (не более 700 символов)</Form.Label>
        <Form.Control as="textarea"
                      name="manager_requirements"
                      value={projectData.manager_requirements}
                      onChange={onChange}
                      rows={4} maxLength={700}
                      aria-describedby="manager_requirementsHelpBlock"/>
        <Form.Text id="manager_requirementsHelpBlock" muted>
            Мы попробуем найти куратора для вашего проекта. Пожалуйста, укажите требования, которым
            потенциальный куратор должен удовлетворять.<br/>
            <strong>Например:</strong> если проект связан с программированием, то можно написать, что у
            куратора должны быть соответствующие навыки; если достаточно, чтобы куратор имел широкий
            кругозор в математике, то можно написать прямо так.
        </Form.Text>
    </Form.Group>);
};

export const ProjectCommentField = ({projectData, onChange}) => {
    return (<Form.Group controlId="formProjectManagerRequirements">
        <Form.Label>Комментарии (не более 700 символов)</Form.Label>
        <Form.Control as="textarea"
                      name="comment"
                      value={projectData.comment}
                      onChange={onChange}
                      rows={4} maxLength={700}
                      aria-describedby="commentHelpBlock"/>
        <Form.Text id="commentHelpBlock" muted>
            Здесь вы можете оставить комментарии к вашему проекту. Это поле может содержать любую информацию, которую вы
            считаете важной или полезной.
        </Form.Text>
    </Form.Group>);
};