import React from 'react';
import {Navigate, useLocation} from "react-router-dom";
import {connect} from "react-redux";

import LoadingSpinner from "../components/LoadingSpinner";

function CanManage({children, has_rights, user}) {
    const location = useLocation();
    if (user === null) {
        return <LoadingSpinner/>
    }
    return has_rights ? children : <Navigate to='/account' state={{from: location}} replace={true}/>;
    // TODO: сделать нормальную заглушку (типо страницу "у вас нет прав")
}

const mapStateToProps = state => ({
    user: state.auth.user,
});

export default connect(mapStateToProps)(CanManage);