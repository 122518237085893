import React, {useEffect, useState} from 'react';
import axios from "axios";

import {
    Avatar,
    Icon,
    Label,
    Popover,
    Popup,
    Table,
    Text,
    TextArea,
    User,
    withTableSettings,
    Button
} from "@gravity-ui/uikit";
import {CircleCheck, CircleCheckFill, CircleXmark, CircleXmarkFill} from '@gravity-ui/icons';

import {
    getJSONAcceptAuthConfig,
    TRAVEL_GRANTS_REVIEWER_COMMENT_ENDPOINT,
    UPDATE_LIVING_ENDPOINT
} from "../../api/api";
import {TRAVEL_STATUS_CHOICES} from "../travelCard/utils";
import {DEFAULT_IMAGE_URL} from "../../utils/const";

const truncateText = (text, len) => {
    if (text?.length > len + 2) {
        return text.slice(0, len) + '...';
    } else {
        return text;
    }
}

const ReviewerComment = ({item}) => {
    const [comment, setComment] = useState(item.reviewer_comment);
    const [loading, setLoading] = useState(false);

    const buttonRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);

    const handleSave = async () => {
        setLoading(true);
        try {
            const body = JSON.stringify({reviewer_comment: comment});
            await axios.put(TRAVEL_GRANTS_REVIEWER_COMMENT_ENDPOINT(item.id), body, getJSONAcceptAuthConfig());
            item.reviewer_comment = comment
            setOpen(false);
        } catch (error) {
            console.error('Error saving comment', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div ref={buttonRef} onClick={() => setOpen((prevOpen) => !prevOpen)}
                 style={{cursor: "pointer"}}>
                {item.reviewer_comment ? truncateText(item.reviewer_comment, 7) : "–"}
            </div>
            <Popup anchorRef={buttonRef} open={open} placement="bottom"
                   onOutsideClick={() => setOpen(false)}>
                <div className={"p-2"}>
                    <TextArea
                        value={comment || ""}
                        onChange={(e) => setComment(e.target.value)}
                        placeholder="Введите комментарий"
                    />

                    <Button onClick={handleSave} disabled={loading} width={"max"} className={"mt-2"}>
                        {loading ? 'Сохранение...' : 'Сохранить'}
                    </Button>

                </div>
            </Popup>
        </>
    );
};

function TravelGrantsTable({grants, setGrants, allGrants, setAllGrants}) {
    const TravelTable = withTableSettings(Table);
    const initialSettings = [];
    const [settings, setSettings] = React.useState(() => {
        const savedSettings = localStorage.getItem('tableSettings');
        return savedSettings ? JSON.parse(savedSettings) : initialSettings;
    });
    const [statusUpdating, setStatusUpdating] = React.useState(false);


    useEffect(() => {
        localStorage.setItem('tableSettings', JSON.stringify(settings));
    }, [settings]);

    const truncateLength = 7;
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).catch(err => {
            console.error('Could not copy text: ', err);
        });
    };

    const contactTemplate = (item) => {
        return (
            <Text>
            <span onClick={() => copyToClipboard(item.user.email)} style={{cursor: 'pointer'}}>
                {item.user.email}
            </span>
                <br/>
                <span onClick={() => copyToClipboard(item.user.telegram_username)} style={{cursor: 'pointer'}}>
                @{item.user.telegram_username}
            </span>
                <br/>
                <span onClick={() => copyToClipboard(item.user.phone_number)} style={{cursor: 'default'}}>
                {item.user.phone_number}
            </span>
            </Text>
        );
    };

    const TRANSPORT_CHOICES = {
        'C': 'Автомобиль',
        'T': 'Поезд',
        'B': 'Автобус',
        'A': 'Самолет'
    };

    function updateLiving(grants, setGrants, updatedLiving) {
        const newGrants = grants.map((grant) => {
            const newLivings = grant.livings.map((living) => {
                if (living.id === updatedLiving.id) {
                    return updatedLiving;
                }
                return living;
            });
            return {...grant, livings: newLivings};
        });
        setGrants(newGrants);
    }

    function onChangeTravelStatus(old_status, new_status, id) {
        setStatusUpdating(true);
        if (old_status === new_status)
            new_status = 'P';
        axios
            .put(UPDATE_LIVING_ENDPOINT(id), {status: new_status}, getJSONAcceptAuthConfig())
            .then((response) => {
                const updatedLiving = response.data;
                // обновляем данные в grants
                updateLiving(grants, setGrants, updatedLiving);
                // обновляем данные в allGrants
                updateLiving(allGrants, setAllGrants, updatedLiving);
            })
            .catch((error) => {
                console.error('Error updating travel status:', error);
            })
            .finally(() => {
                setStatusUpdating(false);
            });
    }

    const columns = [
        {
            id: 'user',
            name: 'Пользователь',
            template: (item) => {
                return (
                    <Popover contentClassName="bmm-popover-motivation"
                             placement={"top"}
                             behavior="immediate"
                             htmlContent={`${item.user.last_name} ${item.user.first_name} ${item.user.middle_name}`}
                    >
                        <div className="single-line-text line-clamp-2"><User
                            name={`${item.user.last_name} ${item.user.first_name[0]}.${item.user.middle_name[0]}.`}
                            avatar={<Avatar imgUrl={item.user.avatar || DEFAULT_IMAGE_URL}/>}></User></div>
                    </Popover>)
            },
            meta: {defaultSortOrder: 'asc'},
        },
        {
            id: 'accommodation_info',
            name: 'Запрашиваемое проживание',
            template: (item) => {
                return (
                    <div>
                        {item.livings.map(living => (
                            <div key={living.id} className="living-item">
                                <div className="workshop-details">
                                    {/*<div className="header">Local Workshop Details</div>*/}
                                    <div className="mb-1">
                                        <Popover contentClassName="bmm-popover-motivation"
                                                 placement={"left"}
                                                 behavior="immediate"
                                                 htmlContent={
                                                     `
                         <div>
                             <span>Транспорт: ${TRANSPORT_CHOICES[living.transport]}</span><br/>
<!--                             <span>Дата прибытия: ${new Date(living.arrival_date).toLocaleDateString('en-GB', {
                                                         day: '2-digit',
                                                         month: '2-digit'
                                                     })}</span><br/>-->
<!--                             <span>Дата отбытия: ${new Date(living.departure_date).toLocaleDateString('en-GB', {
                                                         day: '2-digit',
                                                         month: '2-digit'
                                                     })}</span><br/>-->
                             <span>Время прибытия: ${living.arrival_time}</span><br/>
                             <span>Первый раз в городе: ${living.is_first_time ? "Да" : "Нет"}</span><br/>
                             <span>Статус: ${TRAVEL_STATUS_CHOICES[living.status].label}</span><br/>
                             <span>ID заявки: ${living.travel_grant}</span><br/>
                                <span>ID проживания: ${living.id}</span>
                         </div>`}>
                                            <div className={"d-flex align-items-center gap-3"}>
                                                {living?.status &&
                                                    <Label theme={TRAVEL_STATUS_CHOICES[living.status].theme}
                                                           size={"s"}>
                                                        <div className={"d-flex gap-1"}>
                                                            <button type={"button"} className={"reset-style-button"}
                                                                    disabled={statusUpdating}
                                                                    onClick={() => onChangeTravelStatus(living?.status, 'A', living.id)}>
                                                                <Icon size={20}
                                                                      data={living?.status === 'A' ? CircleCheckFill : CircleCheck}/>
                                                            </button>
                                                            <button type={"button"} className={"reset-style-button"}
                                                                    disabled={statusUpdating}
                                                                    onClick={() => onChangeTravelStatus(living?.status, 'R', living.id)}>
                                                                <Icon size={20}
                                                                      data={living?.status === 'R' ? CircleXmarkFill : CircleXmark}/>
                                                            </button>
                                                        </div>
                                                    </Label>}
                                                <Label theme={TRAVEL_STATUS_CHOICES[living.status].theme}
                                                       size={"s"}>
                                                    ({new Date(living.arrival_date).toLocaleDateString('en-GB', {
                                                    day: '2-digit',
                                                    month: '2-digit'
                                                })} —
                                                    {new Date(living.departure_date).toLocaleDateString('en-GB', {
                                                        day: '2-digit',
                                                        month: '2-digit'
                                                    })}) {truncateText(living.local_workshop.venue.name, truncateLength)}
                                                </Label>
                                            </div>
                                        </Popover>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                );
            },
            meta: {sort: false},
        },
        {
            id: 'birth_year',
            name: 'ДР',
            template: (item) => {
                return item.user.birth_date;
            },
            meta: {sort: false},
        },
        {
            id: 'city',
            name: 'Город',
            template: (item) => {
                return (
                    <Popover contentClassName="bmm-popover-motivation"
                             placement={"top"}
                             behavior="immediate"
                             htmlContent={`${item.user.address}`}
                    >
                        <div
                            className="single-line-text line-clamp-2">{truncateText(item.user.address, truncateLength)}</div>
                    </Popover>)
            },
            meta: {sort: false},
        },
        {
            id: 'contacts',
            name: 'Контакты',
            template: contactTemplate,
            meta: {sort: false},
        },
        {
            id: 'accompanying',
            name: 'Сопровождающий',
            template: (item) => {
                return (<Text>{item.accompanying_name}<br/>{item.accompanying_phone}</Text>);
            },
            meta: {sort: false},
        },
        {
            id: 'motivation_letter',
            name: 'Мотивация',
            template: (item) => {
                return (
                    item.motivation
                        ?
                        <Popover contentClassName="bmm-popover-motivation"
                                 placement={"top"}
                                 behavior="immediate"
                                 htmlContent={`${item.motivation}`}
                        >
                            <div
                                className="single-line-text line-clamp-2">{truncateText(item.motivation, truncateLength)}</div>
                        </Popover>

                        : null
                );
            },
            meta: {sort: false},
        },
        {
            id: 'comment',
            name: 'Комментарий',
            template: (item) => {
                return (
                    item.motivation
                        ?
                        <Popover contentClassName="bmm-popover-motivation"
                                 placement={"top"}
                                 behavior="immediate"
                                 htmlContent={`${item.comment}`}
                        >
                            <div
                                className="single-line-text line-clamp-2">{truncateText(item.comment, truncateLength)}</div>
                        </Popover>

                        : null
                );
            },
            meta: {sort: false},
        },
        {
            id: 'reviewer_comment',
            name: 'Комментарий',
            template: (item) => {
                return (
                    <ReviewerComment item={item}/>
                );
            },
            meta: {sort: false},
        },
        {
            id: 'passport',
            name: 'Паспорт',
            template: (item) => {
                return (
                    item.motivation
                        ?
                        <Popover contentClassName="bmm-popover-motivation"
                                 placement={"top"}
                                 behavior="immediate"
                                 htmlContent={`
                         <div>
                             <span>Пол: ${item.passport.gender}</span><br/>
                             <span>Гражданство: ${item.passport.citizenship}</span><br/>
                             <span>Номер: ${item.passport.number}</span><br/>
                             <span>Выдан: ${item.passport.issue_date}</span><br/>
                             <span>${item.passport.issuer}</span><br/>
                             <span>Код: ${item.passport.issuer_code}</span><br/>
                         </div>`}
                        >
                            <div className="single-line-text line-clamp-2">{item.passport.number}</div>
                        </Popover>

                        : null
                );
            },
            meta: {sort: false},
        },
    ];


    return (
        <TravelTable className="grant-table"
                     data={grants}
                     settings={settings}
                     updateSettings={(settings) => {
                         setSettings(settings);
                         return Promise.resolve();
                     }}
                     columns={columns}/>
    );
}

export default TravelGrantsTable;