import React from 'react'

const HelpPage = () => {
    return (
        <>
            <div className="page-title">Помощь</div>
        </>
    )
}

export default HelpPage