import React from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {connect} from 'react-redux';

import LoadingSpinner from "../components/LoadingSpinner";

const CanCreateProject = ({children, user}) => {
    const location = useLocation();
    if (user === null) {
        return <LoadingSpinner/>
    }

    const currentDate = new Date();
    const maxDate = new Date('2024-04-03T23:59:59.999+07:00');
    // TODO заменить на дату дедлайна подачи проектов + добавить дату начала подачи проектов

    return (!user?.last_name || !user?.first_name || !user?.title || user?.avatar == null || currentDate > maxDate && !(user?.is_ws_director || user?.is_lws_director || user?.is_ls_manager))
        ? <Navigate to='/account/project' state={{from: location}} replace={true}/>
        : children;
}

const mapStateToProps = state => ({
    user: state.auth.user,
});

export default connect(mapStateToProps)(CanCreateProject);