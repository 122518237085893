import React from 'react';
import './Timeline.css';
import NavList from "../navList/NavList";

import {CircleCheck} from '@gravity-ui/icons';
import {Clock} from '@gravity-ui/icons';

const Timeline = ({position = 'left', children}) => {
    return (
        <div className={"timeline left"}>
            {children}
        </div>
    );
};

const TimelineItem = ({children, completed}) => {
    return <div className={`timeline-item ${completed ? 'timeline-item-completed' : ''}`}>
        {completed ? <CircleCheck/> : <Clock/>}
        {children}
    </div>;
};

Timeline.Item = TimelineItem;

const TimelineDescription = ({children}) => {
    return <div className={"timeline-item__description"}>{children}</div>;
};

Timeline.Description = TimelineDescription;

const TimelineDate = ({children}) => {
    return <div className={"timeline-item__date"}>{children}</div>;
};

Timeline.Date = TimelineDate;

const TimelineTitle = ({children}) => {
    return <div className={"bmm-subtitle"}>{children}</div>;
};

Timeline.Title = TimelineTitle;

const TimelineText = ({children}) => {
    return <div className={"bmm-text"}>{children}</div>;
};

Timeline.Text = TimelineText;

export default Timeline;
