import React from 'react';
import {connect} from "react-redux";

import {Form} from "react-bootstrap";
import {Button} from "@gravity-ui/uikit";
import {File} from "@gravity-ui/icons";

import {
    ProjectCommentField,
    ProjectCustomerField,
    ProjectDescriptionFullField,
    ProjectDescriptionShortField,
    ProjectIsForSchoolchildrenField,
    ProjectKeywordsField,
    ProjectManagerField,
    ProjectManagerRequirementsField,
    ProjectNameField,
    ProjectRequirementsField,
    ProjectResultsFileField,
    ProjectResultsURLField,
    ProjectStreamField,
    ProjectTestField,
    ProjectVenueField
} from "./ProjectFields";
import Profile from "../../components/Profile";

function ProjectReviewForm({
                               user,
                               onSubmit, submitting,
                               projectData, setProjectData,
                               oldFiles,
                               errors, setErrors,
                               onCanselClick
                           }) {
    const onChange = (e) => {
        const {name, value, type} = e.target;
        setProjectData((prevData) => ({
            ...prevData,
            [name]: type === 'radio' ? value === 'Да' : value,
        }));
    };

    return (
        <Form className="form-explain" onSubmit={onSubmit}>

            <ProjectCustomerField projectData={projectData} setProjectData={setProjectData}
                                  errors={errors} setErrors={setErrors} required={false}/>

            <ProjectNameField projectData={projectData} onChange={onChange} errors={errors}/>

            <ProjectVenueField disabled={user && !user.is_ws_director} projectData={projectData}
                               setProjectData={setProjectData} errors={errors}/>

            <ProjectStreamField disabled={user && !user.is_ws_director && !user.is_lws_director}
                                hasNotVenue={projectData.venue === '-1'}
                                projectData={projectData} setProjectData={setProjectData} errors={errors}/>

            <ProjectKeywordsField projectData={projectData} setProjectData={setProjectData} errors={errors}/>

            <ProjectDescriptionShortField projectData={projectData} setProjectData={setProjectData} onChange={onChange}
                                          errors={errors}/>

            {oldFiles.full_description_file &&
                <a href={oldFiles.full_description_file} target="_blank"><File/>Полное описание проекта</a>}
            <ProjectDescriptionFullField setProjectData={setProjectData}
                                         errors={errors}/>


            {oldFiles.results_application_file &&
                <a href={oldFiles.results_application_file} target="_blank"><File/>Заметка от куратора</a>}
            <br />
            {oldFiles.results_file &&
            <a href={oldFiles.results_file} target="_blank"><File/>Итоговая заметка</a>}
            <ProjectResultsFileField setProjectData={setProjectData}
                                         errors={errors}/>

            <ProjectResultsURLField projectData={projectData} onChange={onChange} errors={errors}/>

            <ProjectIsForSchoolchildrenField projectData={projectData} onChange={onChange}/>

            <ProjectRequirementsField projectData={projectData} setProjectData={setProjectData} errors={errors}/>

            {oldFiles.test_file &&
                <a href={oldFiles.test_file} target="_blank"><File/>Тестовое задание</a>}
            <ProjectTestField setProjectData={setProjectData}
                              errors={errors}/>

            {projectData.manager_requirements && <ProjectManagerRequirementsField projectData={projectData} onChange={onChange}/>}

            <ProjectManagerField projectData={projectData} setProjectData={setProjectData}
                                 errors={errors} setErrors={setErrors} required={false}/>

            <ProjectCommentField onChange={onChange} projectData={projectData}/>

            <div className="line-btn-group flex-column-reverse flex-sm-row">
                <Button view="normal" size="xl" onClick={onCanselClick} width="max">Отменить редактирование</Button>
                <Button view="action" type="submit" disabled={submitting} size="xl" width="max">
                    {submitting ? "Сохранение..." : "Редактировать проект"}
                </Button>
            </div>
        </Form>
    );
}

const mapStateToProps = state => ({
    user: state.auth.user,
});

export default connect(mapStateToProps)(ProjectReviewForm);