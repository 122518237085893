import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import axios from "axios";

import {File} from "@gravity-ui/icons";

import {
    getAcceptAuthConfig,
    PROJECT_DISTRIBUTION_MANAGER_ENDPOINT
} from "../../../api/api";
import LoadingSpinner from "../../../components/LoadingSpinner";
import {getGradeText} from "../../../utils/utils";

function ProjectManagerSelection() {
    const [isLoading, setIsLoading] = useState(true);

    const [projects, setProjects] = useState([]);

    useEffect(() => {
        axios
            .get(PROJECT_DISTRIBUTION_MANAGER_ENDPOINT, getAcceptAuthConfig())
            .then(response => {
                setProjects(response.data);
            })
            .catch(error => {
                console.error('Error getting manager selection:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    return (
        <>
            <h3 className="mb-2">Заявки на участие</h3>
            <hr className="clearfix w-100 pb-0"/>

            {isLoading &&
                <LoadingSpinner/> ||
                <>
                    {projects.length > 0 && projects.map((project, index) => (
                        <div key={index} className={"bmm-card project-card"}>
                            <div className="selection-title">
                                <h5 className={"project-card__title single-line-text"}>
                                    {project.name}
                                </h5>
                                {project.test_file &&
                                    <a href={project.test_file} target="_blank"><File/>Тестовое задание</a>}
                            </div>
                            {project.participants.length > 0 && project.participants.map((participant, index) => (
                                <Link to={`${participant.id}`}
                                      key={index}
                                      className={"bmm-card bmm-card_white d-flex align-items-center justify-content-between " +
                                          `bmm-text_bold text-reset text-decoration-none ${participant.participation_confirmation === 'confirmed' && "background-confirmed" || participant.participation_confirmation === 'declined' && "background-declined"}`}>
                                    <span>{participant.name}</span>
                                    {participant.grade && <span>{getGradeText(participant.grade)}</span>}
                                </Link>))}
                        </div>
                    ))}
                </>
            }

            {/*{projects.reverse().map((project) => (*/}
            {/*    <ProjectUserCard key={project.id} project={project} onDelete={onDelete}></ProjectUserCard>))}*/}
        </>
    );
}

export default ProjectManagerSelection;