import React, {useState} from "react";
import axios from "axios";
import {Link} from "react-router-dom";

import {Button, Icon, Label, UserLabel, Select, Modal} from "@gravity-ui/uikit";
import {Pencil} from "@gravity-ui/icons";

import {
    getJSONAcceptAuthConfig,
    GRAYLIST_ENDPOINT,
    PROJECT_DETAIL_ENDPOINT,
    REVIEW_PROJECTS_ENDPOINT
} from '../../api/api';
import {UNALLOCATED_PROJECTS} from "../../utils/const";
import {GraylistModal} from "../Modal/Modals";

export const STATUS_CHOICES = {
    'submitted': {value: 'submitted', label: 'Ждёт проверки', color: '#556575', theme: 'normal'},
    'under_review': {value: 'under_review', label: 'Проверяется', color: '#3072b3', theme: 'info'},
    'returned': {value: 'returned', label: 'На доработке', color: '#bd5c0a', theme: 'warning'},
    'rejected': {value: 'rejected', label: 'Отменён', color: '#bd0935', theme: 'danger'},
    'accepted': {value: 'accepted', label: 'Принят', color: '#2d8b5d', theme: 'success'}
};

export default function ProjectStatus({status}) {
    const statusData = STATUS_CHOICES[status];

    return (<Label theme={statusData.theme} className={"ms-1"}>{statusData.label}</Label>);
};

export const ProjectVenue = ({venue}) => {
    return (<>
        {venue?.name && (<Label className="me-1" theme={"normal"}>{venue?.name}</Label>) || (
            <Label className="me-1" theme={"normal"}>Площадка не выбрана</Label>)}
    </>);
};

export const ProjectStream = ({stream}) => {
    return (
        <>
            {stream
                && (<Label theme={"normal"}>{stream}</Label>)
                || (<Label theme={"normal"}>{UNALLOCATED_PROJECTS}</Label>)}
        </>);
};

export const ProjectForSchool = ({is_for_schoolchildren}) => {
    return (<>
        {is_for_schoolchildren && (<Label theme={"warning"}>Для школьников</Label>)}
    </>);
};

export const ProjectPersona = ({user, hasReflection = false}) => {
    return (<UserLabel type="person"
                       children={`${user?.last_name} ${user?.first_name}`}
                       avatar={user?.avatar}
                       size={"s"}
        // hasBorder={false}
                       view={"clear"}
                       onClick={() => {
                       }}
                       className={`pe-none ${hasReflection ? 'indicator' : ''}`}
    />);
};

export const ProjectPersonaWithModal = ({user, hasReflection = false, projectId}) => {
    const [open, setOpen] = React.useState(false);
    const [graylist, setGraylist] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const handleModalOpen = () => {
        axios
            .get(GRAYLIST_ENDPOINT(user.id), getJSONAcceptAuthConfig())
            .then(response => {
                setGraylist(response.data);
            })
            .catch(error => {
                console.error('Error getting projects for review:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });

        setOpen(true);
    }

    return (
        <>
            <UserLabel type="person"
                       children={`${user?.last_name} ${user?.first_name}`}
                       avatar={user?.avatar}
                       size={"s"}
                // hasBorder={false}
                       view={"clear"}
                       onClick={handleModalOpen}
                       className={`${hasReflection ? 'indicator' : ''}`}
            />
            <GraylistModal user={user} projectId={projectId}
                           open={open} setOpen={setOpen}
                           graylist={graylist} setGraylist={setGraylist}
                           isLoading={isLoading}/>
        </>);
};

export const ProjectDate = ({date}) => {
    return (`${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear().toString().slice(2)}`);
};

export const ProjectKeywords = ({keywords}) => {
    return (<>
        {keywords && keywords.map((keyword, index) => (<div key={index}>{keyword}</div>))}
    </>);
};

export const ProjectStatusSelect = ({project}) => {

    const fetchData = (status) => {
        const body = JSON.stringify({status});
        axios.patch(PROJECT_DETAIL_ENDPOINT(project.id), body, getJSONAcceptAuthConfig()).then(response => {
            project.status = status;
        })
    };


    const handleStatusChange = (values) => {
        if (values.length === 0 || project.status === values[0]) {
            return;
        }
        fetchData(values[0]);
    };

    return (
        <Select renderSelectedOption={(option) => (<div className={option.data.theme}>{option.children}</div>)}
                renderOption={(option) => (<div style={{color: option.data.color}}>{option.children}</div>)}
                defaultValue={[project.status]}
                onUpdate={(values) => {
                    handleStatusChange(values);
                }}>
            {Object.keys(STATUS_CHOICES).map((key) => (
                <Select.Option key={key} value={STATUS_CHOICES[key].value}
                               data={{theme: STATUS_CHOICES[key].theme, color: STATUS_CHOICES[key].color}}>
                    {STATUS_CHOICES[key].label}
                </Select.Option>
            ))}
        </Select>
    );
};


export const ProjectUpdateButton = ({projectId}) => {
    return (<Link to={projectId}>
        <Button view={"normal"} size={"m"}>
            <Icon data={Pencil} size={18}/>
        </Button>
    </Link>);
};
