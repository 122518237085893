import React, {useState} from 'react';
import {Navigate, useParams} from 'react-router-dom';
import {connect} from 'react-redux';
import {Container, Card, Button} from "@gravity-ui/uikit";
import {Form, InputGroup} from "react-bootstrap";
import {Eye, EyeSlash} from "@gravity-ui/icons";

import {reset_password_confirm} from '../../actions/auth';
import PasswordButton from "../../components/passwordButton/PasswordButton";

const ResetPasswordConfirm = ({reset_password_confirm}) => {
    const {uid, token} = useParams();
    const [submitting, setSubmitting] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [requestSent, setRequestSent] = useState(false);
    const [formData, setFormData] = useState({
        new_password: '',
        re_new_password: ''
    });
    const {new_password, re_new_password} = formData;

    const [errors, setErrors] = useState({
        new_password: '',
        re_new_password: '',
        non_field_errors: ''
    });
    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

    const onSubmit = e => {
        e.preventDefault();
        setSubmitting(true);

        reset_password_confirm(uid, token, new_password, re_new_password)
            .then(response => {
                setRequestSent(true);
            })
            .catch(e => {
                setErrors(JSON.parse(e.request?.responseText));
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    if (requestSent) {
        return <Navigate to='/'/>
    }

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Container className="mainContainer">
            <Card view="raised" className="info__card yc-s__p_5">
                <Form onSubmit={e => onSubmit(e)}>
                    <Form.Group controlId="formPasssword" className="pb-2">
                        <Form.Label>Новый пароль</Form.Label>
                        <Form.Control
                            type={showPassword ? 'text' : 'password'}
                            name='new_password'
                            value={new_password}
                            onChange={onChange}
                            isInvalid={!!(errors && errors.new_password || errors && errors.non_field_errors)}
                            required
                        />
                        <Form.Control.Feedback
                            type="invalid">{errors && errors.new_password}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formRePasssword" className="pb-2">
                        <Form.Label>Повторите пароль</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                type={showPassword ? 'text' : 'password'}
                                name='re_new_password'
                                value={re_new_password}
                                onChange={onChange}
                                isInvalid={!!(errors && errors.re_new_password || errors && errors.non_field_errors)}
                                required
                            />
                            <PasswordButton onClick={toggleShowPassword}>
                                {showPassword ? <EyeSlash/> : <Eye/>}
                            </PasswordButton>
                            <Form.Control.Feedback
                                type="invalid">{errors && errors.re_new_password || errors && errors.non_field_errors}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Button view="action" type="submit" disabled={submitting} className="mt-3 w-100" size="l">
                        {submitting ? 'Сброс...' : 'Сбросить пароль'}
                    </Button>
                </Form>
            </Card>
        </Container>
    );
};

export default connect(null, {
    reset_password_confirm
})(ResetPasswordConfirm);