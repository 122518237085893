import React, {useState} from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {connect} from "react-redux";

import {
    Magnifier,
    ArrowRightFromSquare,
    Flag,
    BarsUnaligned,
    Layers3Diagonal, Persons, Dice3, Dice2, Dice1, CopyCheckXmark, Ticket, Hand, BranchesRight, Files
} from '@gravity-ui/icons';

import NavList from "../../components/navList/NavList";
import Profile from "../../components/Profile";

import {logout} from "../../actions/auth";

const AccountMenuPage = ({menuItems, logout, user}) => {

    const [redirect, setRedirect] = useState(false);

    const logout_user = () => {
        logout();
        setRedirect(true);
    };

    if (redirect) {
        return <Navigate to='/'/>
    }

    console.log("user", user);

    return (
        <div className="row">
            {user && <Profile size={'m'} className={'mb-4'} profile={user}/>}
            <div className="col-md-4 col-xl-3">

                <NavList className="pr-md-4 nav-list_account">

                    {menuItems.startItems.map(({link, visual, label, disabled}) => (
                        <NavList.Item key={link} to={link} disabled={disabled}>
                            <NavList.LeadingVisual>{visual || <Magnifier/>}</NavList.LeadingVisual>
                            {label}
                        </NavList.Item>
                    ))}

                    <NavList.Divider/>{/*===================================================================*/}

                    <NavList.Heading>
                        Кабинет участника
                    </NavList.Heading>

                    <NavList.Item to={"participant"}>
                        <NavList.LeadingVisual>{<Dice1/>}</NavList.LeadingVisual>
                        Проекты
                    </NavList.Item>

                    <NavList.Item to={"application"}>
                        <NavList.LeadingVisual>{<Hand/>}</NavList.LeadingVisual>
                        Заявка на участие
                    </NavList.Item>

                    <NavList.Item to={"travel"}>
                        <NavList.LeadingVisual>{<Ticket/>}</NavList.LeadingVisual>
                        Тревел грант
                    </NavList.Item>

                    {menuItems.items.map(({link, visual, label, disabled}) => (
                        <NavList.Item key={link} to={link} disabled={disabled}>
                            <NavList.LeadingVisual>{visual || <Magnifier/>}</NavList.LeadingVisual>
                            {label}
                        </NavList.Item>
                    ))}

                    <NavList.Divider/>{/*===================================================================*/}

                    {user && user.is_project_manager &&
                        <>
                            <NavList.Heading>
                                Кабинет куратора
                            </NavList.Heading>

                            <NavList.Item to={"curator"}>
                                <NavList.LeadingVisual>{<Dice2/>}</NavList.LeadingVisual>
                                Проекты
                            </NavList.Item>

                            <NavList.Item to={"selection"}>
                                <NavList.LeadingVisual>{<CopyCheckXmark/>}</NavList.LeadingVisual>
                                Заявки на участие
                            </NavList.Item>

                            <NavList.Divider/>{/*===================================================================*/}
                        </>
                    }

                    <NavList.Heading>
                        Кабинет заказчика
                    </NavList.Heading>

                    <NavList.Item to={"project"}>
                        <NavList.LeadingVisual>{<Dice3/>}</NavList.LeadingVisual>
                        Проекты
                    </NavList.Item>

                    <NavList.Divider/>{/*===================================================================*/}

                    {/*{user && (user.is_ws_director || user.is_lws_director || user.is_ls_manager) &&*/}
                    {/*    (<NavList.Item to="reflection-review">*/}
                    {/*        <NavList.LeadingVisual><Files/></NavList.LeadingVisual>*/}
                    {/*        Рефлексии*/}
                    {/*    </NavList.Item>)*/}
                    {/*}*/}

                    {user && (user.is_ws_director || user.is_lws_director || user.is_ls_manager) &&
                        (<NavList.Item to="all-applications">
                            <NavList.LeadingVisual><CopyCheckXmark/></NavList.LeadingVisual>
                            Заявки на участие
                        </NavList.Item>)
                    }

                    {user && (user.is_ws_director || user.is_lws_director || user.is_ls_manager || user.permissions.some(permission => permission.action.codename === 'list_all_projects')) &&
                        (<NavList.Item to="project-management">
                            <NavList.LeadingVisual><Layers3Diagonal/></NavList.LeadingVisual>
                            Управление проектами
                        </NavList.Item>)
                    }


                    {user && (user.is_ws_director || user.is_lws_director) &&
                        (<NavList.Item to="stream-management">
                            <NavList.LeadingVisual><BarsUnaligned/></NavList.LeadingVisual>
                            Управление потоками
                        </NavList.Item>)
                    }

                    {user && user.is_ws_director &&
                        (<NavList.Item to="venue-management">
                            <NavList.LeadingVisual><Flag/></NavList.LeadingVisual>
                            Управление площадками
                        </NavList.Item>)
                    }

                    {user && user.is_ws_director &&
                        (<NavList.Item to="distribution">
                            <NavList.LeadingVisual><BranchesRight/></NavList.LeadingVisual>
                            Распределение
                        </NavList.Item>)
                    }

                    {user && user.is_ws_director &&
                        (<NavList.Item to="travel-grants">
                            <NavList.LeadingVisual><Ticket/></NavList.LeadingVisual>
                            Тревел гранты
                        </NavList.Item>)
                    }

                    {user && (user.is_ws_director || user.is_lws_director || user.is_ls_manager) &&
                        (<NavList.Item to="users">
                            <NavList.LeadingVisual><Persons/></NavList.LeadingVisual>
                            Пользователи
                        </NavList.Item>)
                    }

                    {user && (user.is_ws_director || user.is_lws_director || user.is_ls_manager) && <NavList.Divider/>}

                    {menuItems.endItems.map(({link, visual, label, disabled}) => (
                        <NavList.Item key={link} to={link} disabled={disabled}>
                            <NavList.LeadingVisual>{visual || <Magnifier/>}</NavList.LeadingVisual>
                            {label}
                        </NavList.Item>
                    ))}

                    {/*<NavList.Item to="settings">*/}
                    {/*    <NavList.LeadingVisual><Person/></NavList.LeadingVisual>*/}
                    {/*    Редактирование профиля*/}
                    {/*</NavList.Item>*/}
                    {/*{menuItems.map(({link, visual, label}) => (*/}
                    {/*    <NavList.Item key={link} to={link}>*/}
                    {/*        <NavList.LeadingVisual>{visual || <Magnifier/>}</NavList.LeadingVisual>*/}
                    {/*        {label}*/}
                    {/*    </NavList.Item>*/}
                    {/*))}*/}


                    {/*<NavList.Divider/>*/}

                    {/*<NavList.Item to="/" disabled>*/}
                    {/*    <NavList.LeadingVisual><Lock/></NavList.LeadingVisual>*/}
                    {/*    Смена пароля*/}
                    {/*</NavList.Item>*/}

                    <NavList.Item to="/" theme="danger" onClick={logout_user}>

                        <NavList.LeadingVisual><ArrowRightFromSquare/></NavList.LeadingVisual>
                        Выход
                    </NavList.Item>
                </NavList>
            </div>

            <div className="col-md-8 col-xl-9 mt-md-0 mt-4">
                <Outlet/>
            </div>
        </div>
    );
};
const mapStateToProps = state => ({
    user: state.auth.user,
});

export default connect(mapStateToProps, {logout})(AccountMenuPage);
