import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";

import {
    getAcceptAuthConfig,
    getJSONAcceptAuthConfig,
    USER_DETAIL_ENDPOINT
} from "../../api/api";
import UserForm from "./UserForm";

function EditUser() {
    const {id} = useParams();

    const navigate = useNavigate();

    const [submitting, setSubmitting] = useState(false);

    const [userData, setUserData] = useState({
        email: "",
        last_name: "",
        first_name: "",
        middle_name: "",
        title: "",
        avatar: ""
    });

    const [errors, setErrors] = useState({
        email: "",
        last_name: "",
        first_name: "",
        middle_name: "",
        title: "",
        avatar: ""
    });

    useEffect(() => {
        axios
            .get(USER_DETAIL_ENDPOINT(id), getJSONAcceptAuthConfig())
            .then(response => {
                setUserData(response.data);
            })
            .catch(error => {
                console.error('Error getting project data:', error);
            });
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();

        let userData_copy = {...userData};

        if (!(userData_copy.avatar instanceof File)) {
            delete userData_copy.avatar;
        }

        if (!userData_copy.avatar) {
            delete userData_copy.avatar;
        }

        if (!userData_copy.birth_date) {
            delete userData_copy.birth_date;
        }

        const formDataToSend = new FormData();
        for (const key in userData_copy) {
            formDataToSend.append(key, userData_copy[key]);
        }

        axios
            .patch(USER_DETAIL_ENDPOINT(id), formDataToSend, getAcceptAuthConfig())
            .then(response => {
                navigate('../', {relative: "path"});
            })
            .catch(error => {
                console.error('Не удалось создать пользователя:', error);
                setErrors(JSON.parse(error.response.request.response));
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <>
            <h3 className="mb-2">Создание пользователя</h3>
            <hr className="clearfix w-100 pb-0"/>

            <UserForm onSubmit={onSubmit} submitting={submitting}
                      userData={userData} setUserData={setUserData}
                      errors={errors}/>
        </>
    );
}

export default EditUser;