import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";

import TravelForm from "./TravelForm";
import {
    getJSONAcceptAuthConfig,
    USER_APPLICATION_LOCAL_WORKSHOPS_ENDPOINT, USER_TRAVEL_GRANTS_DETAIL_ENDPOINT
} from "../../../api/api";
import {forEach} from "react-bootstrap/ElementChildren";
import LoadingSpinner from "../../../components/LoadingSpinner";

function TravelFormUpdate() {
    const {id} = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);

    const [errors, setErrors] = useState(null);
    const [accommodationNeeded, setAccommodationNeeded] = useState({});

    const [travelData, setTravelData] = useState({
            passport: {
                citizenship: '',
                gender: '',
                number: '',
                issuer: '',
                issue_date: '',
                issuer_code: ''
            },
            accompanying_name: '',
            motivation: '',
            comment: '',
            livings: {},
            accompanying_phone: ''
        });

    const [localWorkshops, setLocalWorkshops] = useState([]);

    useEffect(() => {
        Promise.all([
            axios.get(USER_APPLICATION_LOCAL_WORKSHOPS_ENDPOINT, getJSONAcceptAuthConfig()),
            axios.get(USER_TRAVEL_GRANTS_DETAIL_ENDPOINT(id), getJSONAcceptAuthConfig())
        ])
        .then(([response1, response2]) => {
            setLocalWorkshops(response1.data);

            let newLivings = {};
            let newAccommodationNeeded = {};
            Object.entries(response2.data.livings).forEach(([key, value]) => {
                newLivings[value.local_workshop.id] = value;
                newAccommodationNeeded = {...newAccommodationNeeded, [value.local_workshop.id]: true};
            });
            setAccommodationNeeded(newAccommodationNeeded);
            setTravelData({...response2.data, livings: newLivings});
        })
        .catch(error => {
            console.error('Error:', error);
        })
        .finally(() => {
            setIsLoading(false);
        });
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        setSubmitting(true);

    // Преобразование livings в массив объектов
    const livingsArray = Object.entries(travelData.livings).map(([id, value]) => ({...value, local_workshop: Number(id)}));

    // Замена livings в travelData
    const newTravelData = {...travelData, livings: livingsArray};

    // Удаление всех символов, кроме цифр, из номера паспорта и кода подразделения
    newTravelData.passport.number = newTravelData.passport.number.replace(/[^0-9]/g, '');
    newTravelData.passport.issuer_code = newTravelData.passport.issuer_code.replace(/[^0-9]/g, '');

    const travelDataJson = JSON.stringify(newTravelData);
        axios
            .put(USER_TRAVEL_GRANTS_DETAIL_ENDPOINT(id), travelDataJson, getJSONAcceptAuthConfig())
            .then(response => {
                navigate('../', {relative: "path"});
            })
            .catch(error => {
                // Handle error
                setErrors(error.response.data);
            })
            .finally(() => {
                setSubmitting(false);
            });
    }

    if (isLoading) {
        return (
            <>
            <h3 className="mb-2">Редактирование заявки на тревел грант</h3>
                <hr className="clearfix w-100 pb-0"/>
                <LoadingSpinner />
            </>
        );
    }

    return (
        <>
        <h3 className="mb-2">Редактирование заявки на тревел грант</h3>
            <hr className="clearfix w-100 pb-0"/>
            <TravelForm
                travelData={travelData}
                setTravelData={setTravelData}
                accommodationNeeded={accommodationNeeded}
                setAccommodationNeeded={setAccommodationNeeded}
                errors={errors}
                setErrors={setErrors}
                onSubmit={onSubmit}
                submitting={submitting}
                localWorkshops={localWorkshops}
                setLocalWorkshops={setLocalWorkshops}
                type="update"
            />
        </>
    );
}

export default TravelFormUpdate;