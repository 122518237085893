import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Container, Card, Button} from "@gravity-ui/uikit";
import {Form} from "react-bootstrap";

import {reset_password} from '../../actions/auth';

const ResetPassword = ({reset_password}) => {
    const [submitting, setSubmitting] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [requestSent, setRequestSent] = useState(false);
    const [formData, setFormData] = useState({
        email: ''
    });
    const {email} = formData;

    const [errors, setErrors] = useState({
        email: '',
    });

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

    const onSubmit = e => {
        e.preventDefault();
        setSubmitting(true);
        reset_password(email)
            .then(response => {
                setIsSent(true);
            })
            .catch(e => {
                setErrors(JSON.parse(e.request?.responseText));
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <Container className="mainContainer">
            <Card view="raised" className="info__card yc-s__p_5">
                <h1 className="info__title">Сброс пароля</h1>
                {isSent ? (
                        <>
                            <div className="info__text">На указанный вами адрес <strong>{email}</strong> было
                                отправлено письмо с инструкциями по сбросу пароля.<br/><br/>
                                Пожалуйста, следуйте указанным в письме инструкциям для завершения сброса пароля.
                            </div>
                        </>) :
                    (<>
                        <Form onSubmit={onSubmit}>
                            <Form.Group controlId="formEmail" className="pb-2">
                                <Form.Label>Электронная почта</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={email}
                                    onChange={onChange}
                                    isInvalid={!!(errors && errors.email)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">{errors && errors.email}</Form.Control.Feedback>
                            </Form.Group>
                            <Button view="action" type="submit" disabled={submitting} className="mt-3 w-100" size="l">
                                {submitting ? 'Сброс...' : 'Сбросить пароль'}
                            </Button>
                        </Form>
                    </>)}
            </Card>
        </Container>
    );
};

export default connect(null, {reset_password})(ResetPassword);