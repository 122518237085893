import React, {useEffect, useState} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import axios from "axios";

import LoadingSpinner from "../components/LoadingSpinner";
import {getJSONAcceptAuthConfig, USER_APPLICATION_ENDPOINT} from "../api/api";

function CanCreateApplication({children}) {
    const location = useLocation();
    const [applicationData, setApplicationData] = useState(null);

    useEffect(() => {
        axios
            .get(USER_APPLICATION_ENDPOINT, getJSONAcceptAuthConfig())
            .then(response => {
                setApplicationData(response.data.cv);
            })
            .catch(error => {
                console.error('Error getting application data:', error);
            });
    }, []);

    if (applicationData === null) {
        return <LoadingSpinner/>
    }

    const currentDate = new Date();
    const maxDate = new Date('2024-06-07T23:59:59.999+07:00');
    // TODO заменить на дату дедлайна подачи проектов + добавить дату начала подачи проектов

    return (applicationData?.participant || currentDate > maxDate)
        ? <Navigate to='/account/participant' state={{from: location}} replace={true}/>
        : children;
}

export default CanCreateApplication;