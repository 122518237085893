import React, {useState, useEffect, useRef} from 'react';
import axios from "axios";
import {connect} from "react-redux";

import {Icon, Checkbox, Button} from "@gravity-ui/uikit";
import {Pencil, ThumbsDownFill, ThumbsUpFill, TrashBin} from "@gravity-ui/icons";

import './Chat.css';
import UserCard from "../userCard/UserCard";
import {getJSONAcceptAuthConfig, GRAYLIST_DETAIL_ENDPOINT, GRAYLIST_ENDPOINT} from "../../api/api";
import LoadingSpinner from "../LoadingSpinner";
import {DeleteModal} from "../Modal/Modals";


const Chat = ({user, currentUser, graylist, setGraylist, projectId, isLoading}) => {
    const [newMessage, setNewMessage] = useState('');
    const [is_fit, setis_fit] = useState(null);

    const [editMode, setEditMode] = useState(false);
    const [editMessageId, setEditMessageId] = useState(null);
    const [isSending, setIsSending] = useState(false);

    const messagesEndRef = useRef(null);

    const handleSendMessage = () => {
        if (newMessage.trim()) {
            setIsSending(true);
            const body = JSON.stringify({comment: newMessage, is_fit: is_fit, project: projectId});

            if (editMode) {
                // Update the existing message
                axios
                    .put(GRAYLIST_DETAIL_ENDPOINT(user.id, editMessageId), body, getJSONAcceptAuthConfig())
                    .then(response => {
                        setGraylist(prevMessages => prevMessages.map(message =>
                            message.id === editMessageId ? response.data : message
                        ));
                        setEditMode(false);
                        setEditMessageId(null);
                    })
                    .catch(error => console.error('Error updating message:', error))
                    .finally(() => {
                        setIsSending(false);
                    });
            } else {
                // Create a new message
                axios
                    .post(GRAYLIST_ENDPOINT(user.id), body, getJSONAcceptAuthConfig())
                    .then(response => {
                        setGraylist(prevMessages => [
                            ...prevMessages,
                            response.data
                        ]);
                    })
                    .catch(error => {
                        console.error('Error adding message:', error);
                    })
                    .finally(() => {
                        setIsSending(false);
                    });
            }

            setNewMessage('');
            setis_fit(null);
        }
    };

    const handleEditMessage = (message) => {
        setNewMessage(message.comment);
        setis_fit(message.is_fit);
        setEditMode(true);
        setEditMessageId(message.id);
    };

    const handleDeleteMessage = (id) => {
        // if (window.confirm('Вы уверены, что хотите удалить это сообщение?')) {
        axios
            .delete(GRAYLIST_DETAIL_ENDPOINT(user.id, id), getJSONAcceptAuthConfig())
            .then(() => {
                setGraylist(prevMessages => prevMessages.filter(message => message.id !== id));
            })
            .catch(error => console.error('Error deleting message:', error));
        // }
    };
    const hasAddCommentPermission = currentUser.permissions.some(permission => permission.action.codename === 'add_comment');
    const hasViewAllCommentsPermission = currentUser.permissions.some(permission => permission.action.codename === 'view_all_comments');

    // useEffect(() => {
    //     if (messagesEndRef.current) {
    //         messagesEndRef.current.scrollIntoView({behavior: 'smooth'});
    //     }
    // }, [graylist]);

    // useEffect(() => {
    //     document.querySelector('.message-list').scrollTop = document.querySelector('.message-list').scrollHeight
    // }, [graylist])

    return (
        <div className="chat-container">
            <MessageList graylist={graylist} messagesEndRef={messagesEndRef} currentUser={currentUser}
                         onEdit={handleEditMessage} onDelete={handleDeleteMessage} isLoading={isLoading}/>
            {hasAddCommentPermission || !hasViewAllCommentsPermission ? (
            <div className="message-input-container">
                <textarea
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Введите сообщение..."
                />
                <div className="checkbox-container">
                    <Checkbox size="m"
                              checked={is_fit === true}
                              onChange={() => setis_fit(is_fit === true ? null : true)}>
                        Обязательно нужно привлекать к работе БММ в будущем
                    </Checkbox>
                    <Checkbox size="m"
                              checked={is_fit === false}
                              onChange={() => setis_fit(is_fit === false ? null : false)}>
                        Ни в коем случае не нужно пускать на мастерскую
                    </Checkbox>
                </div>
                <Button view={"action"} size={"l"} disabled={isSending}
                        onClick={handleSendMessage}>{editMode ? 'Редактировать' : 'Отправить'}</Button>
            </div>
                ) : null}
        </div>
    );
};

const MessageList = ({graylist, messagesEndRef, currentUser, onEdit, onDelete, isLoading}) => {
    const reversedGraylist = [...graylist].reverse();

    return (
        <div className="message-list">
            <div ref={messagesEndRef}/>
            {isLoading && <div className={"no-message"}><LoadingSpinner/></div> ||
                reversedGraylist.length !== 0 &&
                reversedGraylist.map(message => (
                    <Massage key={message.id} message={message} currentUser={currentUser} onDelete={onDelete} onEdit={onEdit}/>
                )) ||
                <div className={"no-message"}>Сообщений пока нет.</div>
            }
        </div>
    );
};

const Massage = ({message, currentUser, onDelete, onEdit}) => {
    const [open, setOpen] = React.useState(false);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear()).slice(2);
        return `${day}.${month}.${year}`;
    };

    return (
        <div key={message.id} className="message">
            <UserCard size={"xs"} imgUrl={message.author.photo}
                      name={`${message.author.last_name} ${message.author.first_name}`}
                      initialsOnly={true}/>
            <div className="message-info">
                <div className="message__header">
                    <div className="message-author-name">
                        {`${message.author.last_name} ${message.author.first_name}`}
                        {message.is_fit !== null && (
                            <span className={message.is_fit ? 'ok' : 'not-ok'}>
                                    {message.is_fit ?
                                        <Icon data={ThumbsUpFill} size={16}/> :
                                        <Icon data={ThumbsDownFill} size={16}/>}
                                </span>
                        )}
                    </div>
                    {message.author.id === currentUser.id && (
                        <div className="message-actions">
                            <Button view="flat-secondary" size="s" onClick={() => setOpen(true)}>
                                <Icon data={TrashBin} size={16}/>
                            </Button>
                            <Button view="flat-secondary" size="s" onClick={() => onEdit(message)}>
                                <Icon data={Pencil} size={16}/>
                            </Button>
                        </div>
                    )}
                </div>
                <span>{message.comment}</span>
                <div className="message-date">
                    {formatDate(message.created_on)}
                </div>
            </div>
            <DeleteModal type="сообщение"
                         onDelete={() =>  {onDelete(message.id); setOpen(false)}}
                         open={open} setOpen={setOpen}/>
        </div>
    );
};

const mapStateToProps = state => ({
    currentUser: state.auth.user,
});

export default connect(mapStateToProps)(Chat);
