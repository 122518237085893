import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import axios from "axios";

import {File} from "@gravity-ui/icons";

import {getAcceptAuthConfig, PROJECT_DISTRIBUTION_MANAGER_DETAIL_ENDPOINT} from "../../../api/api";
import LoadingSpinner from "../../../components/LoadingSpinner";
import {LinkLikeButton_File} from "../../../components/Button/Button";

function DirectorProjectApplicationView() {
    const {id} = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [selectionData, setSelectionData] = useState();

    useEffect(() => {
        axios
            .get(PROJECT_DISTRIBUTION_MANAGER_DETAIL_ENDPOINT(id), getAcceptAuthConfig())
            .then(response => {
                setSelectionData(response.data);
            })
            .catch(error => {
                console.error('Error getting selection Data:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    return (
        <>
            <h3 className="mb-2">Заявка</h3>
            <hr className="clearfix w-100 pb-0"/>
            {isLoading &&
                <LoadingSpinner/> ||
                <>
                    <div className={"bmm-text_bold selection-cv"}>
                        <p>{selectionData.projects_user.user.name}</p>

                        <strong>Образование</strong><br/>
                        <p className={"selection-text"}>{selectionData.cv.education || "–"}</p>

                        <strong>Опыт работы</strong>
                        <p className={"selection-text"}>{selectionData.cv.experience || "–"}</p>

                        <strong>Достижения</strong>
                        <p className={"selection-text"}>{selectionData.cv.achievements || "–"} <br/>

                            {selectionData.cv.achievements_confirmation_file &&
                                <LinkLikeButton_File href={selectionData.cv.achievements_confirmation_file}
                                                     icon={<File/>}
                                                     text={'Документы, подтверждающие достижения'}
                                                     fileName={selectionData.projects_user.user.name + ' Достижения'}
                                />
                            }
                        </p>

                        <strong>Навыки</strong>
                        <p className={"selection-text"}>{selectionData.cv.skills || "–"}</p>

                        <strong>Интересы в научной сфере</strong>
                        <p className={"selection-text"}>{selectionData.cv.scientific_interests || "–"}</p>

                        <strong>Ожидания от мастерской</strong>
                        <p className={"selection-text"}>{selectionData.cv.expectations || "–"}</p>

                        <strong>Прочие комментарии</strong>
                        <p className={"selection-text"}>{selectionData.cv.other_comments || "–"}</p>

                        <p>
                            {selectionData.projects_user.file &&
                                <LinkLikeButton_File href={selectionData.projects_user.file}
                                                     icon={<File/>}
                                                     text={'Решение тестового задания'}
                                                     fileName={selectionData.projects_user.user.name + ' Тестовое'}
                                />
                            }
                        </p>

                    </div>
                </>
            }
        </>
    );
}

export default DirectorProjectApplicationView;