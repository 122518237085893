import React from 'react';
import {Button} from "react-bootstrap";

import './PasswordButton.css'

const PasswordButton = ({children, onClick}) => {
    return (
        <Button className="btn-show-password" id="button-addon2" onClick={onClick}>
            {children}
        </Button>
    );
};

export default PasswordButton;