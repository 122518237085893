import React, {useState} from 'react';
import axios from "axios";
import {ALL_USERS_ENDPOINT, getAcceptAuthConfig} from "../../api/api";
import UserForm from "./UserForm";
import {useNavigate} from "react-router-dom";

function CreateUser() {
    const navigate = useNavigate();

    const [submitting, setSubmitting] = useState(false);

    const [userData, setUserData] = useState({
        email: "",
        last_name: "",
        first_name: "",
        middle_name: "",
        title: "",
        avatar: ""
    });

    const [errors, setErrors] = useState({
        email: "",
        last_name: "",
        first_name: "",
        middle_name: "",
        title: "",
        avatar: ""
    });

    const onSubmit = (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        for (const key in userData) {
            formDataToSend.append(key, userData[key]);
        }

        axios
            .post(ALL_USERS_ENDPOINT, formDataToSend, getAcceptAuthConfig())
            .then(response => {
                navigate('../', {relative: "path"});
            })
            .catch(error => {
                console.error('Не удалось создать пользователя:', error);
                setErrors(JSON.parse(error.response.request.response));
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <>
            <h3 className="mb-2">Создание пользователя</h3>
            <hr className="clearfix w-100 pb-0"/>

            <UserForm onSubmit={onSubmit} submitting={submitting}
                      userData={userData} setUserData={setUserData}
                      errors={errors}/>
        </>
    );
}

export default CreateUser;