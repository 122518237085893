import React, {useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import axios from "axios";

import {Button} from "@gravity-ui/uikit";

import {getJSONConfig, PUBLIC_PROJECTS_ENDPOINT} from "../../api/api";
import ProjectCard from "../../components/projectCard/ProjectCard";
import {Accordion} from "react-bootstrap";
import LoadingSpinner from "../../components/LoadingSpinner";

const ProjectsPage = () => {
    const [projects, setProjects] = useState([]);

    const [uniqueVenues, setUniqueVenues] = useState([]);
    const [uniqueStreams, setUniqueStreams] = useState([]);
    const [uniqueKeywords, setUniqueKeywords] = useState([]);

    const [selectedVenues, setSelectedVenues] = useState([]);
    const [selectedStreams, setSelectedStreams] = useState([]);
    const [selectedKeywords, setSelectedKeywords] = useState([]);

    const [addedProjects, setAddedProjects] = useState(() => (JSON.parse(localStorage.getItem('addedProjects')) || []));

    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === 'addedProjects') {
                const updatedAddedProjects = JSON.parse(event.newValue) || [];
                setAddedProjects(updatedAddedProjects);
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    // useEffect(() => {
    //     const addedProjects = JSON.parse(localStorage.getItem('addedProjects')) || [];
    //     setAddedProjects(addedProjects);
    // }, []);

    const handleAddToApplication = (project) => {
        let updatedAddedProjects;
        const projectId = project.id;
        if (addedProjects.find(p => p.id === projectId)) {
            updatedAddedProjects = addedProjects.filter(p => p.id !== projectId);
        } else {
            updatedAddedProjects = [...addedProjects, project];
        }
        localStorage.setItem('addedProjects', JSON.stringify(updatedAddedProjects));
        setAddedProjects(updatedAddedProjects);
    };

    useEffect(() => {
        axios
            .get(PUBLIC_PROJECTS_ENDPOINT, getJSONConfig())
            .then(response => {
                setProjects(response.data);

                // Получаем уникальные названия площадок
                const venues = Array.from(new Set(response.data.map(project => project.venue?.name))).filter(Boolean).sort();
                setUniqueVenues(venues);

                // Получаем уникальные названия потоков
                const streams = Array.from(new Set(response.data.map(project => project.local_stream))).filter(Boolean).sort();
                setUniqueStreams(streams);

                // Получаем уникальные ключевые слова
                let keywords = [];
                response.data.forEach(project => {
                    keywords = keywords.concat(project.keywords);
                });
                keywords = Array.from(new Set(keywords)).filter(Boolean).sort();
                setUniqueKeywords(keywords);
            })
            .catch(error => {
                console.error('Error getting projects:', error);
            });
    }, []);

    const filterProjects = () => {
        return projects.filter(project => {
            // Фильтрация по площадке
            if (selectedVenues.length > 0 && !selectedVenues.includes(project.venue?.name)) {
                return false;
            }

            // Фильтрация по потоку
            if (selectedStreams.length > 0) {
                if (!project.local_stream || !selectedStreams.includes(project.local_stream)) {
                    return false;
                }
            }

            // Фильтрация по ключевым словам
            if (selectedKeywords.length > 0 && !selectedKeywords.some(keyword => project.keywords.includes(keyword))) {
                return false;
            }

            return true;
        });
    };

    const handleChange = (name, value, checked, setData) => {
        setData(prevData => {
            if (checked) {
                return [...prevData, value];
            } else {
                return prevData.filter(data => data !== value);
            }
        });
    };

    const handleCheckboxChange = event => {
        const {name, value, checked} = event.target;
        switch (name) {
            case 'selectedVenues':
                handleChange(name, value, checked, setSelectedVenues);
                break;
            case 'selectedStreams':
                handleChange(name, value, checked, setSelectedStreams);
                break;
            case 'selectedKeywords':
                handleChange(name, value, checked, setSelectedKeywords);
                break;
            default:
                break;
        }
    };

    const handleCheckboxAllChange = event => {
        const {name, checked} = event.target;
        switch (name) {
            case 'selectedVenues':
                setSelectedVenues(checked ? uniqueVenues : []);
                break;
            case 'selectedStreams':
                setSelectedStreams(checked ? uniqueStreams : []);
                break;
            case 'selectedKeywords':
                setSelectedKeywords(checked ? uniqueKeywords : []);
                break;
            default:
                break;
        }
    };

    const resetFilters = () => {
        setSelectedVenues([]);
        setSelectedStreams([]);
        setSelectedKeywords([]);
    };

    const createFilterItem = (title, data, name, selectedData, scrollable = false) => (
        <Accordion.Item key={title} eventKey={title}>
            <Accordion.Header className="public-project-filter__name">{title}</Accordion.Header>
            <Accordion.Body className={scrollable ? 'accordion-body_scroll' : ''}>
                <div>
                    <div className="form-check">
                        <input
                            className={`form-check-input ${0 < selectedData.length && selectedData.length < data.length ? `${name}-indeterminate` : ''}`}
                            type="checkbox"
                            name={name}
                            checked={selectedData.length === data.length}
                            value={"Все"}
                            id={`${name}CheckboxAll`}
                            onChange={handleCheckboxAllChange}/>
                        <label className="form-check-label" htmlFor={`${name}CheckboxAll`}>
                            Все
                        </label>
                    </div>
                </div>
                <div className={scrollable ? 'accordion-div_scroll' : ''}>
                    {data.map((item, index) => (
                        <div key={index} className="form-check">
                            <input className="form-check-input" type="checkbox"
                                   name={name}
                                   checked={selectedData.includes(item)}
                                   value={item}
                                   id={`${name}Checkbox${index}`}
                                   onChange={handleCheckboxChange}/>
                            <label className="form-check-label" htmlFor={`${name}Checkbox${index}`}>
                                {item}
                            </label>
                        </div>
                    ))}
                </div>
            </Accordion.Body>
        </Accordion.Item>
    );

    const AccordionFilter = (className = "") => (
        <Accordion defaultActiveKey={['Площадка', 'Поток', 'Ключевые слова']}
                   className={`public-project-filter bmm-text bmm-text_align-start first-column ${className}`}
                   alwaysOpen>
            {createFilterItem("Площадка", uniqueVenues, "selectedVenues", selectedVenues)}
            {createFilterItem("Поток", uniqueStreams, "selectedStreams", selectedStreams)}
            {createFilterItem("Ключевые слова", uniqueKeywords, "selectedKeywords", selectedKeywords, true)}
            <div className={"accordion-item public-project-filter__reset-button"}>
                <Button view={"normal"} size={"xl"} onClick={resetFilters}>
                    Сбросить
                </Button>
            </div>
        </Accordion>
    );

    const currentDate = new Date();
    const maxDate = new Date('2024-04-03T23:59:59.999+07:00');
    // TODO заменить на дату дедлайна подачи проектов + добавить дату начала подачи проектов

    const filteredProjects = filterProjects();

    return (
        <>
            <div className="page-title">Проекты Мастерской</div>

            {currentDate <= maxDate ? (
                <div className="bmm-text">
                    Вы можете подать собственный проект для работы на Большой математической мастерской до
                    01.04.2024.<br/><br/>

                    <div className={"text-center"}>
                        <Link to={"account/project/create"}>
                            <Button view={"action"} size={"xl"}>
                                Подать свой проект
                            </Button>
                        </Link>
                    </div>
                    <br/><br/>

                    Примеры проектов прошлых лет можно посмотреть по <Link to="archive">ссылке</Link>.
                </div>
            ) : (
                projects.length > 0 ? (
                    <>
                        <div className="flex-container">
                            {AccordionFilter("public-project-filter_desktop")}
                            <Accordion
                                className={"public-project-filter public-project-filter_mobile bmm-text first-column"}
                                alwaysOpen>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header
                                        className={"public-project-filter__name"}>Фильтры</Accordion.Header>
                                    <Accordion.Body bsPrefix={"public-project-filter_mobile-accordion-body"}>
                                        {AccordionFilter()}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <div className={"second-column"}>
                                {filteredProjects.length === 0 ? (
                                    <p className={"bmm-text mt-4"}>
                                        Проектов, соответствующих вашим критериям поиска, не
                                        найдено. <br/> Попробуйте изменить или сбросить фильтры.
                                    </p>
                                ) : (
                                    filteredProjects.map((project, index) => {
                                        const isAdded = addedProjects.some(addedProject => addedProject.id === project.id);
                                        return (
                                            <ProjectCard key={index} project={project} isAdded={isAdded}
                                                         handleAddToApplication={handleAddToApplication}/>
                                        );
                                    })
                                )}
                            </div>
                        </div>
                    </>
                ) : (
                    // <div className="bmm-text mt-5">Полный список проектов БММ-2024 будет опубликован на этой странице к
                    //     01.05.2024.</div>
                    <LoadingSpinner/>
                )
            )}
        </>
    );
}

export default ProjectsPage