import React, {useState, useEffect, useRef} from 'react';
import axios from "axios";

import {TextInput, Icon} from '@gravity-ui/uikit';
import {Magnifier} from "@gravity-ui/icons";

import {ALL_USERS_ENDPOINT, getAcceptAuthConfig} from "../../api/api";
import UsersTable from "../../components/UsersTable/UsersTable";
import {AddButtonMaxWidth_Link} from "../../components/Button/Button";
import LoadingSpinner from "../../components/LoadingSpinner";

const UsersTableMemo = React.memo(UsersTable);

const filterUsers = (searchText, allData) => {
    if (!searchText) {
        return allData;
    }
    return allData.filter(({last_name, first_name, email}) =>
        last_name.toLowerCase().includes(searchText.toLowerCase()) ||
        first_name.toLowerCase().includes(searchText.toLowerCase()) ||
        email.toLowerCase().includes(searchText.toLowerCase())
    );

}

function AllUsersList() {
    const [isLoading, setIsLoading] = useState(true);

    const [allUsers, setAllUsers] = useState([]);
    const [enteredValue, setEnteredValue] = useState("");
    const [filteredUsers, setFilteredUsers] = useState(allUsers);

    useEffect(() => {
        axios
            .get(ALL_USERS_ENDPOINT, getAcceptAuthConfig())
            .then(response => {
                setAllUsers(response.data);
                setFilteredUsers(response.data);
            })
            .catch(error => {
                console.error('Не удалось создать пользователя:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);


    useEffect(() => {
        const Debounce = setTimeout(() => {
            setFilteredUsers(filterUsers(enteredValue, allUsers));
        }, 300);

        return () => clearTimeout(Debounce);
    }, [enteredValue, allUsers]);

    return (
        <>
            <h3 className="mb-2">Пользователи</h3>
            <hr className="clearfix w-100 pb-0"/>

            <AddButtonMaxWidth_Link text={"Создать пользователя"} to={"create-user"}/>

            <TextInput className={"search-input mt-3 mb-2"}
                       size="l"
                       placeholder="Поиск..."
                       leftContent={<Icon data={Magnifier}/>}
                       hasClear={true}
                       value={enteredValue}
                       onChange={(e) => setEnteredValue(e.target.value)}
            />

            <div className="users__quantity-info quantity-info">
                Кол-во пользователей: {filteredUsers.length}
            </div>

            {isLoading &&
                <LoadingSpinner/> ||
                <UsersTableMemo users={filteredUsers}/>}
        </>
    );
}

export default AllUsersList;