import React from 'react';
import {Spin} from "@gravity-ui/uikit";

const LoadingSpinner = () => {
    return (
        <div style={{textAlign: 'center', padding: '20px'}}>
            <Spin/>
        </div>
);
};

export default LoadingSpinner;
