import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";

import {
    User, Avatar, Label, Icon,
    Table, withTableActions, withTableSettings, withTableSorting, Popover
} from "@gravity-ui/uikit"
import {Envelope, Hand, LogoTelegram, Pencil, Lock} from "@gravity-ui/icons";

import {DEFAULT_IMAGE_URL} from "../../utils/const";
import {EditButtonIcon_href, EditButtonIcon_Link} from "../Button/Button";
import UserModal from "../Modal/Modals";

function UsersTable({users}) {
    const MyTable = withTableActions(withTableSorting(Table));
    const data = users;

    const navigate = useNavigate();

    const getNextBirthdayDate = (birthDateString) => {
        const birthDate = new Date(birthDateString);
        const today = new Date();
        today.setDate(today.getDate() - 1);
        const currentYear = today.getFullYear();
        const birthMonth = birthDate.getMonth();
        const birthDay = birthDate.getDate();

        // Проверяем, прошел ли день рождения в этом году или нет
        const nextBirthday = new Date(currentYear, birthMonth, birthDay);
        if (nextBirthday < today) {
            nextBirthday.setFullYear(currentYear + 1);
        }

        return nextBirthday;
    };

    const compareDates = (a, b) => {
        const nextBirthdayA = getNextBirthdayDate(a.birth_date);
        const nextBirthdayB = getNextBirthdayDate(b.birth_date);
        return nextBirthdayA - nextBirthdayB;
    };

    const columns = [
        {
            id: 'user',
            name: 'Пользователь',
            template: (item) => {
                return (
                    <User name={`${item.last_name} ${item.first_name} ${item.middle_name}`} description={item.email}
                          avatar={item.avatar ? <Avatar imgUrl={item.avatar}/> : {
                              text: `${item.last_name} ${item.first_name}`
                          }}/>)
            },
            meta: {
                defaultSortOrder: 'asc',
                sort: (a, b) => {
                    const nameA = (a.last_name + ' ' + a.first_name).toUpperCase(); // Фамилия и имя первого объекта
                    const nameB = (b.last_name + ' ' + b.first_name).toUpperCase(); // Фамилия и имя второго объекта
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0; // Если фамилии и имена равны
                },
                selectedAlways: true
            },
        },
        {
            id: 'title',
            name: 'Должность',
            width: 300,
            template: (item) => {
                return (
                    item.title
                        ? <Popover contentClassName="bmm-popover-m"
                                   placement={"top"}
                                   htmlContent={`${item.title}`}
                        >
                            <div className="single-line-text line-clamp-2">{item.title}</div>
                        </Popover>
                        : null
                );
            },
            meta: {sort: false},
        },
        {
            id: 'phone_number',
            name: 'Телефон',
            meta: {sort: false},
        },
        {
            id: 'birth_date',
            name: 'День рождения',
            template: (item) => {
                if (item.birth_date) {
                    const birthDate = new Date(item.birth_date);
                    const today = new Date();
                    const isBirthdayToday = birthDate.getMonth() === today.getMonth() && birthDate.getDate() === today.getDate();
                    const formattedBirthDate = `${birthDate.getDate().toString().padStart(2, '0')}.${(birthDate.getMonth() + 1).toString().padStart(2, '0')}.${birthDate.getFullYear()}`;

                    if (isBirthdayToday) {
                        return (<Label theme="warning">{formattedBirthDate}</Label>);
                    } else {
                        return formattedBirthDate;
                    }
                } else {
                    return null;
                }
            },
            meta: {sort: compareDates},
        },
        {
            id: 'email',
            name: '',
            template: (item) => {
                return (
                    item.email
                        ? <EditButtonIcon_href href={`mailto:${item.email}`} icon={Envelope}/>
                        : null
                );
            },
            align: "center",
            meta: {sort: false},
        },
        {
            id: 'telegram',
            name: '',
            template: (item) => {
                return (
                    item.telegram_username
                        ? <EditButtonIcon_href href={`https://t.me/${item.telegram_username}/`} icon={LogoTelegram}/>
                        : null
                );
            },
            align: "center",
            meta: {sort: false},
        },
        // {
        //     id: 'edit-user',
        //     name: '',
        //     template: (item) => {
        //         return <EditButtonIcon_Link to={`${item.id}`}/>
        //     },
        //     align: "center",
        //     meta: {sort: false},
        // },
    ];

    const initialSettings = [
        {id: 'user', isSelected: true},
        {id: 'title', isSelected: true},
        {id: 'telegram', isSelected: true},
        {id: 'phone_number', isSelected: true},
        {id: 'birth_date', isSelected: true},
    ];

    const [settings, setSettings] = React.useState(() => (
        localStorage.getItem('allUsersTableSettings')
            ? JSON.parse(localStorage.getItem('allUsersTableSettings'))
            : initialSettings)
    );

    const [selectedUser, setSelectedUser] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const getRowActions = (item) => {
        return [
            {
                text: (
                    <div className={'d-flex align-items-center'}>
                        <Icon data={Pencil} className="me-1"/>
                        Редактировать
                    </div>
                ),
                handler: () => {
                    navigate(`${item.id}`);
                },
            },
            {
                text: (
                    <div className={'d-flex align-items-center'}>
                        <Icon data={Hand} className="me-1"/>
                        Заявки
                    </div>
                ),
                handler: () => {
                    navigate(`${item.id}/applications`);
                },
            },
            {
                text: (
                    <div className={'d-flex align-items-center'}>
                        <Icon data={Lock} className="me-1"/>
                        Права пользователя
                    </div>
                ),
                handler: () => {
                    setSelectedUser(item);

                    setIsModalOpen(true);
                },
            },
        ];
    };

    return (
        <>
            <MyTable className="user-table"
                     data={data}
                     columns={columns}
                     getRowActions={getRowActions}
                //      settings={settings}
                     updateSettings={(settings) => {
                         setSettings(settings);
                         localStorage.setItem('allUsersTableSettings', JSON.stringify(settings));
                         return Promise.resolve();
                     }}/>
            <UserModal user={selectedUser} open={isModalOpen} setOpen={setIsModalOpen}/>
        </>
    );
}

export default UsersTable;