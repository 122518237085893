import React from 'react';
import {Link} from "react-router-dom";
import {Button, Icon, Modal, Text} from "@gravity-ui/uikit"
import ProjectStatus, {
    ProjectDate, ProjectForSchool, ProjectKeywords, ProjectPersona, ProjectVenue
} from "./utils"

import './ProjectCard.css'
import {DeleteButtonIcon, EditButtonIcon_Link} from "../Button/Button";
import {DeleteModal} from "../Modal/Modals";
import {ArrowUpRightFromSquare} from "@gravity-ui/icons";
import {Accordion} from "react-bootstrap";

function ProjectUserCard({
                             children, project, onDelete = () => {
    }, forCustomer = false
                         }) {
    const [open, setOpen] = React.useState(false);

    const canEditProject = () => {
        return (project.status === 'submitted' || project.status === 'returned');
    }

    return (
        <div className="bmm-card project-card project-card-draft">
            <div className="project-card__pretitle">
                <div className="me-1">
                    <ProjectVenue venue={project.venue}/>
                </div>
                <div>
                    <ProjectForSchool is_for_schoolchildren={project.is_for_schoolchildren}/>
                    <ProjectStatus status={project.status}/>
                </div>
            </div>

            {canEditProject()
                ? <Link to={`${project.id}`} className="project-card__title">{project.name}</Link>
                : <span className="project-card__title">{project.name}</span>}

            <div className="project__keywords project-card__keywords">
                <ProjectKeywords keywords={project.keywords}/>
            </div>

            <div className="project-card__text" dangerouslySetInnerHTML={{__html: project.description}}/>

            {project.workers?.length > 0 &&
                <Accordion className={"mt-1 mb-1 workers-accordion"} flush>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Участники проекта</Accordion.Header>
                        <Accordion.Body className={"four-columns-container"}>
                            {project.workers.map((worker, index) => (
                                <div key={index} className={`bmm-card bmm-card_white distribution-participant-card ${worker.participation_confirmation === 'confirmed' && "background-confirmed" || worker.participation_confirmation === 'declined' && "background-declined"}`}>
                                    <ProjectPersona key={index} user={worker}/>
                                </div>
                            ))}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            }

            <div className="project-card__subtitle">
                <div className="project-card__subtitle-date">
                    <ProjectDate date={new Date(project.created_on)}/>
                </div>
                <div className="group-btn">
                    {canEditProject() && forCustomer &&
                        <>
                            <DeleteButtonIcon onClick={() => setOpen(true)}/>
                            <EditButtonIcon_Link to={`${project.id}`}/>
                        </>
                    }
                    <Link to={`/project/${project.id}`}>
                        <Button view={"normal"} size={"m"} title={"Предпросмотр"}>
                            <Icon data={ArrowUpRightFromSquare} size={18}/>
                        </Button>
                    </Link>
                </div>
            </div>
            <DeleteModal type="проект" name={`${project.name}`}
                         onDelete={() => onDelete(project.id)}
                         open={open} setOpen={setOpen}/>
        </div>
    );
}

export default ProjectUserCard;