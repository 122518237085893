import React from 'react';

import UserCard from './userCard/UserCard'

const Profile = ({size, side, className, profile}) => {

    return (
        <UserCard
            name={`${profile.last_name} ${profile.first_name}${size === 'sp' && profile.middle_name ? ` ${profile.middle_name}` : ''}`}
            email={`${profile.email || ""}`}
            imgUrl={profile.avatar}
            text={`${profile.title}`}
            size={size}
            side={side}
            className={className}
        />
    );
};

export default Profile;
