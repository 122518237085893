import React from 'react';
import {Link} from "react-router-dom";
import {Accordion, Form} from "react-bootstrap";

import {Button, Icon, Modal, Text} from "@gravity-ui/uikit"
import {ArrowRight, ArrowUpRightFromSquare, FileArrowDown, FileCheck, FileText} from "@gravity-ui/icons";

import ProjectStatus, {
    ProjectDate, ProjectForSchool, ProjectKeywords, ProjectPersona, ProjectVenue
} from "./utils"
import './ProjectCard.css'
import {HoverableIconLikeButton, IconLikeButton} from "../Button/Button";
import {openFileInNewTab} from "../../utils/utils";
import axios from "axios";
import {
    getAcceptAuthConfig,
    REFLECTIONS_MANAGER_DETAIL_ENDPOINT, REFLECTIONS_MANAGER_ENDPOINT,
    REFLECTIONS_TEMPLATE_DETAIL_ENDPOINT,
    REFLECTIONS_TEMPLATES_ENDPOINT, REFLECTIONS_WORKER_DETAIL_ENDPOINT, REFLECTIONS_WORKER_ENDPOINT
} from "../../api/api";
import ReflectionUpload from "../ReflectionUpload";

function ProjectWorkerCard({
                               project,
                               templates,
                               userFiles,
                               setUserFiles,
                               submitting,
                               setSubmitting
                           }) {

    const canEditProject = () => {
        return (project.status === 'submitted' || project.status === 'returned');
    }

    return (
        <div className="bmm-card project-card project-card-draft">
            <div className="project-card__pretitle">
                <div className="me-1">
                    <ProjectVenue venue={project.venue}/>
                </div>
                <div>
                    <ProjectForSchool is_for_schoolchildren={project.is_for_schoolchildren}/>
                </div>
            </div>

            {canEditProject()
                ? <Link to={`${project.id}`} className="project-card__title">{project.name}</Link>
                : <span className="project-card__title">{project.name}</span>}

            <div className="project__keywords project-card__keywords">
                <ProjectKeywords keywords={project.keywords}/>
            </div>

            <div className="project-card__text" dangerouslySetInnerHTML={{__html: project.description}}/>

            {templates && <ReflectionUpload templates={templates} userFiles={userFiles} setUserFiles={setUserFiles}
                                            submitting={submitting} setSubmitting={setSubmitting}
                                            projectId={project.id}
                                            detailEndpoint={REFLECTIONS_WORKER_DETAIL_ENDPOINT}
                                            endpoint={REFLECTIONS_WORKER_ENDPOINT}/>}

            <div className="project-card__subtitle">
                <div className="project-card__subtitle-date">
                    <ProjectDate date={new Date(project.created_on)}/>
                </div>
                <div className="group-btn">
                    <Link to={`/project/${project.id}`}>
                        <Button view={"normal"} size={"m"} title={"Предпросмотр"}>
                            <Icon data={ArrowUpRightFromSquare} size={18}/>
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default ProjectWorkerCard;