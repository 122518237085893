import React from 'react';
import axios from "axios";

export const openFileInNewTab = (e, href, fileName = '') => {
    axios.get(href, {
        responseType: 'blob',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
        }
    })
        .then(response => {
            const type = response.headers.get("Content-Type")
            const names = href.split('/').pop().split('.')
            const ext = names.length > 1 ? '.' + names.pop() : '';
            fileName = (fileName ? fileName : 'file') + ext;

            if (type !== 'application/liquid') {
                const url = window.URL.createObjectURL(new Blob([response.data], {type: type}));
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            } else {
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', href.split('/').pop());
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(href);
            }
        })
        .catch(error => console.error('Error downloading the file: ', error));
}

export const getGradeText = (grade) => {
    if (grade % 10 === 1 && grade % 100 !== 11) {
        return grade + " балл";
    } else if (2 <= grade % 10 && grade % 10 <= 4 && !(12 <= grade % 100 && grade % 100 <= 14)) {
        return grade + " балла";
    } else {
        return grade + " баллов";
    }
}

export const getThemeByPriority = (priority) => {
    switch (priority) {
        case 0:
            return 'success';
        case 1:
            return 'warning';
        default:
            return 'danger';
    }
};