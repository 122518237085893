import React from 'react';
import {NavLink} from 'react-router-dom';
import './NavList.css';

const NavList = ({children, className}) => {
    return <ul className={`nav-list ${className}`}>{children}</ul>;
};

const NavHeading = ({children}) => {
    return (
        <div className="nav-header">
            <span>{children}</span>
        </div>
    );
};

NavList.Heading = NavHeading;

const NavItem = ({to, children, theme = '', onClick, disabled = false}) => {
    const LinkComponent = (to && !disabled) ? NavLink : 'div';

    return (
        <li className={`nav-item ${theme} ${disabled ? 'nav-item-disabled' : ''}`}>
            <NavLink to={to} onClick={onClick}>
                {children[0].type === NavList.LeadingVisual ? (
                    <><span className="leading-visual">{children[0]}</span>
                        <span className="nav-item-label">{children[1]}</span></>
                ) : <span className="nav-item-label">{children}</span>}
            </NavLink>
        </li>
    );
};

NavList.Item = NavItem;

const LeadingVisual = ({children}) => {
    return <>{children}</>;
};

NavList.LeadingVisual = LeadingVisual;

const Divider = () => {
    return <hr/>;
};

NavList.Divider = Divider;

export default NavList;