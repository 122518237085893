import React from 'react';
import {Navigate, useLocation} from "react-router-dom";

function CanUpdateApplication({children}) {
    const location = useLocation();

    const currentDate = new Date();
    const maxDate = new Date('2024-06-07T23:59:59.999+07:00');
    // TODO заменить на дату дедлайна подачи проектов + добавить дату начала подачи проектов

    return currentDate > maxDate
        ? <Navigate to='/account/participant' state={{from: location}} replace={true}/>
        : children;
}

export default CanUpdateApplication;