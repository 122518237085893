import React, {useEffect, useRef, useState} from 'react';
import axios from "axios";
import {Form} from "react-bootstrap";

import {Icon, TextInput} from "@gravity-ui/uikit";
import {ArrowRight, Check, FileArrowDown, FileText, Xmark} from "@gravity-ui/icons";

import {HoverableIconLikeButton} from "./Button/Button";
import {openFileInNewTab} from "../utils/utils";
import {
    getAcceptAuthConfig,
    getJSONAcceptAuthConfig,
    PROJECT_CHARACTERISTIC_DETAIL_ENDPOINT,
    PROJECT_CHARACTERISTIC_ENDPOINT
} from "../api/api";
import {REFLECTION_FORMATS, RESULTS_APPLICATION_FORMATS} from "../utils/const";
import {Load} from "./Icons/Icons";

function ResultsApplication({
                              submitting,
                              setSubmitting,
                              detailEndpoint,
                              projectData
                          }) {

    const [userFile, setUserFile] = useState(projectData.results_application_file);
    const [userLink, setUserLink] = useState(projectData.results_application_url);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [selectedOption, setSelectedOption] = useState('file');
    const debounceTimeout = useRef(null);

    const handleFileUpload = async (e, projectData) => {
        setSubmitting(true);

        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('id', projectData.id);
            formData.append('results_application_file', file);

            axios({
                method: 'patch',
                url:  detailEndpoint(projectData.id),
                data: formData,
                ...getAcceptAuthConfig()
            })
                .then(response => {
                    setUserFile(response.data.results_application_file)
                })
                .catch(error => {
                    console.error('Ошибка при загрузке шаблона:', error);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        } else {
            setSubmitting(false);
            alert('Пожалуйста, выберите файл.');
        }
    };

    function truncateProjectName(name, id) {
        const maxLength = 20; // Define the maximum length for the truncated name
        if (name.length <= maxLength) {
            return `${id} ${name}`;
        }
        const start = name.slice(0, Math.ceil(maxLength / 2));
        const end = name.slice(-Math.floor(maxLength / 2));
        return `${id} ${start}...${end}`;
    }

    const sendResultsURL = async (projectId, resultsURL) => {
        try {
            const url = detailEndpoint(projectId);
            const formData = new FormData();
            formData.append('id', projectData.id);
            formData.append('results_application_url', resultsURL);
            const config = getJSONAcceptAuthConfig();

            let response;
            response = await axios.patch(url, formData, config);

            return response.data;
        } catch (error) {
            throw new Error('Error sending resultsURL to server');
        }
    };


    const handleResultsURLChange = (e) => {
        const newValue = e.target.value;
        setUserLink(newValue);
        setLoading(true);
        setSuccess(false);
        setError(false);

        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(async () => {
            try {
                await sendResultsURL(projectData.id, newValue);
                setLoading(false);
                setSuccess(true);
                setTimeout(() => setSuccess(false), 1000); // Убираем галочку через 1 секунду
            } catch (error) {
                setLoading(false);
                setError(true);
                setTimeout(() => setError(false), 1000); // Убираем крестик через 1 секунду
            }
        }, 1000); // Задержка 1000 мс
    };

    useEffect(() => {
        return () => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
        };
    }, []);

    return (
        <div>
                <Form.Group>
                    <Form.Label>{"Итоговая заметка"}</Form.Label>
                </Form.Group>
            <div className={"mb-3"}>
                <Form.Group>
                    <Form.Check
                        inline
                        type="radio"
                        label="Файл"
                        name="option"
                        value="file"
                        checked={selectedOption === 'file'}
                        onChange={(e) => setSelectedOption(e.target.value)}
                    />
                    <Form.Check
                        inline
                        type="radio"
                        label="Ссылка"
                        name="option"
                        value="link"
                        checked={selectedOption === 'link'}
                        onChange={(e) => setSelectedOption(e.target.value)}
                    />
                </Form.Group>
                    {selectedOption === 'file' && (
                        <div className={"d-flex align-items-center mt-2"}>
                            <HoverableIconLikeButton icon={FileText} hoverIcon={FileArrowDown}
                                                     size={46}
                                                     onClick={(e) => openFileInNewTab(e, projectData.results_application_file, truncateProjectName(projectData.name, projectData.id))}
                                                     disabled={!userFile}
                            />
                        <Form.Group>
                            <Form.Control type="file" size="sm"
                                          accept={RESULTS_APPLICATION_FORMATS}
                                          onChange={(e) => handleFileUpload(e, projectData)}
                                          disabled={submitting}
                            />
                            <Form.Text muted>
                                Можно загрузить только docx. Заметка будет опубликована на странице проекта после
                                модерации.
                            </Form.Text>
                        </Form.Group>
                </div>
                    )}
                    {selectedOption === 'link' && (
                        <Form.Group>
                            <TextInput
                                type="url"
                                className={"without-appearance me-1 mt-2"}
                                value={userLink}
                                onChange={handleResultsURLChange}
                                rightContent={
                                    loading ? (
                                        <Icon name="loading" data={Load} className="icon-loading me-1"/>
                                    ) : success ? (
                                        <Icon name="check" data={Check} className={"me-1"}/>
                                    ) : error ? (
                                        <Icon name="cross" data={Xmark} className={"me-1"}/>
                                    ) : null
                                }
                            />
                            <Form.Text muted>
                                Вставьте полную ссылку на статью на habr.ru. Ссылка будет опубликована на странице
                                проекта после модерации.
                            </Form.Text>
                        </Form.Group>
                    )}
            </div>
        </div>
    );
}

export default ResultsApplication;