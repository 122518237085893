import React from 'react';
import axios from "axios";
import {Form} from "react-bootstrap";

import {Icon} from "@gravity-ui/uikit";
import {ArrowRight, FileArrowDown, FileText} from "@gravity-ui/icons";

import {HoverableIconLikeButton} from "./Button/Button";
import {openFileInNewTab} from "../utils/utils";
import {getAcceptAuthConfig} from "../api/api";
import {REFLECTION_FORMATS} from "../utils/const";

function ReflectionUpload({
                              templates,
                              userFiles,
                              setUserFiles,
                              submitting,
                              setSubmitting,
                              projectId,
                              detailEndpoint,
                              endpoint
                          }) {
    const getUserFile = (templateId, projectId) => {
        return userFiles.find(userFile => userFile.template === templateId && userFile.project === projectId);
    };

    const handleFileUpload = async (e, template, project) => {
        setSubmitting(true);

        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('template', template);
            formData.append('project', project);

            const userFile = userFiles.find(userFile => userFile.template === template && userFile.project === project);

            axios({
                method: userFile ? 'put' : 'post',
                url: userFile ? detailEndpoint(userFile.id) : endpoint,
                data: formData,
                ...getAcceptAuthConfig()
            })
                .then(response => {
                    if (userFile) {
                        // Replace the existing userFile in the array
                        setUserFiles(prevUserFiles => prevUserFiles.map(uf => uf.id === userFile.id ? response.data : uf));
                    } else {
                        // Add the new userFile to the array
                        setUserFiles(prevUserFiles => [...prevUserFiles, response.data]);
                    }
                    // setTemplates(prevTemplates => ({
                    //     ...prevTemplates,
                    //     [type]: response.data
                    // }));
                    // setSuccessIcons(prevSuccessIcons => ({
                    //     ...prevSuccessIcons,
                    //     [type]: true
                    // }));
                    // setTimeout(() => {
                    //     setSuccessIcons(prevSuccessIcons => ({
                    //         ...prevSuccessIcons,
                    //         [type]: false
                    //     }));
                    // }, 1000);
                })
                .catch(error => {
                    console.error('Ошибка при загрузке шаблона:', error);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        } else {
            setSubmitting(false);
            alert('Пожалуйста, выберите файл.');
        }
    };

    const handleDownloadTemplate = (file) => {
        if (file) {
            openFileInNewTab(null, file.file, file.type_file || templates.find(template => template.id === file.template).type_file);
        }
    };

    return (
        <div>
            {templates.map((template) => {
                const userFile = getUserFile(template.id, projectId);
                return (
                    <div className={"mb-3"} key={template.id}>
                        <div className={"d-flex align-items-center mt-2"}>
                            <HoverableIconLikeButton icon={FileText} hoverIcon={FileArrowDown}
                                                     size={46}
                                                     onClick={() => handleDownloadTemplate(template)}
                            />
                            <Icon data={ArrowRight} size={32}/>
                            <HoverableIconLikeButton icon={FileText} hoverIcon={FileArrowDown}
                                                     size={46}
                                                     onClick={() => handleDownloadTemplate(userFile)}
                                                     disabled={!userFile}
                            />
                            <Form.Group>
                                <Form.Label>{template.type_file.endsWith("_reflection") ? "Рефлексия" : "Обратная связь"}</Form.Label>
                                <Form.Control type="file" size="sm"
                                              accept={REFLECTION_FORMATS}
                                              onChange={(e) => handleFileUpload(e, template.id, projectId)}
                                              disabled={submitting}
                                />
                            </Form.Group>
                        </div>
                        <Form.Text muted>
                            Если вы хотите загрузить больше одного файла, пожалуйста, сожмите их в один файл pdf либо
                            zip.
                            Например, в сервисах <a href={"https://www.ilovepdf.com/ru/jpg_to_pdf"}
                                                    target={"_blank"}>ilovepdf</a> или <a
                            href={"https://www.compress2go.com/ru/create-zip-file"}
                            target={"_blank"}>compress2go</a>.
                        </Form.Text>
                    </div>
                )
            })}
        </div>
    );
}

export default ReflectionUpload;