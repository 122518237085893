import React, {Fragment} from 'react';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import {Navbar, Nav, Container} from 'react-bootstrap';

import Profile from "../Profile";

import "./header.css"


const Header = ({navLinks, isAuthenticated, user}) => {

    const guestLinks = () => (
        <Nav className="ml-auto mt-3 mt-lg-0" variant="underline">
            <NavLink to="signin" className="nav-link nav-link_action">Вход</NavLink>
        </Nav>
    );

    const authLinks = () => (
        <Nav className="ml-auto mt-3 mt-lg-0 align-items-end">
            <NavLink to="account">
                {user ? <Profile size={'s'} side={'left'} profile={user}/> : <></>}
            </NavLink>
        </Nav>
    );

    return (
        <Navbar className="header bg-body-tertiary" expand="lg"> {/* без expand="lg" не будет появляться бургер */}
            <Container>
                <Navbar.Brand><NavLink to="/about" className="navbar-brand-link">БММ</NavLink></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto mx-auto" variant="underline">
                        {navLinks.map((link) => (
                            <NavLink key={link.to} to={link.to} className="nav-link">
                                {link.label}
                            </NavLink>
                        ))}
                        <NavLink to="contacts" className="nav-link">
                            Контакты
                        </NavLink>
                    </Nav>
                    {isAuthenticated ? authLinks() : guestLinks()}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

const mapStateToProps = state => ({
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(Header);