import React from 'react'

const ArchivePage = () => {
    return (
        <>
            <div className="page-title">Архив</div>
            <ul className="list-unstyled bmm-text mt-5">
                <li><a target="_blank" href="https://bmm2023.mca.nsu.ru/">
                    Большая математическая мастерская 2023
                </a></li>
                <li><a target="_blank" href="https://bmm2022.mca.nsu.ru/">
                    Большая математическая мастерская 2022
                </a></li>
                <li><a target="_blank" href="https://bmm2021.mca.nsu.ru/">
                    Большая математическая мастерская 2021
                </a></li>
                <li><a target="_blank" href="https://mca.nsu.ru/workshop/">
                    Первый воркшоп Математического центра в Академгородке 2020
                </a></li>
            </ul>
        </>
    )
}

export default ArchivePage