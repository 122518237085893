import React, {useState, useRef, useEffect} from 'react';
import axios from 'axios';
import {connect} from "react-redux";

import {Form, InputGroup} from "react-bootstrap";
import {PhoneInput} from 'react-international-phone';
import 'react-international-phone/style.css';
import {PhoneNumberUtil} from 'google-libphonenumber';
import {Button} from "@gravity-ui/uikit";

import UserCard from "../../components/userCard/UserCard";
import {load_user} from "../../actions/auth";
import {Pencil} from "@gravity-ui/icons";
import {AUTH_LOAD_CURRENT_USER_ENDPOINT, getAcceptAuthConfig} from "../../api/api";

const EditProfileForm = ({user}) => {
    if (user === null) {
        load_user();
        user = {
            last_name: "",
            first_name: "",
            middle_name: "",
            telegram_username: "",
            phone_number: "",
            birth_date: "",
            title: "",
            address: ""
        }
    }

    useEffect(() => {
        setFormData(user);
    }, [user]);


    const [formData, setFormData] = useState({
        last_name: user.last_name,
        first_name: user.first_name,
        middle_name: user.middle_name,
        telegram_username: user.telegram_username,
        phone_number: user.phone_number,
        birth_date: user.birth_date,
        title: user.title,
        address: user.address
    });
    const filePicker = useRef(null);

    const phoneUtil = PhoneNumberUtil.getInstance();

    const isPhoneValid = (phone) => {
        try {
            return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
        } catch (error) {
            return false;
        }
    };

    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);

    const onSubmit = (e) => {
        e.preventDefault();

        if (!isPhoneValid(formData.phone_number) && formData.phone_number !== "+7" && formData.phone_number !== "") {
            e.target.querySelector('[name="phone_number"]').setCustomValidity('Телефон указан неверно.');
        } else {
            setSubmitting(true);
            e.target.querySelector('[name="phone_number"]').setCustomValidity('');

            const formDataWithoutAvatar = {...formData};
            delete formDataWithoutAvatar.avatar;

            axios
                .patch(AUTH_LOAD_CURRENT_USER_ENDPOINT, formDataWithoutAvatar, getAcceptAuthConfig())
                .then(response => {
                    window.location.reload();
                })
                .catch(error => {
                    console.error('Error updating user data:', error);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
    };

    const onChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value
        }));
    };

    const onChangePhone = (e) => {
        document.querySelector('[name="phone_number"]').setCustomValidity('');
        setFormData((prevData) => ({
            ...prevData,
            phone_number: e
        }));
    }

    const onChangeAvatar = async (e) => {
        const formData = new FormData();
        formData.append('avatar', e.target.files[0]);

        axios
            .patch(AUTH_LOAD_CURRENT_USER_ENDPOINT, formData, getAcceptAuthConfig())
            .then(response => {
                window.location.reload();
            })
            .catch(error => {
                console.error('Error updating user avatar:', error);
            })
    };

    const onPick = () => {
        filePicker.current.click();
    };

    return (
        <>
            <h3 className="mb-2">Редактирование профиля</h3>
            <hr className="clearfix w-100 pb-0"/>
            <div className="row d-flex flex-column-reverse flex-lg-row">
                <div className="col-lg-9 mt-lg-0 mt-3">
                    <Form onSubmit={onSubmit}>

                        <Form.Group controlId="formLastname">
                            <Form.Label className="required">Фамилия</Form.Label>
                            <Form.Control required type="text" name="last_name" value={formData.last_name}
                                          onChange={onChange}/>
                        </Form.Group>

                        <Form.Group controlId="formFirstname">
                            <Form.Label className="required">Имя</Form.Label>
                            <Form.Control required type="text" name="first_name" value={formData.first_name}
                                          onChange={onChange}/>
                        </Form.Group>

                        <Form.Group controlId="formMiddlename">
                            <Form.Label>Отчество</Form.Label>
                            <Form.Control type="text" name="middle_name" value={formData.middle_name}
                                          onChange={onChange}/>
                        </Form.Group>

                        <Form.Group controlId="formTitle">
                            <Form.Label>Должность, место работы/учёбы</Form.Label>
                            <Form.Control as="textarea" type="text" name="title" value={formData.title}
                                          onChange={onChange}/>
                        </Form.Group>

                        <Form.Group controlId="formBirthday">
                            <Form.Label>День рождения</Form.Label>
                            <Form.Control type="date" name="birth_date" value={formData.birth_date}
                                          onChange={onChange}/>
                        </Form.Group>

                        <Form.Group controlId="formMiddlename">
                            <Form.Label>Город проживания</Form.Label>
                            <Form.Control type="text" name="address" value={formData.address}
                                          onChange={onChange}/>
                        </Form.Group>

                        <Form.Group controlId="formTelegram">
                            <Form.Label>Логин Телеграмма</Form.Label>
                            <InputGroup>
                                <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                                <Form.Control type="text"
                                              name="telegram_username"
                                              value={formData.telegram_username}
                                              onChange={onChange}/>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group controlId="formPhoneNumber">
                            <Form.Label>Номер телефона</Form.Label>
                            <PhoneInput defaultCountry="ru"
                                        inputProps={{name: "phone_number"}}
                                        value={formData.phone_number}
                                        onChange={onChangePhone}/>
                        </Form.Group>

                        <Button view="action" type="submit" disabled={submitting} className="mt-3 w-100" size="l">
                            {submitting ? 'Сохранение...' : 'Сохранить'}
                        </Button>
                    </Form>
                </div>
                <div className="col-lg-3">
                    <Form>
                        <Form.Label>Изображение профиля</Form.Label>
                        <Form.Control className="hidden" type="file" ref={filePicker} accept="image/*"
                                      onChange={onChangeAvatar}></Form.Control>
                        <div className="avatar-upload position-relative" role="button" onClick={onPick}>
                            <UserCard size="l" imgUrl={user.avatar} name={`${user.last_name} ${user.first_name}`} initialsOnly={true}/>
                            <span className="avatar-upload__button">
                                <Pencil/> Изменить
                            </span>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = state => ({
    user: state.auth.user,
});
export default connect(mapStateToProps, {load_user})(EditProfileForm);
