import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom'
import ReactDOM from 'react-dom/client';

// import './styles/reset.css';

import {ThemeProvider} from '@gravity-ui/uikit';
import {configure} from '@gravity-ui/uikit';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@gravity-ui/uikit/styles/fonts.css';
import '@gravity-ui/uikit/styles/styles.css';

import App from './App';

import './styles/index.css';
import './pages/pages.css';
import './styles/form.css'
import store from "./store";
import {Provider} from "react-redux";

configure({
    lang: 'ru',
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme="light">
        <Router>
            <Provider store={store}>
                <App/>
            </Provider>
        </Router>
    </ThemeProvider>
);