import React, {useEffect, useState} from 'react';
import {Navigate, useLocation} from "react-router-dom";
import {connect} from "react-redux";

function CanUpdateTravel({children, user}) {
    const [isUserLoaded, setIsUserLoaded] = useState(false);
    const location = useLocation();

    const currentDate = new Date();
    const maxDate = new Date('2024-06-02T11:59:59.999+07:00');
    // TODO заменить на дату дедлайна подачи тревела

    const is_bad_user = () => {
        return (!user?.last_name || !user?.first_name || !user?.middle_name || !user?.birth_date)
    }

    useEffect(() => {
        if (user) {
            setIsUserLoaded(true);
        }
    }, [user]);

    useEffect(() => {
        if (isUserLoaded) {
            if (currentDate > maxDate || is_bad_user() ) {
                return <Navigate to='/account/travel' state={{from: location}} replace={true}/>
            }
        }
    }, [isUserLoaded]);

    if (!isUserLoaded) {
        return null;
    }

    return children;
}

const mapStateToProps = state => ({
    user: state.auth.user,
});

export default connect(mapStateToProps)(CanUpdateTravel)