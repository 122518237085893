import React, {useState, useEffect} from 'react';
import axios from "axios";

import {TextInput, Icon} from '@gravity-ui/uikit';
import {File, Magnifier} from "@gravity-ui/icons";

import {ALL_TRAVEL_GRANTS_ENDPOINT, ALL_TRAVEL_XLSX_ENDPOINT, getAcceptAuthConfig} from "../../../api/api";
import TravelGrantsTable from "../../../components/TravelGrantsTable/TravelGrantsTable";
import {LinkLikeButton_File} from "../../../components/Button/Button";
import LoadingSpinner from "../../../components/LoadingSpinner";

const filterGrants = (searchText, allData) => {
    if (!searchText) {
        return allData;
    }
    return allData.filter(({user, livings, comment, motivation}) =>
        user.last_name.toLowerCase().includes(searchText.toLowerCase()) ||
        user.first_name.toLowerCase().includes(searchText.toLowerCase()) ||
        user.email.toLowerCase().includes(searchText.toLowerCase()) ||
        user.address.toLowerCase().includes(searchText.toLowerCase()) ||
        user.phone_number.toLowerCase().includes(searchText.toLowerCase()) ||
        user.telegram_username.toLowerCase().includes(searchText.toLowerCase()) ||
        (livings && livings.some(living =>
            living.local_workshop.venue.name.toLowerCase().includes(searchText.toLowerCase())
        )) ||
        (comment && comment.toLowerCase().includes(searchText.toLowerCase())) ||
        (motivation && motivation.toLowerCase().includes(searchText.toLowerCase()))
    );
}

function TravelGrantsList() {
    const [isLoading, setIsLoading] = useState(true);

    const [allGrants, setAllGrants] = useState([]);
    const [enteredValue, setEnteredValue] = useState("");
    const [filteredGrants, setFilteredGrants] = useState(allGrants);

    useEffect(() => {
        axios
            .get(ALL_TRAVEL_GRANTS_ENDPOINT, getAcceptAuthConfig())
            .then(response => {
                setAllGrants(response.data);
                setFilteredGrants(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the travel grants:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        const Debounce = setTimeout(() => {
            setFilteredGrants(filterGrants(enteredValue, allGrants));
        }, 300);

        return () => clearTimeout(Debounce);
    }, [enteredValue, allGrants]);

    return (
        <>
            <h3 className="mb-2">Тревел гранты</h3>
            <hr className="clearfix w-100 pb-0"/>

            <TextInput className={"search-input mt-3 mb-2"}
                       size="l"
                       placeholder="Search..."
                       leftContent={<Icon data={Magnifier}/>}
                       hasClear={true}
                       value={enteredValue}
                       onChange={(e) => setEnteredValue(e.target.value)}
            />

            <LinkLikeButton_File href={ALL_TRAVEL_XLSX_ENDPOINT}
                                 icon={<File/>} text={'xlsx со всеми грантами'}
                                 fileName={'Все травелгранты'}
            />
            <div className="grants__quantity-info quantity-info">
                Количество тревел грантов: {filteredGrants.length}
            </div>

            {isLoading &&
                <LoadingSpinner/> ||
                <TravelGrantsTable grants={filteredGrants} setGrants={setFilteredGrants} setAllGrants={setAllGrants}
                                   allGrants={allGrants}/>}
        </>
    );
}

export default TravelGrantsList;