import React, {useEffect, useState} from 'react';
import {Navigate, useParams} from 'react-router-dom';
import {Card, Container} from "@gravity-ui/uikit";
import {connect} from "react-redux";

import {activation} from '../../actions/auth';

const Activation = ({activation}) => {
    const {uid, token} = useParams();
    const [verified, setVerified] = useState(false);

    useEffect(() => {
        activation(uid, token);
        setVerified(true);
    }, [uid, token]);

    if (verified) {
        return <Navigate to='/signin'/>
    }

    return (
        <Container className="mainContainer">
            <Card view="raised" className="info__card yc-s__p_5">
                <h1 className="info__title">Регистрация завершина</h1>
                <div className="info__text">Сейчас вы будете направлены на страницу авторизации.</div>
            </Card>
        </Container>
    );
};

export default connect(null, {activation})(Activation);