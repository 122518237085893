import React from 'react'

import {Container} from 'react-bootstrap';

import "./footer.css"

const Footer = ({navLinks}) => {
    return (
        <footer className="footer">
            <Container>
                <div className="row">
                    <div className="col-md-3 col-lg-2 col-6 mb-md-0 mb-3">
                        <h1 className="footer-title">Разделы</h1>
                        <ul className="list-unstyled">
                            {navLinks.map((link) => (
                                <li key={link.to}>
                                    <a href={link.to} className="footer-link footer-link_nav">
                                        {link.label}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="col-md-3 col-6 mb-md-0 mb-3">
                        <h1 className="footer-title">Контакты</h1>
                        <ul className="list-unstyled">
                            <li><a className="footer-link" href="https://t.me/+MetN9nDE7WM4Y2Vk/" target="_blank">
                                Телеграм-канал БММ
                            </a></li>
                            <li><a className="footer-link" href="https://t.me/+jwVuk_743uA4OWUy/" target="_blank">
                                Чат участников БММ
                            </a></li>
                            <li><a className="footer-link" href="https://vk.com/bmm_akadem/" target="_blank">
                                Сообщество БММ Вконтакте
                            </a></li>
                        </ul>
                    </div>

                    <div className="col-md-6 mt-md-0 mt-3 d-flex flex-column flex-md-column-reverse">
                        <div className="logo_block">
                            <a href="https://www.nsu.ru/n/mca/" target="_blank" className="logo-cmp">
                                <img src="/static/logos/Logo_MTsAen.webp" alt="МЦА"></img>
                            </a>
                            <a href="https://nomc.math.tsu.ru/" target="_blank" className="logo-cmp">
                                <img src="/static/logos/Tomsk_new.webp" alt="ТГУ"></img>
                            </a>
                            <a href="https://ofim.oscsbras.ru/" target="_blank" className="logo-cmp logo-cmp_big">
                                <img src="/static/logos/Logo_IM_ru.png" alt="ИМСОРАН"></img>
                            </a>
                            <a href="https://www.utmn.ru/bmm/" target="_blank" className="logo-cmp logo-cmp_short">
                                <img src="/static/logos/utmn.svg" alt="ТюмГУ"></img>
                            </a>
                            <a href="https://www.adygnet.ru/" target="_blank" className="logo-cmp">
                                <img src="/static/logos/KMC_v2.webp" alt="АГУ"></img>
                            </a>
                        </div>

                        <hr className="clearfix w-100 d-md-none pb-0"/>

                        <div>
                            <h1 className="footer-title">БММ</h1>
                            <p>Мастерская организована при поддержке Математического центра в&nbsp;Академгородке,
                                соглашение
                                с&nbsp;Министерством науки и&nbsp;высшего образования Российской
                                Федерации &#8470;&nbsp;075-15-2022-282.</p>
                            <p><a href="https://bmm.mca.nsu.ru/">bmm.mca.nsu.ru</a> и <a
                                href="https://great-math.ru/">great-math.ru</a> являются равнозначными адресами сайта
                                БММ.</p>

                        </div>
                        {/*<a className="footer-link">Политика конфиденциальности</a>*/}
                    </div>
                </div>
            </Container>
        </footer>
    );
}

export default Footer