import {Label} from "@gravity-ui/uikit";
import React from "react";

export const TRAVEL_STATUS_CHOICES = {
    'A':{value:'accepted', label:'Одобрен', color:'#2d8b5d', theme:'success'},
    'R':{value:'rejected', label:'Отклонён', color:'#bd0935', theme:'danger'},
    'P':{value:'pending', label:'На рассмотрении', color:'#3072b3', theme:'info'}
}

export const  TravelStatus = ({status}) => {
    const statusData = TRAVEL_STATUS_CHOICES[status];

    return (<Label theme={statusData.theme} className={"ms-1"}>{statusData.label}</Label>);
};