import React from 'react';

function ContactsPage(props) {
    return (
        <>
            <div className="page-title">Контакты</div>

            <div className="bmm-text mt-5">
                <a href="https://t.me/+MetN9nDE7WM4Y2Vk/" target="_blank">
                    Телеграм-канал БММ
                </a><br/>
                <a href="https://t.me/+jwVuk_743uA4OWUy/" target="_blank">
                    Чат участников БММ
                </a><br/>
                <a href="https://vk.com/bmm_akadem/" target="_blank">
                    Сообщество БММ Вконтакте
                </a>
            </div>
        </>
    );
}

export default ContactsPage;