import React, {useState} from 'react'
import {Navigate} from "react-router-dom";
import {Container, Card, Button} from "@gravity-ui/uikit";
import {Eye, EyeSlash} from '@gravity-ui/icons';
import {Form, InputGroup} from "react-bootstrap";
import {connect} from 'react-redux';

import PasswordButton from "../../components/passwordButton/PasswordButton";
import {signup} from '../../actions/auth';

const RegistrationPage = ({signup, isAuthenticated}) => {
    const [submitting, setSubmitting] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        re_password: ''
    });
    const {first_name, last_name, email, password, re_password} = formData;

    const [errors, setErrors] = useState({
        email: '',
        last_name: '',
        first_name: '',
        password: '',
        re_password: '',
        non_field_errors: ''
    });

    if (isAuthenticated) {
        return <Navigate to='/'/>
    }

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setSubmitting(true);
        signup(first_name, last_name, email, password, re_password)
            .then(response => {
                setIsSent(true);
            })
            .catch(e => {
                setErrors(JSON.parse(e.request?.responseText));
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <Container className="mainContainer">
            <Card view="raised" className="info__card g-s__p_5">
                {isSent ? (
                        <>
                            <h1 className="info__title">Подтверждение почты</h1>
                            <div className="info__text">На указанный вами адрес <strong>{email}</strong> было
                                отправлено письмо с инструкциями по подтверждению
                                электронной почты.
                                <br/><br/>
                                Пожалуйста, следуйте указанным в письме инструкциям для завершения регистрации.
                                <br/><br/>
                                Если письмо не приходит, проверьте папку «Спам».
                            </div>
                        </>) :
                    (<>
                        <h1 className="info__title">Регистрация</h1>
                        <Form onSubmit={onSubmit}>
                            <Form.Group controlId="formEmail">
                                <Form.Label>Электронная почта</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={email}
                                    onChange={onChange}
                                    isInvalid={!!(errors && errors.email)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">{errors && errors.email}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="formLastname">
                                <Form.Label>Фамилия</Form.Label>
                                <Form.Control type="text"
                                              name="last_name"
                                              value={last_name}
                                              onChange={onChange}
                                              isInvalid={!!(errors && errors.last_name)}
                                              required
                                />
                                <Form.Control.Feedback
                                    type="invalid">{errors && errors.last_name}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="formFirstname">
                                <Form.Label>Имя</Form.Label>
                                <Form.Control type="text"
                                              name="first_name"
                                              value={first_name}
                                              onChange={onChange}
                                              isInvalid={!!(errors && errors.first_name)}
                                              required
                                />
                                <Form.Control.Feedback
                                    type="invalid">{errors && errors.first_name}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="formPasssword">
                                <Form.Label>Пароль</Form.Label>
                                <Form.Control
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    value={password}
                                    onChange={onChange}
                                    isInvalid={!!(errors && errors.password || errors && errors.non_field_errors)}
                                    required
                                />
                                <Form.Control.Feedback
                                    type="invalid">{errors && errors.password}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="formRePasssword">
                                <Form.Label>Повторите пароль</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type={showPassword ? 'text' : 'password'}
                                        name="re_password"
                                        value={re_password}
                                        onChange={onChange}
                                        isInvalid={!!(errors && errors.re_password || errors && errors.non_field_errors)}
                                        required
                                    />
                                    <PasswordButton onClick={toggleShowPassword}>
                                        {showPassword ? <EyeSlash/> : <Eye/>}
                                    </PasswordButton>
                                </InputGroup>
                                <Form.Control.Feedback type="invalid">
                                    {errors && errors.re_password || errors && errors.non_field_errors}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="formCheckboxes" className="mt-3">
                                <Form.Check
                                    className="mb-2"
                                    id="c1"
                                    required
                                    type="checkbox"
                                    label={<span>Я подтверждаю <a className="text-decoration-none" target="_blank"
                                                                  href="https://drive.google.com/file/d/1zob5nNc_YzlyQK6jPhqmUVMWNKYJOPdR/view">Согласие на обработку персональных данных</a></span>}
                                />
                                <Form.Check
                                    className="mb-2"
                                    id="c2"
                                    required
                                    type="checkbox"
                                    label={<span>Я подтверждаю <a className="text-decoration-none" target="_blank"
                                                                  href="https://drive.google.com/file/d/1xnBriBtc6bt1c0WxuHZAnrlwIWdhc9x1/view">Согласие на фото и видео съемку во время мероприятия</a></span>}
                                />
                                <Form.Check
                                    id="c3"
                                    required
                                    type="checkbox"
                                    label={<span>Я ознакомлен с <a className="text-decoration-none" target="_blank"
                                                                   href="https://drive.google.com/file/d/1tATQsbxIW-eqGzs3GFuAWKzGYJ-Elhxa/view">порядком проведения мероприятия</a></span>}
                                />
                            </Form.Group>

                            <Button view="action" type="submit" disabled={submitting} className="mt-3 w-100"
                                    size="l">
                                {submitting ? 'Регистрация...' : 'Зарегистрироваться'}
                            </Button>
                        </Form>
                    </>)}
            </Card>
        </Container>
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});
export default connect(mapStateToProps, {signup})(RegistrationPage);