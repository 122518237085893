import React from 'react'
import {Link} from "react-router-dom";

import Carousel from 'react-bootstrap/Carousel';
import {Accordion} from 'react-bootstrap';
import {Popover} from '@gravity-ui/uikit';
import {CircleInfo} from '@gravity-ui/icons';

const AboutPage = () => {
    const importAll = (r) => r.keys().map(r);
    const images = importAll(require.context('../../../public/static/aboutImg', false, /\.(png|jpg|svg|webp)$/));

    return (
        <>
            <h1 className="page-title">Большая математическая мастерская</h1>
            <a href="https://xn--e1anbdcdahefrkku.xn--p1ai/" target="_blank" className="bmm-important-info">
                Мероприятие-спутник Международного форума технологического развития ТЕХНОПРОМ-2024
            </a>
            <div className="bmm-period">8-14 июля + межмодуль + 18-23 июля 2024 года</div>

            <div className="section">
                <h2 className="bmm-title">Что такое Большая математическая мастерская?</h2>
                <div className="bmm-text">
                    <p>Большая математическая мастерская – это мероприятие, где команды студентов, школьников и
                        педагогов вместе с кураторами работают над математическими проектами. Командам предлагается в
                        рамках своего проекта попробовать решить или существенно продвинуться в решении интересной
                        исследовательской, технологической или методологической задачи. Команды сопровождают кураторы, а
                        задачи поступают от заказчиков – представителей научных организаций и индустриальных
                        предприятий. Мероприятие состоит из двух недель интенсивной работы, разделенных между собой
                        трехдневным межмодулем.</p>

                    <a href="https://vk.com/bmm_akadem/" target="_blank">
                        Сообщество БММ Вконтакте
                    </a><br/>
                    <a href="https://t.me/+MetN9nDE7WM4Y2Vk/" target="_blank">
                        Телеграм-канал БММ
                    </a><br/>
                    <a href="https://t.me/+jwVuk_743uA4OWUy/" target="_blank">
                        Чат участников БММ
                    </a><br/><br/>

                    Стать участником Мастерской могут:
                    <ul>
                        <li>учащиеся старших классов школ (школьники, которые в результате работы на Мастерской
                            будут отмечены кураторами, могут заработать 5 баллов к суммарному баллу ЕГЭ при поступлении
                            в НГУ);
                        </li>
                        <li>студенты (студенты, которые в результате работы на Мастерской будут отмечены кураторами,
                            получат сертификаты участников Большой математической мастерской);
                        </li>
                        <li>молодые исследователи, учителя/преподаватели (для педагогов и исследователей с высшим
                            образованием Мастерская станет программой повышения квалификации, при ее успешном
                            прохождении будут выданы свидетельства установленного образца).
                        </li>
                    </ul>
                </div>
                {images.length > 0 && (
                    <Carousel interval={null}>
                        {images.map((image, index) => (
                            <Carousel.Item key={index}>
                                <img className="carousel-img" src={image} alt={`slide ${index}`}/>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                )}

                <Accordion className="about-accordion mt-4" alwaysOpen>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h3 className="bmm-subtitle m-0">
                            Где и когда будет проходить Мастерская?
                        </h3></Accordion.Header>
                        <Accordion.Body className="bmm-text">
                            Все активности Мастерской будут проходить в&nbsp;очном формате. Работа будет организована
                            на&nbsp;пятиплощадках: в&nbsp;Новосибирске&nbsp;(НГУ), Томске&nbsp;(ТГУ),
                            Майкопе&nbsp;(АГУ),
                            Тюмени&nbsp;(ТюмГУ), Омске&nbsp;(ОФИМ&nbsp;СО&nbsp;РАН).<br/><br/>

                            <strong>Адреса площадок:</strong><br/>
                            <a href="https://www.nsu.ru/n/" target="_blank">
                                Новосибирский государственный университет
                            </a>, г.&nbsp;Новосибирск, ул.&nbsp;Пирогова, д.&nbsp;1;<br/>
                            <a href="https://www.tsu.ru/" target="_blank">
                                Томский государственный университет
                            </a>, г.&nbsp;Томск, ул.&nbsp;Проспект Ленина,&nbsp;36&nbsp;к2;<br/>
                            <a href="https://www.adygnet.ru/" target="_blank">
                                Адыгейский государственный университет
                            </a>, г.&nbsp;Майкоп, ул.&nbsp;Первомайская,&nbsp;208 (на данной площадке БММ будет
                            проводиться с 8 по 19 июля);<br/>
                            <a href="https://www.utmn.ru/" target="_blank">
                                Тюменский государственный университет
                            </a>, г.&nbsp;Тюмень, ул.&nbsp;Перекопская, д.&nbsp;15А (на данной площадке БММ будет
                            проводиться с 8 по 18 июля);<br/>
                            {/*<a href="https://ofim.oscsbras.ru/" target="_blank">*/}
                            {/*    Омский филиал Института математики им. С.Л. Соболева СО РАН*/}
                            {/*</a>, работа БММ пройдет в <a href="https://leader-id.ru/places/3942" target="_blank">Точке*/}
                            {/*кипения</a> г.&nbsp;Омск, ул.&nbsp;Маршала Жукова, д.&nbsp;21.<br/><br/>*/}
                            <a href="https://ofim.oscsbras.ru/" target="_blank">
                                Омский филиал Института математики СО РАН</a> и <a href="https://omsu.ru/" target="_blank">
                                Омский государственный университет</a>, работа
                            БММ пройдет в ОмГУ, г. Омск, ул. Фрунзе 6.<br/><br/>

                            <strong>Даты проведения Мастерской:</strong><br/>
                            Первый модуль: 08–14 июля<br/>
                            Межмодуль: 15–17 июля<br/>
                            Второй модуль: 18–23 июля<br/><br/>
                            Для участников Мастерской, проживающих в городе, отличном
                            от города-площадки проведения проекта, предусмотрено полное или частичное
                            покрытие расходов на проезд (до площадки и обратно) и проживание во время
                            Мастерской. При закупке билетов датой прилета является день до начала первого
                            модуля, датой вылета – день после завершения второго модуля. В межмодуль
                            участники остаются на месте проведения Мастерской.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><h3 className="bmm-subtitle m-0">
                            Как принять участие в Мастерской?
                        </h3></Accordion.Header>
                        <Accordion.Body className="bmm-text">
                            <ol>
                                <li>Зарегистрироваться на сайте Мастерской и заполнить информацию в <Link
                                    to="/account">Личном кабинете</Link>.
                                </li>
                                <li> Выбрать не менее трех проектов, в которых хотелось бы участвовать.
                                    Все проекты Мастерской приведены на <Link to="..">сайте</Link>.
                                </li>
                                <li>Дождаться распределения участников по проектам.</li>
                            </ol>
                            Потенциальный участник может не попасть в проект по своему первому приоритету.
                            Чем больше проектов он выбирает, тем больше шансов попасть хотя бы в один из них.<br/>
                            При выборе проекта обратите внимание на площадку, на которой будет проводиться этот проект.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header><h3 className="bmm-subtitle m-0">
                            Как принять участие в конкурсе грантов на покрытие расходов на проживание?
                        </h3></Accordion.Header>
                        <Accordion.Body className="bmm-text">
                            Для участников Мастерской, проживающих в городе, отличном от города-площадки проведения
                            проекта, предусмотрено полное или частичное покрытие расходов на проживание во время
                            Мастерской.<br/><br/>
                            Расходы на проезд не покрываются. Просим запрашивать поддержку на проезд в ваших
                            университетах, школах и других организациях.<br/><br/>
                            Запросить финансовую поддержку на проживание можно до <strong>1 июня 2024 года</strong> в
                            Личном кабинете.
                            Для этого необходимо сделать следующее:
                            <ol>
                                <li>Заполнить ФИО и дату рождения в <Link
                                    to="/account/settings">профиле участника</Link>.</li>
                                <li>Подать заявку на мастерскую в <Link
                                    to="/account/settings">кабинете участника</Link>.
                                </li>
                                <li>Перейти в раздел <Link
                                    to="/account/travel">Тревел грант</Link> и подать там заявку на оплату проживания.
                                    Вам нужно будет указать данные для каждой из площадок, на которых проводятся проекты из вашей заявки на участие.
                                    Условия покрытия расходов на разных площадках могут отличаться.
                                    Вам также нужно будет в заявке написать мотивационное письмо.</li>
                            </ol>
                            Обратите внимание, что средства поддержки не перечисляются участнику.
                            Все необходимые расходы непосредственно оплачиваются организаторами Мастерской.
                            Если ваша заявка на грант будет одобрена, для уточнения деталей с вами свяжутся организаторы
                            той площадки, в проекте которой вы планируете участвовать.<br/><br/>
                            Ввиду ограниченности средств некоторые заявки на покрытие расходов на проживание
                            могут быть не поддержаны.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>

            <div className="section">
                <h2 className="bmm-title">Что такое проект Большой математической мастерской?</h2>
                <div className="bmm-text">
                    <p>Проект БММ — это открытая задача, у которой есть заказчик. Он понимает контур употребления
                        возможного решения и заинтересован в успехе команды в решении задачи. Открытость задачи
                        означает, что заказчик сам не знает решения поставленной задачи, иначе он давно нанял бы команду
                        инженеров или разработчиков и реализовал свои идеи.</p>
                </div>
                <Accordion className="about-accordion" alwaysOpen>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h3 className="bmm-subtitle m-0">
                            Откуда берутся проекты Мастерской?
                        </h3></Accordion.Header>
                        <Accordion.Body className="bmm-text">
                            Проекты Мастерской поступают как из научных организаций (в частности, из институтов
                            Российской академии наук), так и от индустриальных предприятий и бизнеса.<br/>
                            Проекты Мастерской предыдущих лет можно посмотреть по <Link to="/archive">ссылке</Link>.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><h3 className="bmm-subtitle m-0">
                            Кто такой заказчик и что он делает?
                        </h3></Accordion.Header>
                        <Accordion.Body className="bmm-text">
                            Заказчик проекта — это человек, у которого возникла реальная проблема.
                            Именно решениям таких проблем и будет посвящена проектная деятельность во время
                            Мастерской.<br/><br/>
                            Заказчик формирует проект — заявку на решение какой-то проблемы.
                            При необходимости обеспечивает экспертизу работы (например, предоставляет необходимые
                            данные). Заказчик также встречается с группой для обсуждения промежуточных результатов.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header><h3 className="bmm-subtitle m-0">
                            Кто такой куратор и что он делает?
                        </h3></Accordion.Header>
                        <Accordion.Body className="bmm-text">
                            Куратор сопровождает работу команды студентов на пути к решению поставленной заказчиком
                            задачи. Он, как правило, является специалистом в области: знает понятийную базу, методы,
                            технологии, может проконсультировать и дать совет. Однако куратор не сможет написать для
                            каждого пошаговую инструкцию с точным описанием конкретных действий. Простыми словами,
                            куратор проекта — это старший товарищ, который разбирается в тематике проекта чуть больше
                            самих участников.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header><h3 className="bmm-subtitle m-0">
                            Какие результаты могут быть получены на Мастерской?
                        </h3></Accordion.Header>
                        <Accordion.Body className="bmm-text">
                            По опыту проведения БММ результаты работы проектных групп могут быть разными:
                            <ul>
                                <li>научный результат, оформленный в виде научной публикации;
                                    <Popover
                                        contentClassName="bmm-popover-l"
                                        placement={"auto"}
                                        htmlContent={
                                            '<b>Помимо того, что научные публикации являются основной формой донесения научных результатов до академического сообщества, они могут быть полезными и для других целей.</b> ' +
                                            '<li>Наличие, качество и количество научных публикаций часто служит одним из ключевых показателей при участии в конкурсе на стипендию, премию, грант и т.п. ' +
                                            '(см., например, <a href="https://www.nsu.ru/n/education/stipendii/#item_3473729/" target="_blank">здесь</a>).' +
                                            '</li>' +
                                            '<li>Наличие научных публикаций необходимо для защиты кандидатской диссертации ' +
                                            '(см., например, <a href="https://www.nsu.ru/n/research/dissertation-committee/nsu/normativnye-dokumenty/" target="_blank">здесь</a>).' +
                                            '</li>\n' +
                                            '<li>Наличие научных публикаций необходимо при трудоустройстве на научные должности в научные, образовательные и некоторые коммерческие организации.</li>'
                                        }
                                    >
                                        <CircleInfo className="info-icon"/>
                                    </Popover>
                                </li>
                                <li>прикладной результат, оформленный в виде патента;
                                    <Popover
                                        contentClassName="bmm-popover-l"
                                        placement={"auto"}
                                        htmlContent={
                                            '<b>Помимо того, зарегистрированные результаты интеллектуальной деятельности (программы для ЭВМ, патенты и т.д.) являются основной формой защиты интеллектуальной собственности, они могут быть полезными и для других целей.</b> ' +
                                            '<li>Зарегистрированные результаты интеллектуальной деятельности являются публикациями и учитываются при защите диссертационных работ ' +
                                            '(см., например, <a href=" https://vak.minobrnauki.gov.ru/uploader/loader?type=1&name=91495941002&f=7798/" target="_blank">здесь</a>).' +
                                            '</li>' +
                                            '<li>Наличие зарегистрированных результатов интеллектуальной деятельности позволяет усилить заявки на различные конкурсы, резюме авторов и команд, вызывает больше доверия ' +
                                            '(см., например, <a href=" https://nauka.nso.ru/page/232/" target="_blank">здесь</a>).' +
                                            '</li>'
                                        }
                                    >
                                        <CircleInfo className="info-icon"/>
                                    </Popover>
                                </li>
                                <li>прикладной результат, оформленный в виде программного продукта;</li>
                                <li>переформулировка изначально поставленной в проекте проблемы на математическом
                                    языке.
                                </li>
                            </ul>
                            Также по результатам Мастерской формируются новые исследовательские группы, подаются заявки
                            на гранты и даже создаются новые бизнесы.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>

            <h2 className="section">
                <div className="bmm-title">
                    Что представляет собой работа на Большой математической мастерской?
                </div>
                <div className="bmm-subtitle">
                    Сколько времени занимает работа на Мастерской?
                </div>
                <div className="bmm-text">
                    <p>Обычный рабочий день БММ длится с 10:00 до 19:00.
                        Бывают дни, когда команда решает собраться пораньше или засидеться допоздна.
                        Такие решения принимаются регулярно, поэтому <strong>реальная работа в период модулей занимает
                            10–12 часов в день</strong>. Общение в мессенджерах может проходить 24/7.</p>
                </div>

                <Accordion className="about-accordion" alwaysOpen>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h3 className="bmm-subtitle m-0">
                            Групповая работа
                        </h3></Accordion.Header>
                        <Accordion.Body className="bmm-text">
                            Групповая работа — основное действие Мастерской.
                            Проектные команды ставят промежуточные цели, формулируют гипотезы, определяют методы,
                            которыми нужно пользоваться, реализуют эти методы, а также анализируют полученные
                            результаты. Во время групповой работы возможны:
                            <ul>
                                <li>прослушивание лекций для ознакомления с новым материалом;</li>
                                <li>решение упражнений для закрепления полученных знаний;</li>
                                <li>консультации с заказчиком проекта.</li>
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><h3 className="bmm-subtitle m-0">
                            Взаимные доклады проектов
                        </h3></Accordion.Header>
                        <Accordion.Body className="bmm-text">
                            Взаимные доклады проектов — формат, в рамках которого группа рассказывает о
                            полученных результатах другим участникам Мастерской, а также, возможно, заказчикам и
                            приглашенным экспертам. На Мастерской предусмотрены:
                            <ul>
                                <li>взаимные доклады малых групп (два проекта делают доклад друг другу);</li>
                                <li>взаимные доклады в рамках группы проектов (5-6 проектов, каждый из которых делает
                                    доклад для остальных проектов);
                                </li>
                                <li>взаимные доклады в рамках Мастерской (каждый проект делает доклад для всех
                                    участников Мастерской).
                                </li>
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header><h3 className="bmm-subtitle m-0">
                            Питч-сессия проектов
                        </h3></Accordion.Header>
                        <Accordion.Body className="bmm-text">
                            Питч-сессия проектов — формат, в рамках которого проектные группы
                            делают двухминутные научно-популярные доклады о своем проекте для всех участников
                            Мастерской.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header><h3 className="bmm-subtitle m-0">
                            Установка руководителя потока
                        </h3></Accordion.Header>
                        <Accordion.Body className="bmm-text">
                            Установка руководителя потока — формат, в рамках
                            которого участники вместе с кураторами и организаторами Мастерской обсуждают
                            движение проекта, планирование совместной работы, постановку задач и
                            результатов.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header><h3 className="bmm-subtitle m-0">
                            Социальное мероприятие
                        </h3></Accordion.Header>
                        <Accordion.Body className="bmm-text">
                            Социальное мероприятие — необязательное
                            мероприятие, целью которого является веселое совместное время препровождение.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </h2>

            <h2 className="section">
                <div className="bmm-title">
                    Какую пользу могут извлечь из Мастерской заказчики и участники?
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="bmm-subtitle">
                            Заказчики
                        </div>
                        <div className="bmm-text">
                            Заказчику-исследователю Мастерская позволит:
                            <ul>
                                <li>вовлечь новых студентов в свою область исследований;</li>
                                <li>быстро проверить некоторые гипотезы вокруг исследуемой проблемы;</li>
                                <li>найти связи между собственными исследованиями и исследованиями других ученых,
                                    работающих
                                    на
                                    Мастерской.
                                </li>
                            </ul>
                            Если заказчик является представителем бизнес-организации или индустриального предприятия, то
                            Мастерская позволит:
                            <ul>
                                <li>переформулировать индустриальную задачу на математическом языке;</li>
                                <li>получить минимально жизнеспособный продукт, отвечающий на поставленную задачу;</li>
                                <li>найти потенциальных сотрудников в свою компанию.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="bmm-subtitle">
                            Участники
                        </div>
                        <div className="bmm-text">
                            Участникам Мастерская позволит:
                            <ul>
                                <li>попробовать свои силы в научной или околонаучной сфере за короткий промежуток
                                    времени;
                                </li>
                                <li>найти единомышленников и товарищей, с которыми можно будет вместе реализовывать
                                    проекты;
                                </li>
                                <li>наработать базу, которая станет основой заявки на грант, заказного R&amp;D
                                    исследования, контракта или стартапа;
                                </li>
                                <li>найти научного руководителя;</li>
                                <li>написать первую публикацию;</li>
                                <li>найти будущую работу.</li>
                            </ul>
                            <p>Школьники, которые в результате работы на Мастерской будут отмечены кураторами, могут
                                заработать 5 баллов к суммарному баллу ЕГЭ при поступлении в НГУ.</p>
                            <p>Студенты, которые в результате работы на Мастерской будут отмечены кураторами, получат
                                сертификаты участников Большой математической мастерской.</p>
                            Для педагогов и исследователей с высшим образованием Мастерская станет программой повышения
                            квалификации, при ее успешном прохождении будут выданы свидетельства установленного образца.
                        </div>
                    </div>
                </div>
            </h2>

            <h2 className="section">
                <div className="bmm-title">
                    Часто задаваемые вопросы
                </div>
                <Accordion className="about-accordion" alwaysOpen>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h3 className="bmm-subtitle m-0">
                            Что такое межмодуль?
                        </h3></Accordion.Header>
                        <Accordion.Body className="bmm-text">
                            <p>Межмодуль представляет собой три дня, в которые нет обязательных мероприятий
                                Мастерской.</p>
                            Каждая команда сама выбирает, как использовать этот период:
                            <ul>
                                <li>Некоторые продолжают активную работу, решая задачи.</li>
                                <li>Другие структурируют полученные результаты.</li>
                                <li>Есть те, кто углубляется в изучение материалов по своей проблеме.</li>
                                <li>И, конечно, те, кто просто отдыхает.</li>
                            </ul>
                            Главное, чтобы межмодуль приносил пользу как участникам, так и самому проекту.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><h3 className="bmm-subtitle m-0">Какие
                            школьники могут принять
                            участие в Мастерской?</h3>
                        </Accordion.Header>
                        <Accordion.Body className="bmm-text">
                            <p>Как показывает практика, учащимся 9-11 классов хватает математических знаний для работы
                                над школьными проектами Большой математической мастерской. Учащимся 7-8 классов будет
                                гораздо сложнее из-за нехватки знаний из школьной программы. Такие участники были на
                                Мастерской в 2022-2023 годах. Не смотря на сложности, при должном внимании куратора, все
                                они дошли до конца Мастерской и получили научные результаты.</p>

                            <strong>Важно!</strong> Со школьниками, которые приехали на Мастерскую из другого города,
                            обязательно должно быть сопровождающее лицо, которое несет ответственность за этого
                            школьника. Например, в 2022-2023 годах на Мастерской было множество таких ситуаций: ребенок
                            (в школьный проекта) с мамой-сопроваждающей (в педагогический проект), ребенок (в школьный
                            проект) с сестрой-сопровождающей (в студенческий проект), группа детей (в школьные проекты)
                            со своим учителем (в педагогический проект). Для работы иногородних школьников на Мастерской
                            сопровождающий – это единственный вариант.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header><h3 className="bmm-subtitle m-0">Как повысить
                            шансы попасть в тот
                            проект, в который больше всего хочу?</h3>
                        </Accordion.Header>
                        <Accordion.Body className="bmm-text">
                            Распишите все свои достоинства, умения и интересы при заполнении портфолио в Личном кабинете
                            участника и прикрепите подтверждающие документы.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header><h3 className="bmm-subtitle m-0">Как я узнаю, на
                            какой проект я
                            прошел?</h3>
                        </Accordion.Header>
                        <Accordion.Body className="bmm-text">
                            В ходе отбора участников представители проектов присваивают каждому участнику один из трех
                            статусов: рекомендован, в листе ожидания и не рекомендован. В Личном кабинете участника
                            публикуется информация о проекте, в котором он будет работать в рамках Мастерской.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header><h3 className="bmm-subtitle m-0">Что нужно взять
                            с собой на
                            Мастерскую?</h3>
                        </Accordion.Header>
                        <Accordion.Body className="bmm-text">
                            Работа на Большой математической мастерской очень разнообразна. Вы будете анализировать
                            известную информацию, искать новую, а также получать новые математические результаты.
                            Полезными при работе могут быть канцелярские принадлежности (тетрадь, ручка), планшет или
                            ноутбук, если есть. Если ваш проект так или иначе связан с написанием кода, то ноутбук может
                            быть особенно полезен.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header><h3 className="bmm-subtitle m-0">Можно ли
                            участвовать в 2
                            проектах?</h3>
                        </Accordion.Header>
                        <Accordion.Body className="bmm-text">
                            Так как реальная работа над одним проектом во время модуля занимает 10–12 часов в день, то
                            одновременно участвовать в двух проектах не получится.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header><h3 className="bmm-subtitle m-0">Что делать, если
                            я могу участвовать
                            только на 1 модуле?</h3>
                        </Accordion.Header>
                        <Accordion.Body className="bmm-text">
                            Мастерская предполагает работу в ходе обоих модулей и межмодульного периода. Принимать
                            участие только в одном модуле нельзя.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                        <Accordion.Header><h3 className="bmm-subtitle m-0">Как планируется
                            оформлять результаты
                            работы команд?</h3>
                        </Accordion.Header>
                        <Accordion.Body className="bmm-text">
                            Как минимум, каждая команда представит заметку, в которой будет содержаться информация о
                            результатах проекта понятным и доступным широкой аудитории языком. Данные заметки будут
                            размещены на сайте в разделе с проектами. Также планируется создать единый сборник, выходные
                            данные которого могут использоваться для подачи документов на различные стипендии.
                            Приветствуется обобщение результатов в виде полноценных научных публикаций, а также заметки
                            на специализированных сайтах, к примеру на Habr.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                        <Accordion.Header><h3 className="bmm-subtitle m-0">К кому я могу
                            обратиться за
                            консультацией по вопросам участия в Мастерской?</h3>
                        </Accordion.Header>
                        <Accordion.Body className="bmm-text">
                            Оперативнее всего ответ на ваш вопрос вы получите в Телеграм-чате <a
                            href="https://t.me/+1hepV9jWqeBhY2M6/" target="_blank">по ссылке</a>.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </h2>
        </>
    )
}

export default AboutPage