import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {checkAuthenticated, load_user} from '../actions/auth';
import Header from "../components/header/Header";
import {Container} from "react-bootstrap";
import {Outlet} from "react-router-dom";
import Footer from "../components/footer/Footer";

const navLinks = [
    {to: '/about', label: 'О мастерской'},
    {to: '/', label: 'Проекты'},
    {to: '/stages', label: 'Этапы'},
    {to: '/archive', label: 'Архив'},
    // {to: '/help', label: 'Помощь'},
];

const Layout = ({checkAuthenticated, load_user}) => {
    useEffect(() => {
        checkAuthenticated();
        load_user();
    }, []);

    return (
        <>
            <Header navLinks={navLinks}/>

            <Container>
                <Outlet/>
            </Container>

            <Footer navLinks={navLinks}/>
        </>
    );
};

export default connect(null, {checkAuthenticated, load_user})(Layout);