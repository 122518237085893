import React, {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import axios from 'axios';

import ProjectUserForm from "./ProjectUserForm";
import {getAcceptAuthConfig, PROJECTS_ENDPOINT} from "../../api/api";

const ProjectUserFormCreate = () => {
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);

    const [hasManager, setHasManager] = useState(() => (localStorage.getItem('hasManager') ?
        JSON.parse(localStorage.getItem('hasManager')) : ""));
    const [projectData, setProjectData] = useState(() => (localStorage.getItem('projectData') ?
        JSON.parse(localStorage.getItem('projectData')) :
        {
            venue: "-1",
            name: "",
            description: "",
            requirements: "",
            is_for_schoolchildren: "",
            full_description_file: "",
            test_file: "",
            status: "",
            local_stream: "",
            customers: [],
            keywords: [],
            managers: [],
            manager_requirements: "",
            description_text_editor: "",
        }));

    const [errors, setErrors] = useState(
        {
            venue: "",
            name: "",
            description: "",
            requirements: "",
            is_for_schoolchildren: "",
            full_description_file: "",
            test_file: "",
            status: "",
            local_stream: "",
            customers: [],
            keywords: "",
            manager: "",
            managers: [],
        });

    const onSubmit = (e) => {
        e.preventDefault();

        let projectData_copy = {...projectData};

        if (!projectData_copy.description_text_editor || projectData_copy.description_text_editor.trim() === '') {
            e.target.querySelector('[name="description_text_editor"]').setCustomValidity('Заполните это поле.');
        } else {
            setSubmitting(true);
            e.target.querySelector('[name="description_text_editor"]').setCustomValidity('');

            if (!hasManager) {
                delete projectData_copy.managers;
            }
            delete projectData_copy.customer;

            const formDataToSend = new FormData();
            for (const key in projectData_copy) {
                if (key === 'managers' || key === 'customers') {
                    formDataToSend.append(key, JSON.stringify(projectData_copy[key]));
                } else {
                    formDataToSend.append(key, projectData_copy[key]);
                }
            }

            axios
                .post(PROJECTS_ENDPOINT, formDataToSend, getAcceptAuthConfig())
                .then(response => {
                    localStorage.removeItem('projectData');
                    localStorage.removeItem('hasManager');
                    navigate('../', {relative: "path"});
                })
                .catch(error => {
                    setErrors(JSON.parse(error.response.request.response))
                    console.error('Error adding project:', error);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
    };

    useEffect(() => {
        localStorage.setItem('projectData', JSON.stringify(projectData));
    }, [projectData]);

    useEffect(() => {
        localStorage.setItem('hasManager', hasManager);
    }, [hasManager]);

    const onCanselClick = (e) => {
        localStorage.removeItem('projectData');
        localStorage.removeItem('hasManager');
        navigate('../', {relative: "path"});
    }

    return (
        <>
            <h3 className="mb-2">Подача проекта</h3>
            <hr className="clearfix w-100 pb-0"/>

            <ProjectUserForm type={"create"}
                             onSubmit={onSubmit} submitting={submitting}
                             projectData={projectData} setProjectData={setProjectData}
                             hasManager={hasManager} setHasManager={setHasManager}
                             errors={errors} setErrors={setErrors}
                             onCanselClick={onCanselClick}/>
        </>
    );
};

export default ProjectUserFormCreate;