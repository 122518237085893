import React from 'react';
import { Icon } from "@gravity-ui/uikit";
import { HeartFill } from "@gravity-ui/icons";
import {Link} from "react-router-dom";
import { ProjectKeywords} from "./utils"

import './ProjectCard.css'
import {Button} from "@gravity-ui/uikit";

function ProjectCard({project, isAdded, handleAddToApplication}) {
    return (
        <div className="bmm-card project-card project-card-public">
            <div className="project-card__pretitle">
                <div className="me-1">
                    <span>Площадка {project.venue?.name || '–'}</span>
                </div>
                <div>
                    <span>{project.is_for_schoolchildren && "Для школьников"}</span>
                </div>
            </div>

            <Link to={`project/${project.id}`} className="project-card__title" target="_blank" rel="noopener noreferrer">
                {project.name}
            </Link>

            <div className="project__keywords project-card__keywords">
                <ProjectKeywords keywords={project.keywords}/>
            </div>

            <div className="bmm-text" dangerouslySetInnerHTML={{__html: project.description}}/>

            <div className="project-card__subtitle">
                <Link to={`project/${project.id}`} target="_blank" rel="noopener noreferrer">
                    <Button view={"normal"} size={"xl"}>
                        Подробнее
                    </Button>
                </Link>
                <Button onClick={() => handleAddToApplication(project)} view={"normal"} size={"xl"}>
                    <Icon data={HeartFill} size={18} className={isAdded ? 'heart-icon-active' : 'heart-icon-inactive'}/>
                    {isAdded ? 'Добавлен в заявку' : 'Добавить в заявку'}
                </Button>
            </div>
        </div>
    );
}

export default ProjectCard;