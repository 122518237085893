import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";

import {
    getAcceptAuthConfig,
    getJSONAcceptAuthConfig,
    USER_APPLICATION_DETAIL_ENDPOINT,
} from "../../../api/api";
import ApplicationForm from "./ApplicationForm";
import LoadingSpinner from "../../../components/LoadingSpinner";

function ApplicationFormUpdate() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);

    const [applicationData, setApplicationData] = useState(null);
    const [applicationProjectsData, setApplicationProjectsData] = useState(null);

    const [oldAchievementsFile, setOldAchievementsFile] = useState("");
    const [oldSolutionFiles, setOldSolutionFiles] = useState("");

    const [errors, setErrors] = useState("");

    useEffect(() => {
        axios
            .get(USER_APPLICATION_DETAIL_ENDPOINT(id), getJSONAcceptAuthConfig())
            .then(response => {
                setApplicationData(response.data.cv);
                setOldAchievementsFile(response.data.cv.achievements_confirmation_file);
                const sortedProjects = response.data.projects_user.sort((a, b) => a.priority - b.priority);
                setApplicationProjectsData(sortedProjects);
                setOldSolutionFiles(sortedProjects);
            })
            .catch(error => {
                console.error('Error getting application data:', error);
            });
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();

        setSubmitting(true);

        const formData = new FormData();

        if (applicationData.achievements_confirmation_file !== oldAchievementsFile) {
            formData.append("achievements_confirmation_file", applicationData.achievements_confirmation_file);
        } else {
            delete applicationData.achievements_confirmation_file;
        }
        formData.append("cv", JSON.stringify(applicationData));

        formData.append("projects_user", JSON.stringify(applicationProjectsData));
        applicationProjectsData.forEach(project => {
            if (project.file) {
                const projectWithSameId = oldSolutionFiles.find(p => p.id === project.id);
                if (!projectWithSameId || project.file !== projectWithSameId.file) {
                    formData.append(project.id, project.file);
                }
            }
        });

        axios
            .put(USER_APPLICATION_DETAIL_ENDPOINT(id), formData, getAcceptAuthConfig())
            .then(response => {
                navigate('../', {relative: "path"});
            })
            .catch(error => {
                setErrors(JSON.parse(error.response.request.response))
                console.error('Ошибка отправки заявки:', error);
            })
            .finally(() => {
                setSubmitting(false);
            });
    }

    return (
        <>
            <h3 className="mb-2">Редактирование заявки на участие</h3>
            <hr className="clearfix w-100 pb-0"/>

            {applicationData
                ? <ApplicationForm type={"update"}
                                   applicationData={applicationData} setApplicationData={setApplicationData}
                                   applicationProjectsData={applicationProjectsData}
                                   setApplicationProjectsData={setApplicationProjectsData}
                                   oldAchievementsFile={oldAchievementsFile} oldSolutionFiles={oldSolutionFiles}
                                   errors={errors}
                                   onSubmit={onSubmit} submitting={submitting}/>
                : <LoadingSpinner/>}
        </>
    );
}

export default ApplicationFormUpdate;