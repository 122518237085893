import React from 'react';


export function Diagram() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
            <path
                d="M 3.5 14 h -1 a 2 2 0 0 1 -2 -2 v -2 a 2 2 0 0 1 2 -2 h 2 c 0.173 0 0.34 0.022 0.5 0.063 v -4.063 a 2 2 0 0 1 2 -2 h 2 c 0 0 2 0 2 2 v 1.063 c 0.16 -0.041 0.327 -0.063 0.5 -0.063 h 2 a 2 2 0 0 1 2 2 v 5 a 2 2 0 0 1 -2 2 h -10 Z M 13.5 12.5 A 0.5 0.5 0 0 0 14 12 v -5 a 0.5 0.5 0 0 0 -0.5 -0.5 h -2 a 0.5 0.5 0 0 0 -0.5 0.5 v 5 a 0.5 0.5 0 0 0 0.5 0.5 h 2 Z M 9 12.5 A 0.5 0.5 0 0 0 9.5 12 V 4 a 0.5 0.5 0 0 0 -0.5 -0.5 H 7 a 0.5 0.5 0 0 0 -0.5 0.5 v 8 a 0.5 0.5 0 0 0 0.5 0.5 h 2 Z M 4.5 12.5 A 0.5 0.5 0 0 0 5 12 v -2 a 0.5 0.5 0 0 0 -0.5 -0.5 h -2 a 0.5 0.5 0 0 0 -0.5 0.5 v 2 a 0.5 0.5 0 0 0 0.5 0.5 h 2 Z"/>
        </svg>
    );
}

export function Load() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
            <path
                d="M12.442 13.033c-.278.307-.319.777-.05 1.092.27.314.747.353 1.033.053a7.5 7.5 0 1 0-10.85 0c.286.3.763.261 1.032-.053.27-.315.23-.785-.05-1.092a6 6 0 1 1 8.884 0Z"/>
        </svg>
    );
}

export function EmptyFile() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
            <path
                d="M 11 13.5 h -6 a 1.5 1.5 0 0 1 -1.5 -1.5 V 4 A 1.5 1.5 0 0 1 5 2.5 L 7.759 2.5 A 1.5 1.5 0 0 1 8.819 2.939 L 12.06 6.182 A 1.5 1.5 0 0 1 12.5 7.243 V 12 A 1.5 1.5 0 0 1 11 13.5 Z M 14 7.243 A 3 3 0 0 0 13.121 5.121 L 9.88 1.88 A 3 3 0 0 0 7.757 1 H 5 a 3 3 0 0 0 -3 3 v 8 a 3 3 0 0 0 3 3 h 6 a 3 3 0 0 0 3 -3 V 7.243 Z "/>
        </svg>
    );
}